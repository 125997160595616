import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';
import { CurrentUser } from '../../services/user';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    api: Api;
    router: Router;
    username: string = '';
    showNotice = false;
    constructor(api: Api, router: Router, private currentUser: CurrentUser, private globals: Globals) {
        this.api = api;
        this.router = router;
    }


    async ngOnInit() {

    }

    async submit() {
        this.showNotice = true;
        let result = await this.api.base().post<any>('/reset-password', {
            username: this.username
        });
        if (result.succeeded) {
            this.username = '';
        }
        console.log(result);
    }
}
