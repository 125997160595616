// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .qr-code img {
  display: inline-block !important;
  max-width: 100%;
  max-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/settings/staff-devices/staff-device-qr-dialog/staff-device-qr-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/settings/staff-devices/staff-device-qr-dialog/staff-device-qr-dialog.component.scss"],"names":[],"mappings":"AAEQ;EACI,gCAAA;EACA,eAAA;EACA,gBAAA;ACDZ","sourcesContent":["::ng-deep {\r\n    .qr-code {\r\n        img {\r\n            display: inline-block !important;\r\n            max-width: 100%;\r\n            max-height: 50vh;\r\n        }\r\n    }\r\n}\r\n","::ng-deep .qr-code img {\n  display: inline-block !important;\n  max-width: 100%;\n  max-height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
