import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../services/globals';
import { Utility } from '../../services/utility';
import { IPackage } from '../../interfaces/package';
import { CurrentUser } from '../../services/user';
import { IAddOn } from '../../interfaces/add-on';
import { IResource } from '../../interfaces/resource';

@Component({
  selector: 'client-settings',
  templateUrl: './client-settings.component.html'
})

export class ClientSettingsComponent implements OnInit {
  settings: any = null;
  @ViewChild('tabGroup', { static: false }) tabGroup;

  availablePackages: IPackage[];
  availableResources: IResource[];
  availableAddOns: IAddOn[];

  googleCalendarUsername: string;
  addingGoogleCalendarUser: boolean;

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

  }

  checkSmsSenderLength(event) {
    if (this.settings.smsSettings.sender.length >= 11) {
      this.settings.smsSettings.sender = this.settings.smsSettings.sender.substring(0, 11);
      event.preventDefault();
    }

  }


  async save() {
    let result = await this.api.client().patch<any>(`/manage/settings`, this.settings);
    //this.globals.clientSettings = this.settings;
  }

  async addGoogleCalendarUser() {


    if (this.googleCalendarUsername && this.googleCalendarUsername != '') {
      this.addingGoogleCalendarUser = true;
      let result = await this.api.client().put<any>(`/manage/third-party/google/calendar/user`, { username: this.googleCalendarUsername });
      this.addingGoogleCalendarUser = false;
      if (result.calendarUser) {
        this.settings.googleCalendarSettings.calendarUsers.push(result.calendarUser);
        this.googleCalendarUsername = null;
      }
    }
  }

  async googleCalendarRemoveUsers(userEmail: string) {
    console.log(`Removed ${userEmail} from google calendar`);

    //remove google user from array of users by index    
    this.settings.googleCalendarSettings.calendarUsers = this.settings.googleCalendarSettings.calendarUsers.filter((value, index, arr) => {
        return value.scope.value != userEmail;
    });

    await this.api.client().patch<any>(`/manage/third-party/google/calendar/user/remove`, {
      user: userEmail
    });

    console.log(this.settings.googleCalendarSettings.calendarUsers);
  }

  async saveGoogleSettings() {

  }

  async ngOnInit() {
    this.settings = await this.api.client().get<any>(`/manage/settings`);
    this.availablePackages = await this.api.client().get<IPackage[]>(`/manage/packages`);
    this.availableResources = await this.api.client().get<IResource[]>(`/manage/resources`);
    this.availableAddOns = await this.api.client().get<IAddOn[]>(`/manage/add-ons`);

    if (!this.settings.contactInfo)
      this.settings.contactInfo = {};

    if (!this.settings.smsSettings)
      this.settings.smsSettings = {};

    if (!this.settings.mailchimpSettings) {
      this.settings.mailchimpSettings = {};
    }

    if (!this.settings.quickBookingPackages) {
      this.settings.quickBookingPackages = [];
    }

    if (!this.settings.notificationSettings) {
      this.settings.notificationSettings = {
        tags: {}
      };
    }

    if(!this.settings.enableCartShowEndtime) {
        this.settings.enableCartShowEndtime = false;
    }

    if (!this.settings.notificationSettings.tags.NEW_BOOKING) {
      this.settings.notificationSettings.tags.NEW_BOOKING = {
        notificationEnabled: true,
        filterByPackage: false,
        filteredPackages: []
      }
    }

    if (!this.settings.notificationSettings.tags.CHANGED_BOOKING) {
      this.settings.notificationSettings.tags.CHANGED_BOOKING = {
        notificationEnabled: true,
        filterByPackage: false,
        filteredPackages: []
      }
    }

    if (!this.settings.notificationSettings.tags.CANCELED_BOOKING) {
      this.settings.notificationSettings.tags.CANCELED_BOOKING = {
        notificationEnabled: true,
        filterByPackage: false,
        filteredPackages: []
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_BOOKING_COMMENT) {
      this.settings.notificationSettings.tags.NEW_BOOKING_COMMENT = {
        notificationEnabled: true,
        filterByPackage: false,
        filteredPackages: []
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_BOOKING_ADD_ON) {
      this.settings.notificationSettings.tags.NEW_BOOKING_ADD_ON = {
        notificationEnabled: false,
        filterByAddOn: false,
        filteredAddOns: []
      }
    }


    if (!this.settings.notificationSettings.tags.NEW_EVENT_BOOKING) {
      this.settings.notificationSettings.tags.NEW_EVENT_BOOKING = {
        notificationEnabled: true
      }
    }


    if (!this.settings.notificationSettings.tags.CANCELED_EVENT_BOOKING) {
      this.settings.notificationSettings.tags.CANCELED_EVENT_BOOKING = {
        notificationEnabled: true
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_EVENT_BOOKING_COMMENT) {
      this.settings.notificationSettings.tags.NEW_EVENT_BOOKING_COMMENT = {
        notificationEnabled: true
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_EVENT_BOOKING_ADD_ON) {
      this.settings.notificationSettings.tags.NEW_EVENT_BOOKING_ADD_ON = {
        notificationEnabled: false,
        filterByAddOn: false,
        filteredAddOns: []
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_FEEDBACK) {
      this.settings.notificationSettings.tags.NEW_FEEDBACK = {
        notificationEnabled: false,
        positiveFeedback: false,
        neutralFeedback: false,
        negativeFeedback: false,
      }
    }

    if (!this.settings.notificationSettings.tags.NEW_SOLD_GIFTCARD) {
      this.settings.notificationSettings.tags.NEW_SOLD_GIFTCARD = {
        notificationEnabled: false,
      }
    }

    if (!this.settings.birthdayEmailSettings) {
      this.settings.birthdayEmailSettings = {
        generatePromoCode: false,
        promoCodeType: 'PERCENT',
        promoCodeValue: 10
      };
    }

    if (!this.settings.bookingReminderHoursInAdvance)
      this.settings.bookingReminderHoursInAdvance = 24;

    if (typeof (this.settings.checkInChangeAllowedHoursInAdvance) == 'undefined') {
      this.settings.checkInChangeAllowedHoursInAdvance = 1;
    }

    if (!this.settings.gameIntegrationSettings.vbsSettings.laneResources) {
      this.settings.gameIntegrationSettings.vbsSettings.laneResources = [];
    }

    if (this.settings.gameIntegrationSettings.socialGamingSettings) {
      for (let resource of this.availableResources) {
        if (!this.settings.gameIntegrationSettings.socialGamingSettings.resourceIds[resource.id])
          this.settings.gameIntegrationSettings.socialGamingSettings.resourceIds[resource.id] = {
            enabled: false,
            name: ''
          }
      }
    }

    if (!this.settings.billingInfo) {
      this.settings.billingInfo = {};
    }
  }

  addVBSBowlingLane() {
    this.settings.gameIntegrationSettings.vbsSettings.laneResources.push({});
  }

  removeVBSBowlingLane(index) {
    this.settings.gameIntegrationSettings.vbsSettings.laneResources.splice(index, 1);
  }

  addBowlitBowlingLane() {
    this.settings.gameIntegrationSettings.bowlitSettings.laneResources.push({});
  }

  removeBowlitBowlingLane(index) {
    this.settings.gameIntegrationSettings.bowlitSettings.laneResources.splice(index, 1);
  }

}
