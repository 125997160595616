import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Utility } from '../../../services/utility';

@Component({
    selector: 'api-keys',
    styleUrls: ['./api-keys.component.scss'],
    templateUrl: './api-keys.component.html'
})

export class ApiKeysComponent implements OnInit {
    apiTokens: any[];
    description: string;
    addingToken: boolean;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

    }

    async ngOnInit() {
        this.apiTokens = await this.api.client().get<any>(`/manage/api-tokens`);
    }


    async generateApiToken() {
        this.addingToken = true;
        let description = this.description;
        this.description = '';
        let result = await this.api.client().post<any>(`/manage/api-tokens/generate`, { description: description });
        this.apiTokens.push(Object.assign(result.apiToken, { visible: true }));
    }

    view(apiToken: any) {
        apiToken.visible = true;
    }

    validate() {
        return this.description 
        && this.description != '' 
        && !this.addingToken;
    }
}
