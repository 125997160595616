import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { Globals } from '../../services/globals';
import { Utility } from '../../services/utility';
import { IUser } from '../../interfaces/user';
import { CurrentUser } from '../../services/user';


@Component({
    selector: 'report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss']
})

export class ReportListComponent implements OnInit, OnDestroy {
    user: IUser;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private currentUser:CurrentUser) {

    }

    async ngOnInit() {
        this.user = await this.currentUser.get();
    }

    isReportAvailable(reportIdentifier: string) {
        return ['superadmin', 'manager'].includes(this.user.role) || this.user.availableReports[reportIdentifier];
    }

    async ngOnDestroy() {

    }
}
