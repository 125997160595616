import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Api } from '../../../services/api';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';


@Component({
  selector: 'virtual-gift-card-dialog',
  templateUrl: './virtual-gift-card-dialog.component.html',
  styleUrls: ['./virtual-gift-card-dialog.component.scss']
})

export class VirtualGiftCardDialogComponent implements OnInit, OnDestroy {

  window: any = window;

  @ViewChild('codeScanInput', { static: false })
  codeScanInput: ElementRef<HTMLInputElement>;

  newGiftCard: {
    value: number,
    name?: string,
    email?: string,
    sendEmail?: boolean,
    validMonths: number,
    code?: string,
    discountCode?: string
  } = {
      value: null,
      validMonths: 12
    }

  defaultGiftCardTemplate: any;
  availableDiscountCodes: string[] = [];

  scanMode = false;
  codeScanStarted = false;
  codeScanFinished = false;
  codeFailed = false;
  creatingGiftCard = false;
  giftCardCreated = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<VirtualGiftCardDialogComponent>, private api: Api, public translate: TranslateService, public currentUser: CurrentUser, public globals: Globals, public currency: Currency) {
    if (data && data.code) {
      this.newGiftCard.code = data.code;
    }


    dialogRef.afterClosed().subscribe(() => {
      this.window.textScanDisabled = false;
    })

    this.window.textScanDisabled = true;

  }

  startScanMode() {
    this.scanMode = true;
    this.newGiftCard.code = '';
    setTimeout(() => {
      this.codeScanInput.nativeElement.focus()
    }, 100);
  }

  async createGiftCard() {
    this.creatingGiftCard = true;
    let result = await this.api.client().post<any>(`/virtual-gift-cards/consume`, this.newGiftCard);

    if (result.succeeded) {
      this.giftCardCreated = true;
    }
    else {
      this.codeFailed = true;
    }

    this.creatingGiftCard = false;
  }

  codeScanKeyUp() {
    if (!this.codeScanStarted) {
      this.codeScanStarted = true;
      setTimeout(() => {
        this.scanMode = false;
        this.codeScanFinished = true;
      }, 300);
    }
  }

  close() {
    if (this.dialogRef)
      this.dialogRef.close();
  }

  async ngAfterViewInit() {

  }


  getPrice() {
    if (this.newGiftCard.discountCode && this.defaultGiftCardTemplate && this.defaultGiftCardTemplate.availableDiscountCodes) {
      let discountCode = this.defaultGiftCardTemplate.availableDiscountCodes.find(dc => {
        return dc.code
          && dc.code == this.newGiftCard.discountCode
          && moment().isAfter(dc.validFrom)
          && moment().isBefore(dc.validTo);
      });

      if (discountCode) {
        return this.newGiftCard.value * (1 - discountCode.discountPercent / 100);
      }
    }

    return this.newGiftCard.value || 0;

  }


  async ngOnInit() {
    if (this.globals.clientSettings && this.globals.clientSettings.defaultGiftCardTemplateId) {
      this.defaultGiftCardTemplate = await this.api.client().get<any>(`/gift-card-templates/${this.globals.clientSettings.defaultGiftCardTemplateId}`);
      if (this.defaultGiftCardTemplate && this.defaultGiftCardTemplate.availableDiscountCodes) {
        this.availableDiscountCodes = this.defaultGiftCardTemplate.availableDiscountCodes.filter(dc => {
          return moment().isAfter(dc.validFrom) && moment().isBefore(dc.validTo);
        });
      }
    }

  }

  async ngOnDestroy() {

  }
}
