import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IActivitySequences, IPackage } from '../../../interfaces/package';
import { IActivity } from '../../../interfaces/activity';
import { addDuration, minutesFromDuration } from '../../../helpers/utility';
import { TranslateService } from '@ngx-translate/core';





@Component({
    selector: 'activity-sequence-editor',
    templateUrl: './activity-sequence-editor.component.html',
    styleUrls: ['./activity-sequence-editor.component.scss']
})

export class ActivitySequenceEditorComponent implements OnInit {
    @Input()
    sequences: IActivitySequences[];
    @Input()
    package: IPackage;
    availableActivities: IActivity[];
    availableActivitiesById: { [activityId: string]: IActivity }
    totalLength: number;
    packageLength: number;
    voidDurations = [];

    constructor(private api: Api, public globals: Globals, public translate: TranslateService) {
        var voidDuration = '00:05';
        for (let i = 0; i < 24; i++) {
            this.voidDurations.push(voidDuration);
            voidDuration = addDuration(voidDuration, '00:05');
        }
    }

    update() {
        this.totalLength = 0;
        for (let sequence of this.sequences) {
            let sequenceLength = 0;
            let currentStart = '00:00';
            var totalDuration = '00:00';
            for (let sequenceActivity of sequence.activities) {
                let activity = this.availableActivities.find(a => a.id == sequenceActivity.activityId);
                (sequenceActivity as any).startTime = currentStart;
                let duration = sequenceActivity.isVoid ? sequenceActivity.voidLength : activity.timeslotInterval;
                currentStart = (sequenceActivity as any).endTime = addDuration(currentStart, duration);
                sequenceLength += minutesFromDuration(duration);
                totalDuration = addDuration(totalDuration, duration);
            }
            this.totalLength = Math.max(this.totalLength, sequenceLength);
            this.package.duration = totalDuration;
        }
    }

    getActivityWidth(sequenceActivity) {
        if (this.availableActivities) {
            let activity = this.availableActivities.find(a => a.id == sequenceActivity.activityId);
            let activityDuration = minutesFromDuration(sequenceActivity.isVoid ? sequenceActivity.voidLength : activity.timeslotInterval);
            let percent = activityDuration / this.totalLength;
            return `${(percent * 100).toFixed(4)}%`;
        }
        return '0px';
    }

    removeSequence(sequence) {
        this.sequences.splice(this.sequences.indexOf(sequence), 1);
        this.update();
    }

    removeActivity(sequence, activity) {
        sequence.activities.splice(sequence.activities.indexOf(activity), 1);
        this.update();
    }

    addSequence() {
        this.sequences.push({
            activities: [],
            continuousResourceActivities: {}
        });
        this.update();
    }

    addActivity(sequence) {
        sequence.activities.push({
            activityId: 0, //this.availableActivities[0].id,
            name: this.availableActivities[0].name,
            voidLength: '00:30',
            isVoid: true
        });

        this.update();
    }

    activityChanged(sequence: IActivitySequences, activity) {
        if (activity.activityId) {
            activity.isVoid = false;
            activity.name = this.availableActivities.find(a => a.id == activity.activityId).name;

            // Default continous resource to be enabled
            if (sequence.continuousResourceActivities
                && !sequence.continuousResourceActivities[activity.activityId]
                && this.continuousResourcePossible(sequence, this.availableActivitiesById[activity.activityId])) {
                sequence.continuousResourceActivities[activity.activityId] = { enabled: true };
            }
        }
        else {
            activity.isVoid = true;
            activity.name = this.translate.instant('VOID');
        }
        this.update();
    }

    continuousResourcePossible(sequence: IActivitySequences, activity: IActivity) {
        //return false;
        if (activity && ["SINGLE","SPILL_OVER"].includes(activity.resourceAllocationType)) {
            if (sequence.activities.filter(a => a.activityId == activity.id).length > 1 && activity.associatedResources.length > 1) {
                return true;
            }
        }
        return false;
    }

    toggleContinousResource(sequence: IActivitySequences, activityId: string, value: boolean) {
        if (sequence.continuousResourceActivities[activityId])
            sequence.continuousResourceActivities[activityId].enabled = value;
        else
            sequence.continuousResourceActivities[activityId] = { enabled: value };
    }

    async ngOnInit() {
        this.availableActivities = await this.api.client().get<IActivity[]>(`/manage/activities`);
        
        //filter out disabled activities
        this.availableActivities = this.availableActivities.filter(a => !a.disabled);

        this.availableActivitiesById = {};
        for (let activity of this.availableActivities) {
            this.availableActivitiesById[activity.id] = activity;
        }

        if (!this.sequences.length)
            this.sequences.push({
                activities: [],
                continuousResourceActivities: {}
            });
        for (let sequence of this.sequences) {
            if (!sequence.continuousResourceActivities) {
                sequence.continuousResourceActivities = {};
            }
        }
        this.packageLength = minutesFromDuration(this.package.timeslotInterval);
        this.update();
    }
}
