import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';
import { IResourceBooking, IResourceBookingResource } from 'src/app/interfaces/resource-booking';
import { IEventBooking } from '../../../../../../../../../../common/common-interfaces/event-booking';

@Component({
    selector: 'event-info-dialog',
    templateUrl: './event-info-dialog.component.html',
    styleUrls: ['./event-info-dialog.component.scss']
})

export class EventInfoDialogComponent implements OnInit {
    public event: IEvent;
    public eventDate: IEventDate;
    public eventBooking: IEventBooking;
    public resourceBooking: IResourceBooking;
    public resourceBookingResource: IResourceBookingResource;


    eventStatus: {
        eventDates: {
            [dateIdentifier: string]:
            IEventDate & { arrived: number, notArrived: number }
        }
    }
    arrivedPersons: number = 0;

    eventChanged: any;

    @ViewChild('tabGroup', { static: false })
    tabGroup: MatTabGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<EventInfoDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, private currency: Currency) {
        this.event = data.event;
        this.eventDate = data.eventDate;
        this.eventBooking = data.eventBooking;
        this.resourceBooking = data.resourceBooking;
        this.resourceBookingResource = data.resourceBookingResource;

        this.eventChanged = data.eventChanged;

        this.dialogRef.backdropClick().subscribe(() => {

        });

    }

    async ngAfterViewInit() {

    }


    async fetchEventStatus() {
        this.eventStatus = await this.api.client().get<any>(`/events/${this.event.id}/status`);

        if (this.eventDate)
            this.arrivedPersons = this.eventStatus.eventDates[this.eventDate.identifier].arrived;
    }

    ngOnDestroy() {

    }

    async ngOnInit() {
        await this.fetchEventStatus();
    }
}
