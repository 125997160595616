import { Component, OnInit, EventEmitter, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Globals } from '../../../services/globals';
import moment from 'moment';


@Component({
    selector: 'calendar-date-selector',
    templateUrl: './calendar-date-selector.component.html',
    styleUrls: ['./calendar-date-selector.component.scss']
})

export class CalendarDateSelectorComponent implements OnInit {
    public dateChanged = new EventEmitter();
    @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
    date: Date;
    onDateSelected;
    onGoToToday;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private globals: Globals, private dialogRef: MatDialogRef<CalendarDateSelectorComponent>, private dateAdapter: DateAdapter<any>) {
        this.date = data.selectedDate;
        this.onDateSelected = data.onDateSelected;
        this.onGoToToday = data.onGoToToday;
        this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
        this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
    }

    goToToday() {
        this.onGoToToday();
        this.dialogRef.close();
    }

    onDateChanged(event) {
        let newDate = moment(`${moment(event).format('YYYY-MM-DD')} 08:00`).toDate();
        this.onDateSelected(newDate);
        this.dialogRef.close();
    }

    async ngAfterViewInit() {

    }

    async ngOnInit() {

    }
}
