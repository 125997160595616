import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { ActivatedRoute, Router } from '@angular/router';

import { Globals } from '../../services/globals';
import { IPriceSummary, newPriceSummary } from '../../interfaces/price';
import { Subscription } from 'rxjs';
import { Currency } from '../../services/currency';
import { Utility } from '../../services/utility';
import { formatCurrency } from '../../../../../../../../../common/common-helpers/currency';



@Component({
    selector: 'invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})

export class InvoiceComponent implements OnInit {
    invoice: any;
    private paramsSubscription?: Subscription;

    paymentStarted = false;
    constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, public currency: Currency, public utility: Utility) {

    }


    async initPayment() {
        this.paymentStarted = true;
    }


    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.invoice = await this.api.client().get<any>(`/manage/invoices/${params['id']}`);
            }
        });
    }

    currencyFormat(value){
      return formatCurrency(this.invoice.currency, value);
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }

}
