// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-column {
  background: rgba(0, 0, 0, 0.02);
}

.top {
  padding: 10px;
}

h2, h3 {
  margin-left: 10px;
}

.not-accurate {
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/z-report/z-report.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/z-report/z-report.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;ACCJ;;ADEA;EACI,aAAA;ACCJ;;ADEA;EACI,iBAAA;ACCJ;;ADGA;EACI,aAAA;ACAJ","sourcesContent":[".dark-column {\r\n    background: rgba(0, 0, 0, 0.02);\r\n}\r\n\r\n.top {\r\n    padding: 10px;\r\n}\r\n\r\nh2, h3{\r\n    margin-left: 10px;\r\n}\r\n\r\n\r\n.not-accurate{\r\n    padding:15px;\r\n}",".dark-column {\n  background: rgba(0, 0, 0, 0.02);\n}\n\n.top {\n  padding: 10px;\n}\n\nh2, h3 {\n  margin-left: 10px;\n}\n\n.not-accurate {\n  padding: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
