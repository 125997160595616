import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DomSanitizer } from '@angular/platform-browser';
import { IPriceSummary } from 'src/app/interfaces/price';
import { IBooking } from 'src/app/interfaces/booking';
import { CurrentUser } from 'projects/client-app/src/app/services/user';
import { Currency } from 'projects/client-app/src/app/services/currency';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Api } from 'projects/client-app/src/app/services/api';


export interface ICheckInPromptData {
    booking: IBooking,
    original: {
        price: IPriceSummary,
        isPaid: boolean
    }
}

@Component({
    selector: 'check-in-prompt-dialog',
    templateUrl: './check-in-prompt-dialog.component.html',
    styleUrls: ['./check-in-prompt-dialog.component.scss']
})

export class CheckInPromptDialogComponent implements OnInit {
    booking: IBooking;
    data: ICheckInPromptData;

    finish: any;
    cancel: any;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private dialogRef: MatDialogRef<CheckInPromptDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, private currency: Currency) {
        this.data = dialogData.data;
        this.booking = this.data.booking;

        this.finish = dialogData.finish;
        this.cancel = dialogData.cancel;

        this.dialogRef.backdropClick().subscribe(() => {
            this.cancel();
        });
    }

    close() {
        this.cancel();
        this.dialogRef.close();
    }

    finishCheckIn() {
        this.finish();
        this.dialogRef.close();
    }

    getButtonText() {
        return 'Test';
    }

    async ngAfterViewInit() {
        if ((this.data.original.isPaid
            && this.booking.isPaid)
            || (!this.data.original.isPaid
                && !this.booking.isPaid)) {
            if (this.data.original.price.withVat == this.booking.price.withVat) {
                this.close();
            }
        }
    }


    async ngOnInit() {

    }
}
