import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';




@Component({
    selector: 'hardware-units',
    templateUrl: './hardware-units.component.html',
    styleUrls: ['./hardware-units.component.scss']
})

export class HardwareUnitsComponent implements OnInit {
    hardwareUnits: any;
    displayedColumns = ['name', 'reset', 'restart', 'reload', 'edit'];
    pairingEnabled = false;
    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.hardwareUnits = await this.api.client().get<any[]>('/manage/hardware-units');
        this.hardwareUnits.sort(orderBy('name').asc);
    }

    async enablePairing() {
        let result = await this.api.client().post<any>(`/manage/hardware-units/enable-pairing`, {});
        if (result.succeeded)
            this.pairingEnabled = true;
    }

    async reset(hardwareUnit) {
        await this.api.client().post<any>(`/manage/hardware-units/${hardwareUnit.id}/reset`, {});
    }

    async restart(hardwareUnit) {
        await this.api.client().post<any>(`/manage/hardware-units/${hardwareUnit.id}/restart`, {});
    }

    async reload(hardwareUnit) {
        await this.api.client().post<any>(`/manage/hardware-units/${hardwareUnit.id}/reload`, {});
    }
}
