import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Globals } from '../../../../services/globals';
import { CurrentUser } from '../../../../services/user';
import { Currency } from '../../../../services/currency';
import { Utility } from '../../../../services/utility';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';


@Component({
    selector: 'journal-entry-dialog',
    templateUrl: './journal-entry-dialog.component.html',
    styleUrls: ['./journal-entry-dialog.component.scss']
})
export class JournalEntryDialogComponent implements OnInit, OnDestroy {
    entry: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<JournalEntryDialogComponent>, private translate: TranslateService, public globals: Globals, public currentUser: CurrentUser, public currency: Currency, public utility: Utility) {
    }

    ngOnDestroy() {

    }

    ngAfterViewInit() {
        console.log("ngAfterViewInit");
        this.entry = this.data;
    }

    ngOnInit() {}

    close() {
        this.dialogRef.close();
    }

    openReceipt() {
        if(this.entry.receiptUrl){
            window.open(this.entry.receiptUrl, '_blank');
        }
    }

    getBookingNumber() {
        return this.entry.bookingNumber ? `- #${this.entry.bookingNumber}` : '';
    }

    getDate(date: string) {
        return date && date != '' ? moment(date).format('YYYY-MM-DD') : '';
    }

    getParticipantCount() {
        let count = 0;
        for(let participant of this.entry.participants) {
            count++;
        }
        return count;
    }

    csvDownload() {

    }

    openEvent(eventId) {
        let url = `${this.globals.baseUrl}/client/${this.globals.clientId}/events/${eventId}`
        window.open(url);
    }
}
