import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAddOnGroup } from '../../../interfaces/booking-form';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { IAddOn } from '../../../interfaces/add-on';


@Component({
    selector: 'add-on-groups-editor',
    templateUrl: './add-on-groups-editor.component.html',
    styleUrls: ['./add-on-groups-editor.component.scss']
})

export class AddOnGroupsEditorComponent implements OnInit, OnDestroy {
    @Input()
    addOnGroups: IAddOnGroup[];
    availableAddOns: IAddOn[];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals) {

    }

    async groupImageChanged(addOnGroup, addOn) {
        let value = addOn.hasGroupImage;
        for (let addOn of addOnGroup.addOns) {
            addOn.hasGroupImage = false;
        }
        addOn.hasGroupImage = value;
    }

    async addAddOnGroup() {
        this.addOnGroups.push({
            name: '',
            expanded: false,
            addOns: []
        });
    }

    async removeAddOnGroup(addOnGroup) {
        this.addOnGroups.splice(this.addOnGroups.indexOf(addOnGroup), 1);
    }


    async addAddOn(addOnGroup, e) {
        let addOn = e.value;
        if (!addOnGroup.addOns.some(a => a.addOnId == addOn.id)) {
            addOnGroup.addOns.push({
                addOnId: addOn.id,
                name: addOn.name
            });
        }
    }

    async removeAddOn(addOnGroup, addOn) {
        addOnGroup.addOns.splice(addOnGroup.addOns.indexOf(addOn), 1);
    }


    moveAddOnGroupUp(addOnGroup) {
        let index = this.addOnGroups.indexOf(addOnGroup);
        if (index > 0) {
            this.addOnGroups.splice(index - 1, 0, this.addOnGroups.splice(index, 1)[0]);
        }
    }

    moveAddOnGroupDown(addOnGroup) {
        let index = this.addOnGroups.indexOf(addOnGroup);
        if (index < this.addOnGroups.length - 1) {
            this.addOnGroups.splice(index + 1, 0, this.addOnGroups.splice(index, 1)[0]);
        }
    }

    moveAddOnUp(addOnGroup, addOn) {
        let index = addOnGroup.addOns.indexOf(addOn);
        if (index > 0) {
            addOnGroup.addOns.splice(index - 1, 0, addOnGroup.addOns.splice(index, 1)[0]);
        }
    }

    moveAddOnDown(addOnGroup, addOn) {
        let index = addOnGroup.addOns.indexOf(addOn);
        if (index < addOnGroup.addOns.length - 1) {
            addOnGroup.addOns.splice(index + 1, 0, addOnGroup.addOns.splice(index, 1)[0]);
        }
    }


    async ngOnInit() {
        this.availableAddOns = await this.api.client().get<IAddOn[]>(`/manage/add-ons`);
    }

    async ngOnDestroy() {

    }
}
