import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { IBookingForm } from '../../interfaces/booking-form';



@Component({
    selector: 'booking-forms',
    templateUrl: './booking-forms.component.html'
})

export class BookingFormsComponent implements OnInit {
    bookingForms: IBookingForm[] | null = null;
    displayedColumns = ['name', 'edit'];

    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.bookingForms = await this.api.client().get<IBookingForm[]>('/manage/booking-forms');
        this.bookingForms.sort(orderBy('name').asc);
    }
}
