// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smiley-feedback-section-report .comments .smiley {
  width: 60px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/customer-feedback/feedback-section-report/text-feedback-section-report/text-feedback-section-report.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/customer-feedback/feedback-section-report/text-feedback-section-report/text-feedback-section-report.component.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;ACDN","sourcesContent":[".smiley-feedback-section-report {\r\n  .comments {\r\n    .smiley {\r\n      width: 60px;\r\n    }\r\n  }\r\n}\r\n",".smiley-feedback-section-report .comments .smiley {\n  width: 60px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
