import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { UsersComponent } from './components/users/users.component';
import { UserAdministrationComponent } from './components/users/user-administration.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourceAdministrationComponent } from './components/resources/resource-administration.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ActivityAdministrationComponent } from './components/activities/activity-administration.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PackageAdministrationComponent } from './components/packages/package-administration.component';
import { BookingFormsComponent } from './components/booking-forms/booking-forms.component';
import { BookingFormAdministrationComponent } from './components/booking-forms/booking-form-administration.component';
import { AddOnsComponent } from './components/add-ons/add-ons.component';
import { AddOnAdministrationComponent } from './components/add-ons/add-on-administration.component';
import { CheckInCalendarComponent } from './components/calendar/check-in-calendar.component';
import { CustomerGroupsComponent } from './components/customer-groups/customer-groups.component';
import { CustomerGroupAdministrationComponent } from './components/customer-groups/customer-group-administration.component';
import { ClientSettingsComponent } from './components/settings/client-settings.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { PromoCodesComponent } from './components/promo-codes/promo-codes.component';
import { PromoCodeAdministrationComponent } from './components/promo-codes/promo-code-administration.component';
import { CustomerFeedbackEmailsComponent } from './components/customer-feedback-emails/customer-feedback-emails.component';
import { CustomerFeedbackReportComponent } from './components/reports/customer-feedback/customer-feedback-report.component';
import { CustomTextsComponent } from './components/custom-texts/custom-texts.component';
import { ReportListComponent } from './components/reports/report-list.component';
import { BookingListReportComponent } from './components/reports/booking-list/booking-list-report.component';
import { SalesReportComponent } from './components/reports/sales/sales-report.component';
import { HardwareUnitsComponent } from './components/hardware-units/hardware-units.component';
import { HardwareUnitAdministrationComponent } from './components/hardware-units/hardware-unit-administration.component';
import { GiftCardTemplatesComponent } from './components/gift-card-templates/gift-card-templates.component';
import { GiftCardTemplateAdministrationComponent } from './components/gift-card-templates/gift-card-template-administration.component';
import { GiftCardReportComponent } from './components/reports/gift-cards/gift-card-report.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoiceComponent } from './components/invoices/invoice.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { EventsComponent } from './components/events/events.component';
import { EventAdministrationComponent } from './components/events/event-administration.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { QRCodePosterPrintComponent } from './components/qr-code-poster-print/qr-code-poster-print.component';
import { ZReportComponent } from './components/reports/z-report/z-report.component';
import { EventReportComponent } from './components/reports/event/event-report.component';
import { ResourceDayReportComponent } from './components/reports/resource-day-report/resource-day-report.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DepartmentAdministrationComponent } from './components/departments/department-administration.component';
import { PrisonIslandReportComponent } from './components/reports/prison-island/prison-island-report.component';
import { GiftCardAdministrationComponent } from './components/gift-cards/gift-card-administration.component';
import { MonthlySummaryReportComponent } from './components/reports/monthly-summary-report/monthly-summary-report.component';
import { JournalReportComponent } from './components/reports/journal-report/journal-report.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: ':clientId', component: MainComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'users', component: UsersComponent },
      { path: 'users/new', component: UserAdministrationComponent },
      { path: 'users/:id', component: UserAdministrationComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'resources/new', component: ResourceAdministrationComponent },
      { path: 'resources/:id', component: ResourceAdministrationComponent },
      { path: 'activities', component: ActivitiesComponent },
      { path: 'activities/new', component: ActivityAdministrationComponent },
      { path: 'activities/:id', component: ActivityAdministrationComponent },
      { path: 'packages', component: PackagesComponent },
      { path: 'packages/new', component: PackageAdministrationComponent },
      { path: 'packages/:id', component: PackageAdministrationComponent },
      { path: 'booking-forms', component: BookingFormsComponent },
      { path: 'booking-forms/new', component: BookingFormAdministrationComponent },
      { path: 'booking-forms/:id', component: BookingFormAdministrationComponent },
      { path: 'add-ons', component: AddOnsComponent },
      { path: 'add-ons/new', component: AddOnAdministrationComponent },
      { path: 'add-ons/:id', component: AddOnAdministrationComponent },
      { path: 'calendar', component: CheckInCalendarComponent },
      { path: 'customer-groups', component: CustomerGroupsComponent },
      { path: 'customer-groups/new', component: CustomerGroupAdministrationComponent },
      { path: 'customer-groups/:id', component: CustomerGroupAdministrationComponent },
      { path: 'settings', component: ClientSettingsComponent },
      { path: 'user-settings', component: UserSettingsComponent },
      { path: 'promo-codes', component: PromoCodesComponent },
      { path: 'promo-codes/new', component: PromoCodeAdministrationComponent },
      { path: 'promo-codes/:id', component: PromoCodeAdministrationComponent },
      { path: 'custom-texts', component: CustomTextsComponent },
      { path: 'customer-feedback-emails', component: CustomerFeedbackEmailsComponent },
      { path: 'reports', component: ReportListComponent },
      { path: 'reports/customer-feedback', component: CustomerFeedbackReportComponent },
      { path: 'reports/booking-list', component: BookingListReportComponent },
      { path: 'reports/sales', component: SalesReportComponent },
      { path: 'reports/gift-cards', component: GiftCardReportComponent },
      { path: 'reports/day-sales', component: ZReportComponent },
      { path: 'reports/journal-report', component: JournalReportComponent },
      { path: 'reports/resource-day', component: ResourceDayReportComponent },
      { path: 'reports/events', component: EventReportComponent },
      { path: 'reports/prison-island', component: PrisonIslandReportComponent },
      { path: 'reports/monthly-summary', component: MonthlySummaryReportComponent },
      { path: 'hardware-units', component: HardwareUnitsComponent },
      { path: 'hardware-units/new', component: HardwareUnitAdministrationComponent },
      { path: 'hardware-units/:id', component: HardwareUnitAdministrationComponent },
      { path: 'gift-card-templates', component: GiftCardTemplatesComponent },
      { path: 'gift-card-templates/new', component: GiftCardTemplateAdministrationComponent },
      { path: 'gift-card-templates/:id', component: GiftCardTemplateAdministrationComponent },
      { path: 'gift-cards/:id', component: GiftCardAdministrationComponent },
      { path: 'invoices', component: InvoicesComponent },
      { path: 'invoices/:id', component: InvoiceComponent },
      { path: 'changelog', component: ChangelogComponent },
      { path: 'events', component: EventsComponent },
      { path: 'events/new', component: EventAdministrationComponent },
      { path: 'events/:id', component: EventAdministrationComponent },
      { path: 'departments', component: DepartmentsComponent },
      { path: 'departments/new', component: DepartmentAdministrationComponent },
      { path: 'departments/:id', component: DepartmentAdministrationComponent },
      { path: 'qr-code-poster-print/:id', component: QRCodePosterPrintComponent }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
