import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';



@Component({
    selector: 'text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss']
})

export class TextEditorComponent implements OnInit {
    @Input()
    text: any = null;
    @Input()
    placeholders: string[];
    @Input()
    inputType: 'TEXT' | 'MULTILINE' | 'WYSIWYG';

    @Output()
    contentChanged = new EventEmitter();

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }

    async onChange(text) {
        console.log(text);
        this.contentChanged.emit(text);
    }


    async ngOnInit() {
        if(!this.text){
            this.contentChanged.emit(this.text = '');
        }
    }

}
