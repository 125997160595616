import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ComponentFactoryResolver, Injector, ComponentRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as  moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { IResource } from '../../../interfaces/resource';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Currency } from '../../../services/currency';
import { IBooking } from 'src/app/interfaces/booking';
import { IPackage } from '../../../interfaces/package';
import { IResourceBooking } from 'src/app/interfaces/resource-booking';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';


interface IBookingPackageData {
    name: string,
    package: IPackage & { packageId: string },
    passed: boolean,
    resources: {
        name: string,
        resource: IResource,
        passed: boolean,
        startTime: string,
        endTime: string
    }[],
    startTime: string
}


interface IBookingData {
    startTime: string,
    endTime: string,
    passed: boolean,
    booking?: IBooking,
    packages?: IBookingPackageData[],
    resourceBooking?: IResourceBooking,
    event?: IEvent,
    eventDate?: IEventDate
}


@Component({
    selector: 'check-in-list-view',
    templateUrl: './check-in-list-view.component.html',
    styleUrls: ['./check-in-list-view.component.scss']
})
export class CheckInListViewComponent implements OnInit {
    public displayedColumns = ['bookingNumber', 'time', 'name', 'company', 'email', 'phone', 'packages', 'birthday', 'addOns', 'promoCodes', 'customerComment', 'staffComment', 'isPaid', 'hasArrived', 'price'];
    calendarOptions: any = {};
    bookingAutoFetchSuspended = false;
    selectedDate: Date = new Date();
    packages: { [resourceId: string]: IPackage } = {};

    bookingsData: IBookingData[] = [];

    @ViewChild('bookingsTable', { static: true })
    bookingsTable: MatTable<any>;

    @Input()
    set date(date: Date) {
        this.selectedDate = date;
        console.log(`Date set: ${date}`);
        this.updateDate();
    }

    get date(): Date { return this.selectedDate; }

    @Output()
    bookingClicked = new EventEmitter();

    constructor(private api: Api, private globals: Globals, private utility: Utility, private translate: TranslateService, private dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private currency: Currency) {

    }

    updateDate() {
        this.fetchBookings();
    }

    async reRender(refetch?: boolean) {
        if (refetch)
            await this.fetchBookings();
        this.bookingsTable.renderRows();
    }

    async fetchBookings() {
        let day = moment(this.selectedDate).locale('en').format('YYYY-MM-DD');
        let hasEvents = false;
        let events: IEvent[];


        let bookings = await this.api.client().get<IBooking[]>(`/bookings/by-day/${day}`);
        let resourceAllocations = (await this.api.client().get<any[]>(`/resource-allocations/by-day/${day}`)).filter(a => {
            return !this.globals.userSettings
                || !this.globals.userSettings.hiddenCalendarResources
                || !this.globals.userSettings.hiddenCalendarResources.includes(a.resourceId);
        });
        let resourceBookings = await this.api.client().get<IResourceBooking[]>(`/resource-bookings/by-day/${day}`);



        let addedPackages: { [bookingId: string]: { [packageId: string]: boolean } } = {};

        let bookingDictionary: { [bookingId: string]: IBookingData } = {};
        for (let booking of bookings) {
            bookingDictionary[booking.id] = {
                startTime: booking.localStartTime,
                endTime: booking.localEndTime,
                booking: booking,
                passed: moment(booking.endDate).isBefore(moment()),
                packages: []
            };
        }

        let resourceBookingDictionary: { [resourceBookingId: string]: IResourceBooking } = {};
        for (let resourceBooking of resourceBookings) {
            resourceBookingDictionary[resourceBooking.id] = resourceBooking;
            if (resourceBooking.eventId)
                hasEvents = true;
        }

        let eventDictionary: {
            [eventId: string]: {
                event: IEvent,
                dateDictionary: { [identifier: string]: IEventDate }
            }
        } = {};
        if (hasEvents) {
            events = await this.api.client().get<IEvent[]>(`/events/by-day/${day}`);

            for (let event of events) {
                eventDictionary[event.id] = {
                    event: event,
                    dateDictionary: {}
                }

                for (let eventDate of event.dates) {
                    eventDictionary[event.id].dateDictionary[eventDate.identifier] = eventDate;
                }
            }
        }


        let allocationsDictionary: any = {};

        for (let allocation of resourceAllocations) {
            if (!allocationsDictionary[allocation.bookingId])
                allocationsDictionary[allocation.bookingId] = {}
            if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId])
                allocationsDictionary[allocation.bookingId][allocation.uniquePackageId] = {}
            if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId])
                allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId] = {}
            if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId])
                allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId] = []
            allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId].push(allocation);
        }

        for (let allocation of resourceAllocations) {
            try {
                // Only process regular booking allocations here
                if (allocation.bookingId) {
                    let existingAllocations = allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId];

                    // Check if there is an allocation right before current allocation
                    let allocationBefore = existingAllocations.find(a => a.endTime == allocation.startTime && a.persons == allocation.persons);
                    // Check if there is an allocation right after current allocation
                    let allocationAfter = existingAllocations.find(a => a.startTime == allocation.endTime && a.persons == allocation.persons);

                    if (allocationBefore) {
                        allocationBefore.endTime = allocation.endTime;
                    }
                    else if (allocationAfter) {
                        allocationAfter.startTime = allocation.startTime;
                    }
                    else {
                        let booking = bookingDictionary[allocation.bookingId].booking;
                        let _package = null;
                        let activity = null;
                        let resource = null;
                        let followingAllocations = [];
                        for (let _p of booking.packages) {
                            for (let _a of _p.activities) {
                                for (let _r of _a.resources) {
                                    if (_r.resourceId == allocation.resourceId
                                        && _a.activityId == allocation.activityId
                                        && _p.uniquePackageId == allocation.uniquePackageId) {
                                        _package = _p;
                                        activity = _a;
                                        resource = _r;

                                        // Check if there is an activity following this activity
                                        followingAllocations = resourceAllocations.filter(a => {
                                            return a.bookingId == booking.id
                                                && a.resourceId != _r.resourceId
                                                && a.startTime == allocation.endTime
                                        }).map(a => {
                                            return Object.assign({
                                                // This finds the resource of the booking
                                                resource: booking.packages.reduce(
                                                    (res, p) => res
                                                        || p.activities.reduce((res, act) => res
                                                            || act.resources.reduce((res, r) => res
                                                                || (r.resourceId == a.resourceId ? r : null),
                                                                res),
                                                            res),
                                                    null)
                                            }, a);
                                        });
                                    }
                                }
                            }
                        }

                        if (!addedPackages[booking.id])
                            addedPackages[booking.id] = {};

                        console.log(`${_package.name}: ${booking.localDay} ${_package.endTime}`);
                        console.log(moment(`${booking.localDay} ${_package.endTime}`))

                        let existingPackage: IBookingPackageData = {
                            name: _package.name,
                            package: _package,
                            startTime: _package.startTime,
                            passed: moment(`${booking.localDay} ${_package.startTime}`).isBefore(moment()),
                            resources: []
                        };
                        if (addedPackages[booking.id][_package.packageId]) {
                            existingPackage = bookingDictionary[booking.id].packages.find(p => p.package.packageId == _package.packageId);
                        } else {
                            bookingDictionary[booking.id].packages.push(existingPackage);
                            addedPackages[booking.id][_package.packageId] = true;
                        }

                        bookingDictionary[booking.id].packages.sort((packageData1, packageData2) => {
                            if (packageData1.startTime > packageData2.startTime)
                                return 1;
                            else
                                return -1;
                        });

                        existingPackage.resources.push({
                            name: resource.name,
                            resource: resource,
                            startTime: allocation.startTime,
                            endTime: allocation.endTime,
                            passed: moment(`${booking.localDay} ${allocation.startTime}`).isBefore(moment()),
                        });

                        existingPackage.resources.sort((resource1, resource2) => {
                            if (resource1.startTime > resource2.startTime)
                                return 1;
                            else
                                return -1;
                        });

                    }
                }
            }
            catch (error) {
                console.log(`Could not create event from allocation ${JSON.stringify(allocation)}`);
                console.log(error);
            }
        }

        // BOOKINGS
        this.bookingsData = Object.values(bookingDictionary).filter(data => {
            if (this.globals.userSettings && this.globals.userSettings.hiddenCalendarResources) {
                return data.packages.reduce((value, _package) => {
                    return value || _package.resources.reduce((value, resource) => {
                        return value || !this.globals.userSettings.hiddenCalendarResources.includes(resource.resource.id);
                    }, false);
                }, false)
            }
            else {
                return true;
            }
        });

        // RESOURCE BOOKINGS
        for (let resourceBooking of resourceBookings) {
            if (resourceBooking.resources.filter((resource) => {
                return !this.globals.userSettings
                    || !this.globals.userSettings.hiddenCalendarResources
                    || !this.globals.userSettings.hiddenCalendarResources.includes(resource.resourceId);
            }).length) {
                let resourceBookingEvent;
                let resourceBookingEventDate;

                if (resourceBooking.eventId) {
                    resourceBookingEvent = eventDictionary[resourceBooking.eventId].event;
                    if (resourceBooking.eventDateIdentifier)
                        resourceBookingEventDate = eventDictionary[resourceBooking.eventId].dateDictionary[resourceBooking.eventDateIdentifier];
                }

                this.bookingsData.push({
                    startTime: resourceBooking.localStartTime,
                    endTime: resourceBooking.localEndTime,
                    passed: moment(`${resourceBooking.localDay} ${resourceBooking.localEndTime}`).isBefore(moment()),
                    resourceBooking: resourceBooking,
                    event: resourceBookingEvent,
                    eventDate: resourceBookingEventDate
                });
            }
        }

        this.bookingsData.sort((bookingData1, bookingData2) => {
            if (bookingData1.startTime > bookingData2.startTime)
                return 1;
            else
                return -1;
        });

        console.log('BookingsData:');
        console.log(this.bookingsData);
        this.bookingsTable.renderRows();
    }

    async openBooking(bookingData: IBookingData) {
        if (bookingData.booking)
            this.bookingClicked.emit({ booking: bookingData.booking });
        else if (bookingData.resourceBooking) {
            if (bookingData.event) {
                this.bookingClicked.emit({
                    event: bookingData.event,
                    eventDate: bookingData.eventDate,
                    resourceBooking: bookingData.resourceBooking
                });
            }
            else {
                this.bookingClicked.emit({ resourceBooking: bookingData.resourceBooking });
            }
        }

    }

    hasPassed(day: string, time: string) {
        return moment(`${day} ${time}`).isBefore(moment())
    }

    getBookingBackgroundColor(bookingData: IBookingData) {
        if (bookingData.event) {
            return bookingData.event.color;
        }
        else {
            return 'flurp';
        }
    }

    getBookingPackageBackgroundColor(packageData: IBookingPackageData) {
        if (this.globals.userSettings
            && this.globals.userSettings.showPackageColorsInCalendar
            && this.packages[packageData.package.packageId]) {
            return this.packages[packageData.package.packageId].color;
        }
        else {
            return 'flurp';
        }
    }


    async ngAfterViewInit() {
        let setupUserSettings = async (userSettings) => {
            if (userSettings.showPackageColorsInCalendar) {
                let packages = await this.api.client().get<IPackage[]>('/packages');
                this.packages = {};
                for (let _package of packages) {
                    this.packages[_package.id] = _package;
                }
            }
        };
        if (this.globals.userSettings)
            setupUserSettings(this.globals.userSettings);
        else
            this.globals.userSettingsReceived.subscribe(setupUserSettings);

    }

    async ngOnInit() {

    }
}
