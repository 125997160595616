import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { Utility } from '../../services/utility';
import { CurrentUser } from '../../services/user';
import { IPackage } from '../../interfaces/package';
import { environment } from 'projects/client-app/src/environments/environment';

@Component({
    selector: 'hardware-unit-administration',
    templateUrl: './hardware-unit-administration.component.html',
    styleUrls: ['./hardware-unit-administration.component.scss']
})

export class HardwareUnitAdministrationComponent implements OnInit, OnDestroy {

    hardwareUnit: any;
    private paramsSubscription?: Subscription;
    @ViewChild('tabGroup', { static: false }) tabGroup;

    availableFonts = environment.webSafeFonts;
    availablePackages: IPackage[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, private currentUser: CurrentUser) {

    }

    getAvailableTypes() {
        let types = [
            'BOOKING_LIST'
        ];

        if (this.currentUser.user && this.currentUser.user.role == 'superadmin') {
            types.push('TEST');
            types.push('CONFIGURATOR');
            types.push('TEST');
            types.push('CHECK_IN_DROP_IN');
            types.push('VIKING_BOWLING_GATEWAY');
        }
        return types;
    }


    async save() {
        // Check if hardware unit should be created or updated
        if (this.hardwareUnit && this.hardwareUnit.id) {
            let result = await this.api.client().patch<any>(`/manage/hardware-units/${this.hardwareUnit.id}`, this.hardwareUnit);
        }
        else {
            let result = await this.api.client().put<any>(`/manage/hardware-units`, this.hardwareUnit);
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/hardware-units/${result.hardwareUnit.id}`);
            }
        }
    }

    async ngOnInit() {
        this.availablePackages = await this.api.client().get<IPackage[]>('/manage/packages');
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.hardwareUnit = await this.api.client().get<any>(`/manage/hardware-units/${params['id']}`);

            }
            else {
                this.hardwareUnit = {
                    type: 'BOOKING_LIST',
                    typeSettings: {}
                };
            }

            if (!this.hardwareUnit.typeSettings) {
                this.hardwareUnit.typeSettings = {};
            }

            if (!this.hardwareUnit.typeSettings.TEST) {
                this.hardwareUnit.typeSettings.TEST = {};
            }

            if (!this.hardwareUnit.typeSettings.CHECK_IN_DROP_IN) {
                this.hardwareUnit.typeSettings.CHECK_IN_DROP_IN = {
                    backgroundColor: '#ffffff',
                    checkInType: 'DISPLAY_QR'
                };
            }

            if (!this.hardwareUnit.typeSettings.BOOKING_LIST) {
                let statusText = () => {
                    return {
                        notPaid: '',
                        paid: '',
                        checkedIn: ''
                    }
                };
                this.hardwareUnit.typeSettings.BOOKING_LIST = {
                    logoImage: null,
                    statusTexts: {
                        upcoming: statusText(),
                        twentyMinutesBefore: statusText(),
                        tenMinutesBefore: statusText(),
                        during: statusText(),
                        after: statusText()
                    },
                    packageFilter: [],
                    bookingStartViewPeriod: {
                        value: 1,
                        unit: 'hours'
                    },
                    bookingEndViewPeriod: {
                        value: 10,
                        unit: 'minutes'
                    },
                    socialMedia: {
                        website: '',
                        facebook: '',
                        instagram: ''
                    },
                    primaryColors: {
                        foreground: '#000000',
                        background: '#c1c1c1'
                    },
                    secondaryColors: {
                        foreground: '#ffffff',
                        background: '#000000'
                    },
                    fontFamily: 'Arial',
                    fontSize: 18,
                    bookingsPerPage: 10,
                    flashes:[]
                };
            }
            if (!this.hardwareUnit.typeSettings.BOOKING_LIST.socialMedia) {
                this.hardwareUnit.typeSettings.BOOKING_LIST.socialMedia = {
                    website: '',
                    facebook: '',
                    instagram: ''
                }
            }

            if (!this.hardwareUnit.typeSettings.BOOKING_LIST.bookingEndViewPeriod) {
                this.hardwareUnit.typeSettings.BOOKING_LIST.bookingEndViewPeriod = {
                    value: 10,
                    unit: 'minutes'
                }
            }

            if (!this.hardwareUnit.typeSettings.BOOKING_LIST.flashes) {
                this.hardwareUnit.typeSettings.BOOKING_LIST.flashes = []
            }


        });

    }

    newFlash(){
        this.hardwareUnit.ty
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }
}
