// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-col {
  width: 30px;
}
.toggle-col .mat-mdc-checkbox {
  vertical-align: sub;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/booking-form-mapper/booking-form-mapper.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/booking-form-mapper/booking-form-mapper.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;ACCJ;ADCI;EACI,mBAAA;ACCR","sourcesContent":[".toggle-col{\r\n    width: 30px;\r\n\r\n    .mat-mdc-checkbox{\r\n        vertical-align: sub;\r\n    }\r\n}",".toggle-col {\n  width: 30px;\n}\n.toggle-col .mat-mdc-checkbox {\n  vertical-align: sub;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
