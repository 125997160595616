import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { Currency } from 'projects/client-app/src/app/services/currency';
import { Api } from 'projects/client-app/src/app/services/api';
import { IPriceSummary } from 'src/app/interfaces/price';
import { addPriceToSummary, newPrice, newPriceSummary, summarizePriceSummaries } from '../../../../../../../../../../../common/common-helpers/price-helper';
import { IBookingTotalsData, IPaymentProviderInfo, ISalesTotalsData, ITotalsData } from '../../../../../../../../../../../common/common-interfaces/reports/sales';

@Component({
  selector: 'sales-report-table',
  templateUrl: './sales-report-table.component.html',
  styleUrls: ['./sales-report-table.component.scss']
})
export class SalesReportTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  startDate: Date = moment().subtract(1, 'month').toDate();
  @Input()
  endDate: Date = moment().toDate();
  @Input()
  searchMode: string;

  loading: boolean;
  bookingData: any;
  bookingTotals: IBookingTotalsData;
  vatTotals: {
    [vat: string]: number
  };
  vatProviderTotals: {
    [provider: string]: {
      [vat: string]: number
    }
  };
  vatTotal: number;
  giftCards: {
    name: string,
    quantity: number,
    total: IPriceSummary,
    providers: IPaymentProviderInfo
  }[];
  giftCardTotals: {
    quantity: number,
    withVat: number,
    withoutVat: number,
    providers: IPaymentProviderInfo
  };
  paymentProviders: string[] = [];

  resourceBookingData: any;
  resourceBookingTotals: ITotalsData;

  eventBookingData: any;
  eventBookingTotals: ITotalsData;

  bookingTableTotals: ISalesTotalsData;

  salesTotals: ISalesTotalsData;


  // Changes to this might need to be made in superadmin monthly report as well
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency) {

  }


  async fetchBookingData() {
    this.bookingData = await this.api.client().get<any>(`/reports/sales/bookings/data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`);

    this.bookingTotals = {
      prePaid: {
        withVat: 0,
        withoutVat: 0,
        providers: {}
      },
      notPrePaid: {
        withVat: 0,
        withoutVat: 0
      },
      total: {
        withVat: 0,
        withoutVat: 0
      }
    };

    this.vatTotals = {};
    this.vatProviderTotals = {};
    this.vatTotal = 0;

    let addToTotal = (data) => {
      for (let dataKey in data) {
        this.bookingTotals.prePaid.withVat += data[dataKey].prePaid.price.withVat;
        this.bookingTotals.prePaid.withoutVat += data[dataKey].prePaid.price.withoutVat;

        this.bookingTotals.notPrePaid.withVat += data[dataKey].notPrePaid.price.withVat;
        this.bookingTotals.notPrePaid.withoutVat += data[dataKey].notPrePaid.price.withoutVat;

        this.bookingTotals.total.withVat += (data[dataKey].prePaid.price.withVat + data[dataKey].notPrePaid.price.withVat);
        this.bookingTotals.total.withoutVat += (data[dataKey].prePaid.price.withoutVat + data[dataKey].notPrePaid.price.withoutVat);

        let addVatTotal = (vatCharges) => {
          for (let vatPercentage in vatCharges) {
            if (!this.vatTotals[vatPercentage])
              this.vatTotals[vatPercentage] = 0;
            this.vatTotals[vatPercentage] += vatCharges[vatPercentage];
            this.vatTotal += vatCharges[vatPercentage];
          }
        }

        let addProviderVatTotal = (vatCharges, provider) => {
          for (let vatPercentage in vatCharges) {
            if (!this.vatProviderTotals[provider])
              this.vatProviderTotals[provider] = {};
            if (!this.vatProviderTotals[provider][vatPercentage])
              this.vatProviderTotals[provider][vatPercentage] = 0;
            this.vatProviderTotals[provider][vatPercentage] += vatCharges[vatPercentage];
          }
        }

        addVatTotal(data[dataKey].prePaid.price.vatCharges);
        addVatTotal(data[dataKey].notPrePaid.price.vatCharges);

        for (let provider in data[dataKey].prePaid.providers) {
          if (!this.paymentProviders.includes(provider)) {
            this.paymentProviders.push(provider);
          }

          if (!this.bookingTotals.prePaid.providers[provider]) {
            this.bookingTotals.prePaid.providers[provider] = {
              quantity: 0,
              price: newPriceSummary()
            }
          }

          this.bookingTotals.prePaid.providers[provider].price = summarizePriceSummaries(this.bookingTotals.prePaid.providers[provider].price, data[dataKey].prePaid.providers[provider].price)
          this.bookingTotals.prePaid.providers[provider].quantity += data[dataKey].prePaid.providers[provider].quantity;

          addProviderVatTotal(data[dataKey].prePaid.providers[provider].price.vatCharges, provider);
        }

      }
    }

    addToTotal(this.bookingData.packages);
    addToTotal(this.bookingData.addOns);
    addToTotal(this.bookingData.extraActivities);
    addToTotal(this.bookingData.promoCodes);

    let orderedVat = {};
    let percentages = Object.keys(this.vatTotals).map(p => parseInt(p));
    percentages.sort();
    for (let vatPercentage of percentages.map(p => String(p))) {
      console.log(vatPercentage);
      if (this.vatTotals[vatPercentage])
        orderedVat[vatPercentage] = this.vatTotals[vatPercentage];
    }
    this.vatTotals = orderedVat;


  }

  async fetchGiftCardData() {
    this.giftCards = await this.api.client().get<any>(`/reports/sales/gift-cards/data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}`);
    this.giftCardTotals = {
      quantity: 0,
      withVat: 0,
      withoutVat: 0,
      providers: {}
    };

    for (let giftCard of this.giftCards) {
      this.giftCardTotals.withVat += giftCard.total.withVat;
      this.giftCardTotals.withoutVat += giftCard.total.withoutVat;
      for (let vatPercentage in giftCard.total.vatCharges) {
        if (!this.vatTotals[vatPercentage])
          this.vatTotals[vatPercentage] = 0;
        this.giftCardTotals.quantity += giftCard.quantity;
        this.vatTotals[vatPercentage] += giftCard.total.vatCharges[vatPercentage];
      }

      // Gift card payment providers
      for (let provider in giftCard.providers) {
        if (!this.giftCardTotals.providers[provider]) {
          this.giftCardTotals.providers[provider] = {
            quantity: 0,
            price: newPriceSummary()
          }
        }
        this.giftCardTotals.providers[provider].price = summarizePriceSummaries(this.giftCardTotals.providers[provider].price, giftCard.providers[provider].price)
        this.giftCardTotals.providers[provider].quantity += giftCard.providers[provider].quantity;
        this.giftCardTotals.quantity += giftCard.providers[provider].quantity;

        for (let vatPercentage in giftCard.providers[provider].price.vatCharges) {
          if (!this.vatProviderTotals[provider])
            this.vatProviderTotals[provider] = {};
          if (!this.vatProviderTotals[provider][vatPercentage])
            this.vatProviderTotals[provider][vatPercentage] = 0;
          this.vatProviderTotals[provider][vatPercentage] += giftCard.providers[provider].price.vatCharges[vatPercentage];
        }
      }
    }
  }

  async fetchResourceBookingData() {
    this.resourceBookingData = await this.api.client().get<any>(`/reports/sales/resource-bookings/data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`);

    this.resourceBookingTotals = {
      prePaid: {
        quantity: this.resourceBookingData.summary.prePaid.quantity,
        withVat: this.resourceBookingData.summary.prePaid.price.withVat,
        withoutVat: this.resourceBookingData.summary.prePaid.price.withoutVat,
        providers: {}
      },
      notPrePaid: {
        quantity: this.resourceBookingData.summary.notPrePaid.quantity,
        withVat: this.resourceBookingData.summary.notPrePaid.price.withVat,
        withoutVat: this.resourceBookingData.summary.notPrePaid.price.withoutVat
      },
      total: {
        quantity: this.resourceBookingData.summary.prePaid.quantity + this.resourceBookingData.summary.notPrePaid.quantity,
        withVat: this.resourceBookingData.summary.prePaid.price.withVat + this.resourceBookingData.summary.notPrePaid.price.withVat,
        withoutVat: this.resourceBookingData.summary.prePaid.price.withoutVat + this.resourceBookingData.summary.notPrePaid.price.withoutVat
      }
    };

    /*
    this.bookingTotals.prePaid.withVat += this.resourceBookingTotals.prePaid.withVat;
    this.bookingTotals.prePaid.withoutVat += this.resourceBookingTotals.prePaid.withoutVat;

    this.bookingTotals.notPrePaid.withVat += this.resourceBookingTotals.notPrePaid.withVat;
    this.bookingTotals.notPrePaid.withoutVat += this.resourceBookingTotals.notPrePaid.withoutVat;

    this.bookingTotals.total.withVat += this.resourceBookingTotals.total.withVat;
    this.bookingTotals.total.withoutVat += this.resourceBookingTotals.total.withoutVat;
*/

    for (let vatPercentage in this.resourceBookingData.summary.prePaid.price.vatCharges) {
      if (!this.vatTotals[vatPercentage])
        this.vatTotals[vatPercentage] = 0;
      this.vatTotals[vatPercentage] += this.resourceBookingData.summary.prePaid.price.vatCharges[vatPercentage];
    }

    for (let vatPercentage in this.resourceBookingData.summary.notPrePaid.price.vatCharges) {
      if (!this.vatTotals[vatPercentage])
        this.vatTotals[vatPercentage] = 0;
      this.vatTotals[vatPercentage] += this.resourceBookingData.summary.notPrePaid.price.vatCharges[vatPercentage];
    }

  }


  async fetchEventBookingData() {
    let eventBookingData = await this.api.client().get<any>(`/reports/sales/event-bookings/data?startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=${this.searchMode}`);

    if (eventBookingData && (eventBookingData.summary.prePaid.price.withVat || eventBookingData.summary.notPrePaid.price.withVat)) {
      this.eventBookingData = eventBookingData;
      this.eventBookingTotals = {
        prePaid: {
          quantity: this.eventBookingData.summary.prePaid.quantity,
          withVat: this.eventBookingData.summary.prePaid.price.withVat,
          withoutVat: this.eventBookingData.summary.prePaid.price.withoutVat,
          providers: this.eventBookingData.summary.prePaid.providers
        },
        notPrePaid: {
          quantity: this.eventBookingData.summary.notPrePaid.quantity,
          withVat: this.eventBookingData.summary.notPrePaid.price.withVat,
          withoutVat: this.eventBookingData.summary.notPrePaid.price.withoutVat
        },
        total: {
          quantity: this.eventBookingData.summary.prePaid.quantity + this.eventBookingData.summary.notPrePaid.quantity,
          withVat: this.eventBookingData.summary.prePaid.price.withVat + this.eventBookingData.summary.notPrePaid.price.withVat,
          withoutVat: this.eventBookingData.summary.prePaid.price.withoutVat + this.eventBookingData.summary.notPrePaid.price.withoutVat
        }
      };
      /*
      this.bookingTotals.prePaid.withVat += this.eventBookingTotals.prePaid.withVat;
      this.bookingTotals.prePaid.withoutVat += this.eventBookingTotals.prePaid.withoutVat;

      this.bookingTotals.notPrePaid.withVat += this.eventBookingTotals.notPrePaid.withVat;
      this.bookingTotals.notPrePaid.withoutVat += this.eventBookingTotals.notPrePaid.withoutVat;

      this.bookingTotals.total.withVat += this.eventBookingTotals.total.withVat;
      this.bookingTotals.total.withoutVat += this.eventBookingTotals.total.withoutVat;
*/
      for (let vatPercentage in this.eventBookingData.summary.prePaid.price.vatCharges) {
        if (!this.vatTotals[vatPercentage])
          this.vatTotals[vatPercentage] = 0;
        this.vatTotals[vatPercentage] += this.eventBookingData.summary.prePaid.price.vatCharges[vatPercentage];
      }

      for (let vatPercentage in this.eventBookingData.summary.notPrePaid.price.vatCharges) {
        if (!this.vatTotals[vatPercentage])
          this.vatTotals[vatPercentage] = 0;
        this.vatTotals[vatPercentage] += this.eventBookingData.summary.notPrePaid.price.vatCharges[vatPercentage];
      }

      for (let provider in eventBookingData.summary.prePaid.providers) {
        if (!this.paymentProviders.includes(provider)) {
          this.paymentProviders.push(provider);
        }

        for (let vatPercentage in eventBookingData.summary.prePaid.providers[provider].price.vatCharges) {
          if (!this.vatProviderTotals[provider])
            this.vatProviderTotals[provider] = {};
          if (!this.vatProviderTotals[provider][vatPercentage])
            this.vatProviderTotals[provider][vatPercentage] = 0;
          this.vatProviderTotals[provider][vatPercentage] += eventBookingData.summary.prePaid.providers[provider].price.vatCharges[vatPercentage];
        }
      }
    }
    else {
      delete this.eventBookingData;
      delete this.eventBookingTotals;
    }
  }

  getProviderVatTotal(provider) {
    if (this.vatProviderTotals[provider]) {
      return Object.values(this.vatProviderTotals[provider]).reduce((total, current) => total += current, 0);
    }
    else {
      return 0;
    }
  }


  async ngOnInit() {
    await this.fetchData();
  }

  async addTotalsDataToSalesTotals(data: ITotalsData | IBookingTotalsData) {
    this.salesTotals.prePaid.withVat += data.prePaid.withVat;
    this.salesTotals.prePaid.withoutVat += data.prePaid.withoutVat;

    for (let provider in data.prePaid.providers) {
      if (!this.salesTotals.prePaid.providers[provider])
        this.salesTotals.prePaid.providers[provider] = newPriceSummary();
      this.salesTotals.prePaid.providers[provider] = summarizePriceSummaries(this.salesTotals.prePaid.providers[provider], data.prePaid.providers[provider].price);
    }

    this.salesTotals.notPrePaid.withVat += data.notPrePaid.withVat;
    this.salesTotals.notPrePaid.withoutVat += data.notPrePaid.withoutVat;

    this.salesTotals.total.withVat += (data.notPrePaid.withVat + data.prePaid.withVat);
    this.salesTotals.total.withoutVat += (data.notPrePaid.withoutVat + data.prePaid.withoutVat);
  }

  async addTotalsDataToBookingTableTotals(data: ITotalsData | IBookingTotalsData) {
    this.bookingTableTotals.prePaid.withVat += data.prePaid.withVat;
    this.bookingTableTotals.prePaid.withoutVat += data.prePaid.withoutVat;

    for (let provider in data.prePaid.providers) {
      if (!this.bookingTableTotals.prePaid.providers[provider])
        this.bookingTableTotals.prePaid.providers[provider] = newPriceSummary();
      this.bookingTableTotals.prePaid.providers[provider] = summarizePriceSummaries(this.bookingTableTotals.prePaid.providers[provider], data.prePaid.providers[provider].price);
    }

    this.bookingTableTotals.notPrePaid.withVat += data.notPrePaid.withVat;
    this.bookingTableTotals.notPrePaid.withoutVat += data.notPrePaid.withoutVat;

    this.bookingTableTotals.total.withVat += (data.notPrePaid.withVat + data.prePaid.withVat);
    this.bookingTableTotals.total.withoutVat += (data.notPrePaid.withoutVat + data.prePaid.withoutVat);
  }

  async addGiftCardTotalsToSalesTotals() {
    for (let provider in this.giftCardTotals.providers) {
      if (!this.paymentProviders.includes(provider))
        this.paymentProviders.push(provider);

      if (!this.salesTotals.prePaid.providers[provider])
        this.salesTotals.prePaid.providers[provider] = newPriceSummary();
      this.salesTotals.prePaid.providers[provider] = summarizePriceSummaries(this.salesTotals.prePaid.providers[provider], this.giftCardTotals.providers[provider].price);
    }

    this.salesTotals.prePaid.withVat += this.giftCardTotals.withVat;
    this.salesTotals.prePaid.withoutVat += this.giftCardTotals.withoutVat;

    this.salesTotals.total.withVat += this.giftCardTotals.withVat;
    this.salesTotals.total.withoutVat += this.giftCardTotals.withoutVat;
  }

  async fetchData() {
    if (!this.loading) {
      this.paymentProviders = [];
      this.loading = true;
      this.salesTotals = {
        prePaid: {
          withVat: 0,
          withoutVat: 0,
          providers: {}
        },
        notPrePaid: {
          withVat: 0,
          withoutVat: 0
        },
        total: {
          withVat: 0,
          withoutVat: 0
        }
      };

      this.bookingTableTotals = {
        prePaid: {
          withVat: 0,
          withoutVat: 0,
          providers: {}
        },
        notPrePaid: {
          withVat: 0,
          withoutVat: 0
        },
        total: {
          withVat: 0,
          withoutVat: 0
        }
      };

      await this.fetchBookingData();
      await this.fetchGiftCardData();
      await this.fetchResourceBookingData();
      await this.fetchEventBookingData();

      if (this.bookingTotals) {
        await this.addTotalsDataToSalesTotals(this.bookingTotals);
        await this.addTotalsDataToBookingTableTotals(this.bookingTotals);
      }
      if (this.resourceBookingTotals) {
        await this.addTotalsDataToSalesTotals(this.resourceBookingTotals);
        await this.addTotalsDataToBookingTableTotals(this.resourceBookingTotals);
      }
      if (this.eventBookingTotals)
        await this.addTotalsDataToSalesTotals(this.eventBookingTotals);
      if (this.giftCardTotals)
        await this.addGiftCardTotalsToSalesTotals();


      this.loading = false;
    }
  }

  getProviderName(provider) {
    return provider != 'billmate' ? provider : 'qvickly';
  }

  paymentProviderText(paymentProvider) {
    if (paymentProvider == 'billmate')
      return 'qvickly';
    return paymentProvider;
  }

  async reload() {
    await this.fetchData();
  }

  async ngOnChanges(changes: SimpleChanges) {
    //   await this.fetchData();
  }

  async ngOnDestroy() {

  }
}
