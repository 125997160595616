export const generateNickname = (maxLength?: number) => {
    let prefixes = [
        'Captain',
        'Molly',
        'Billy',
        'King',
        'Queen',
        'Ducky',
        'Lady',
        'Nicky',
        'Goofy',
        'Piggy',
        'Fuzzy',
        'Hairy',
        'Big',
        'Little',
        'Short',
        'Fishy',
        'Poppy',
        'Donald',
        'Super',
        'Mickey',
        'Kitty',
        'Brutus',
        'Speedy',
        'Jumping',
        'Pumpkin',
        'Furious',
        'Curious',
        'Stooge',
        'Buffalo',
        'Old',
        'Thumb'
    ];
    let suffixes = [
        'McMuffin',
        'Sweatpants',
        'Longbeard',
        'Popeye',
        'Bagpipe',
        'Bobblehead',
        'Finger',
        'Nosebleed',
        'Bacon',
        'Kiwi',
        'Cheeseburger',
        'Banana',
        'Peanut',
        'Chaos',
        'Fish',
        'Trump',
        'Man',
        'Woman',
        'Bowling',
        'Speed',
        'Rally',
        'Pie',
        'George',
        'Jane',
        'McDuck',
        'Duckface',
        'Seaweed',
        'Chainsaw',
        'Gecko',
        'Buffalo',
        'McDonald',
        'Nailbiter',
        'Underpants'
    ];


    let result = `${prefixes[Math.floor(Math.random() * prefixes.length)]} ${suffixes[Math.floor(Math.random() * suffixes.length)]}`;
    if (maxLength && maxLength >= 8) {
        while (result.length > maxLength) {
            result = generateNickname(maxLength);
        }
    }

    return result;
}


export const generateTeamName = (maxLength?: number) => {
    let prefixes = [
        'Goofy',
        'Fuzzy',
        'Hairy',
        'Big',
        'Little',
        'Short',
        'Fishy',
        'Super',
        'Jumping',
        'Furious',
        'Curious',
        'Old',
        'Team'
    ];
    let suffixes = [
        'Sweatpants',
        'Bagpipes',
        'Bobbleheads',
        'Fingers',
        'Kiwis',
        'Cheeseburgers',
        'Men',
        'Women',
        'Pies',
        'Duckfaces',
        'Seaweeds',
        'Chainsaws',
        'Geckos',
        'Buffalos',
        'Nailbiters',
        'Underpants'
    ];


    let result = `${prefixes[Math.floor(Math.random() * prefixes.length)]} ${suffixes[Math.floor(Math.random() * suffixes.length)]}`;
    if (maxLength && maxLength >= 8) {
        while (result.length > maxLength) {
            result = generateNickname(maxLength);
        }
    }

    return result;
}