export type CountryCode = 'se' | 'no' | 'dk' | 'fi' | 'fr' | 'nl' | 'de' | 'ch' | 'ae' | 'es' | 'be' | 'gb' | 'nz' | 'eg' | 'pl' | 'in' | 'ie' | 'cz' | 'pt' | 'it' | 'au';


export const availableCountries = [
    {
        name: 'Sverige',
        code: 'se'
    },
    {
        name: 'Norge',
        code: 'no'
    },
    {
        name: 'Danmark',
        code: 'dk'
    },
    {
        name: 'Finland',
        code: 'fi'
    },
    {
        name: 'Frankrike',
        code: 'fr'
    },
    {
        name: 'Holland',
        code: 'nl'
    },
    {
        name: 'Tyskland',
        code: 'de'
    },
    {
        name: 'Schweiz',
        code: 'ch'
    },
    {
        name: 'Förenade Arabemiraten',
        code: 'ae'
    },
    {
        name: 'Spanien',
        code: 'es'
    },
    {
        name: 'Nya Zealand',
        code: 'nz'
    },
    {
        name: 'Belgien',
        code: 'be'
    },
    {
        name: 'Storbritannien',
        code: 'gb'
    },
    {
        name: 'Egypten',
        code: 'eg'
    },
    {
        name: 'Polen',
        code: 'pl'
    },
    {
        name: 'Saudiarabien',
        code: 'sa'
    },
    {
        name: 'Indien',
        code: 'in'
    },
    {
        name: 'Irland',
        code: 'ie'
    },
    {
        name: 'Tjeckien',
        code: 'cz'
    },
    {
        name: 'Portugal',
        code: 'pt'
    },
    {
        name: 'Italien',
        code: 'it'
    },
    {
        name: 'Österrike',
        code: 'au'
    },
];