import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../services/globals';

@Component({
    selector: 'quantity-chooser',
    templateUrl: './quantity-chooser.component.html',
    styleUrls: ['./quantity-chooser.component.scss']
})

export class QuantityChooserComponent implements OnInit {
    @Output()
    quantityChanged = new EventEmitter();
    @Input()
    value: number = 0;
    @Input()
    min: number = 0;
    @Input()
    max: number = 999;
    @Input()
    disabled: boolean;
    @Input()
    slim = false;
    constructor(private globals: Globals, private translate: TranslateService) {

    }

    async increment() {
        if (this.value < this.max) {
            let oldValue = this.value;
            this.value++;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue
            });
        }
    }

    async decrement() {
        if (this.value > this.min) {
            let oldValue = this.value;
            this.value--;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue
            });
        }
    }

    async setValue(value) {
        this.value = value;
    }


    async changed(revert?) {
        if (this.value >= 0) {
            this.quantityChanged.emit({
                value: this.value,
                revert: revert
            });
        }
        else {
            this.value = 0;
        }
    }


    async ngOnInit() {
    }
}
