import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'new-quantity-chooser',
    templateUrl: './new-quantity-chooser.component.html',
    styleUrls: ['./new-quantity-chooser.component.scss']
})

export class NewQuantityChooserComponent implements OnInit {
    @Output()
    quantityChanged = new EventEmitter();
    @Input()
    value: number = 0;
    @Input()
    min: number = 0;
    @Input()
    max: number = 999;
    @Input()
    disabled: boolean;
    constructor(private translate: TranslateService) {

    }

    async increment() {
        if (this.value < this.max) {
            let oldValue = this.value;
            this.value++;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue
            });
        }
    }

    async decrement() {
        if (this.value > this.min) {
            let oldValue = this.value;
            this.value--;
            this.changed(() => {
                console.log(`Reverted from ${this.value} to ${oldValue}`);
                this.value = oldValue
            });
        }
    }

    async keyUp() {
        if (this.value < this.min) {
            console.log(`Reverted from ${this.value} to ${this.min}`);
            this.value = this.min;
        }
        else if (this.value > this.max) {
            console.log(`Reverted from ${this.value} to ${this.max}`);
            this.value = this.max;
        } else{
            this.changed(() => {

            });
        }

    }

    async setValue(value) {
        this.value = value;
    }


    async changed(revert?) {
        if (this.value >= 0) {
            this.quantityChanged.emit({
                value: this.value,
                revert: revert
            });
        }
        else {
            this.value = 0;
        }
    }


    async ngOnInit() {
    }
}
