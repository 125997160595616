import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { DateAdapter } from '@angular/material/core';
import { IGiftCard } from 'src/app/interfaces/gift-card';
import { Currency } from '../../../services/currency';

@Component({
    selector: 'gift-card-report',
    templateUrl: './gift-card-report.component.html',
    styleUrls: ['./gift-card-report.component.scss']
})

export class GiftCardReportComponent implements OnInit, OnDestroy {
    giftCards: IGiftCard[];
    searchData: {
        code?: string,
        receiverName?: string,
        receiverEmail?: string,
        receiverPhone?: string
    } = {};
    loading: boolean = true;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>, public currency: Currency) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
        });
    }

    print() {
        window.print();
    }

    async fetchLatestGiftCards() {
        this.loading = true;
        this.giftCards = await this.api.client().get(`/gift-cards/latest/50`);
        this.loading = false;
    }

    async searchGiftCards() {
        this.loading = true;
        this.giftCards = await this.api.client().post(`/gift-cards/search`, this.searchData);
        this.loading = false;
    }

    async fetchGiftCards() {
        if (this.searchData.code
            || this.searchData.receiverEmail
            || this.searchData.receiverName
            || this.searchData.receiverPhone) {
            this.searchGiftCards();
        }
        else {
            this.fetchLatestGiftCards();
        }
    }

    getGiftCardUrl(giftCard) {
        if (giftCard.shortUrl)
            return giftCard.shortUrl.url;
        else
            return `${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${giftCard.id}/view`;
    }


    async ngOnInit() {
        this.fetchGiftCards();
    }

    async ngOnDestroy() {

    }
}
