// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-info-dialog {
  width: 700px;
  max-width: 90vw;
  min-height: 200px;
  max-height: 90vh;
}

.mat-mdc-dialog-content {
  max-height: 55vh;
}

a {
  color: black;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/first-visit-dialog/first-visit-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/first-visit-dialog/first-visit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;ACCJ;;ADGE;EACE,gBAAA;ACAJ;;ADGE;EACI,YAAA;ACAN","sourcesContent":[".event-info-dialog {\r\n    width: 700px;\r\n    max-width: 90vw;\r\n    min-height: 200px;\r\n    max-height: 90vh;\r\n  }\r\n\r\n  \r\n  .mat-mdc-dialog-content {\r\n    max-height: 55vh;\r\n  }\r\n  \r\n  a{\r\n      color:black;\r\n  }",".event-info-dialog {\n  width: 700px;\n  max-width: 90vw;\n  min-height: 200px;\n  max-height: 90vh;\n}\n\n.mat-mdc-dialog-content {\n  max-height: 55vh;\n}\n\na {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
