import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { CurrentUser } from '../../services/user';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';

@Component({
    selector: 'home',
    templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
    private paramsSubscription?: Subscription;
    constructor(private api: Api, private router: Router, private globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser) {

    }

    async ngOnInit() {

    }
}
