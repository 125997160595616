import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { Api } from '../../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';
import { IPackage } from '../../../interfaces/package';
import { IResource } from '../../../interfaces/resource';

@Component({
    selector: 'viking-bowling-gateway-settings',
    templateUrl: './viking-bowling-gateway-settings.component.html'
})

export class VikingBowlingGatewayTypeSettingsComponent implements OnInit, OnDestroy {
    @Input()
    hardwareUnit: any;
    availablePackages: IPackage[];
    availableResources: IResource[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, private currentUser: CurrentUser) {

    }

    async ngOnInit() {
        this.availablePackages = await this.api.client().get<IPackage[]>('/manage/packages');
        this.availableResources = await this.api.client().get<IResource[]>('/manage/resources');

        if (!this.hardwareUnit.typeSettings.VIKING_BOWLING_GATEWAY) {

            this.hardwareUnit.typeSettings.VIKING_BOWLING_GATEWAY = {
                mdbFilePath: '',
                packageFilter: [],
                numberOfLanes: 8,
                peoplePerLane: 4,
                laneResources: []
            };
        }

        if (!this.hardwareUnit.typeSettings.VIKING_BOWLING_GATEWAY.laneResources) {
            this.hardwareUnit.typeSettings.VIKING_BOWLING_GATEWAY.laneResources = [];
        }
    }

    addLaneResource(){
        this.hardwareUnit.typeSettings.VIKING_BOWLING_GATEWAY.laneResources.push({

        });
    }


    ngOnDestroy() {

    }

}
