// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-info-dialog {
  width: 1000px;
  max-width: 90vw;
  min-height: 200px;
  max-height: 90vh;
}
.event-info-dialog .label {
  font-size: 12px;
  font-weight: 100;
}
.event-info-dialog .text {
  font-weight: bold;
  margin-bottom: 5px;
}
.event-info-dialog .icon-col {
  width: 40px;
}
.event-info-dialog .passed {
  opacity: 0.5;
}

::ng-deep hr {
  opacity: 0.1;
}

.mat-mdc-dialog-content {
  max-height: 55vh;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/event-info-dialog/event-info-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/event-info-dialog/event-info-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;ACCF;ADCE;EACE,eAAA;EACA,gBAAA;ACCJ;ADEE;EACE,iBAAA;EACA,kBAAA;ACAJ;ADGE;EACE,WAAA;ACDJ;ADKE;EACE,YAAA;ACHJ;;ADQE;EACE,YAAA;ACLJ;;ADSA;EACE,gBAAA;ACNF","sourcesContent":[".event-info-dialog {\r\n  width: 1000px;\r\n  max-width: 90vw;\r\n  min-height: 200px;\r\n  max-height: 90vh;\r\n\r\n  .label {\r\n    font-size: 12px;\r\n    font-weight: 100;\r\n  }\r\n\r\n  .text {\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n  }\r\n\r\n  .icon-col {\r\n    width: 40px;\r\n  }\r\n\r\n\r\n  .passed{\r\n    opacity: .5;\r\n  }\r\n}\r\n\r\n::ng-deep {\r\n  hr {\r\n    opacity: 0.1;\r\n  }\r\n}\r\n\r\n.mat-mdc-dialog-content {\r\n  max-height: 55vh;\r\n}\r\n",".event-info-dialog {\n  width: 1000px;\n  max-width: 90vw;\n  min-height: 200px;\n  max-height: 90vh;\n}\n.event-info-dialog .label {\n  font-size: 12px;\n  font-weight: 100;\n}\n.event-info-dialog .text {\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n.event-info-dialog .icon-col {\n  width: 40px;\n}\n.event-info-dialog .passed {\n  opacity: 0.5;\n}\n\n::ng-deep hr {\n  opacity: 0.1;\n}\n\n.mat-mdc-dialog-content {\n  max-height: 55vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
