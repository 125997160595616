import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { IUser } from '../../interfaces/user';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';



@Component({
    selector: 'users',
    templateUrl: './users.component.html'
})

export class UsersComponent implements OnInit {
    users: IUser[] | null = null;
    clientId?: string;
    showDisabled = false;
    displayedColumns = ['username', 'name', 'role', 'lastLoggedIn', 'edit'];
    constructor(private api: Api, private router: Router, public globals: Globals) {
    }


    async ngOnInit() {
        this.users = await this.api.client().get<IUser[]>('/manage/users');
        this.users.sort(orderBy('username').asc);
        this.clientId = this.globals.clientId;
    }
}
