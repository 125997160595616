import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../services/api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { IClientNotification } from '../../../interfaces/client-notification';
import { Globals } from '../../../services/globals';

@Component({
    selector: 'notification-content-dialog',
    templateUrl: './notification-content-dialog.component.html',
    styleUrls: ['./notification-content-dialog.component.scss']
})

export class NotificationContentDialogComponent implements OnInit {
    notification: IClientNotification;
    content: string | SafeHtml;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globals: Globals, private dialogRef: MatDialogRef<NotificationContentDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer) {
        this.notification = data.notification;
        this.content = this.notification.useHtmlContent ? sanitizer.bypassSecurityTrustHtml(this.notification.content) : this.notification.content;
    }

    close() {
        this.dialogRef.close();
    }

    async ngOnInit() {
        await this.api.client().post<any>(`/notifications/${this.notification.id}/viewed`, {});
    }
}
