// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-gaming-administration,
.bottom {
  width: 700px;
  max-width: 100%;
}
.social-gaming-administration input[type=number],
.bottom input[type=number] {
  width: 4em;
  text-align: right;
}

.sgg-logo {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-info-dialog/third-party/social-gaming-administration/social-gaming-administration-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-info-dialog/third-party/social-gaming-administration/social-gaming-administration-dialog.component.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;EACA,eAAA;ACCF;ADCE;;EACE,UAAA;EACA,iBAAA;ACEJ;;ADEA;EACE,gBAAA;ACCF","sourcesContent":[".social-gaming-administration,\r\n.bottom {\r\n  width: 700px;\r\n  max-width: 100%;\r\n\r\n  input[type=\"number\"]{\r\n    width: 4em;\r\n    text-align: right;\r\n  }\r\n}\r\n\r\n.sgg-logo{\r\n  max-width: 300px;\r\n}\r\n",".social-gaming-administration,\n.bottom {\n  width: 700px;\n  max-width: 100%;\n}\n.social-gaming-administration input[type=number],\n.bottom input[type=number] {\n  width: 4em;\n  text-align: right;\n}\n\n.sgg-logo {\n  max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
