import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IActivitySequences, IPackage, IPriceSettings, IExtraOrderRow } from '../../../interfaces/package';
import { IActivity } from '../../../interfaces/activity';
import { addDuration, minutesFromDuration, getStandardColor } from '../../../helpers/utility';
import { TranslateService } from '@ngx-translate/core';
import { newPrice } from '../../../interfaces/price';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Currency } from '../../../services/currency';
import { Utility } from '../../../services/utility';


@Component({
    selector: 'timeslot-with-price-settings',
    templateUrl: './timeslot-with-price-settings.component.html',
    styleUrls: ['./timeslot-with-price-settings.component.scss']
})

export class TimeslotWithPriceSettingsComponent implements OnInit, OnChanges {
    @Input()
    priceSettings: IPriceSettings[];
    @Input()
    timeslots: any;
    @Input()
    enabledTimeslotSchemes: any;

    @Output()
    enabledTimeslotSchemeChanged = new EventEmitter();

    activePriceSettingIndex = 0;
    extraStyle: SafeHtml;

    constructor(private api: Api, public globals: Globals, public translate: TranslateService, public currency: Currency, private sanitizer: DomSanitizer, public utility: Utility) {

    }

    async onEnabledTimeslotSchemeChanged(role) {
        this.enabledTimeslotSchemeChanged.emit(role);
    }

    generateStyles() {
        let priceSettingsColorStyles = '<style>';
        this.priceSettings.forEach((priceSetting, index) => {
            priceSettingsColorStyles += `
            .mat-mdc-checkbox-checked.mat-accent.price-setting-${index} .mdc-checkbox__background,
            .color-unchecked.mat-accent.price-setting-${index} .mdc-checkbox__background  { background-color: ${priceSetting.color} !important; border-color: ${priceSetting.color} !important;}
            i.price-setting-${index} { color: ${priceSetting.color} !important; }
            `;
        });
        priceSettingsColorStyles += '</style>';
        this.extraStyle = this.sanitizer.bypassSecurityTrustHtml(priceSettingsColorStyles);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.generateStyles();
    }

    async ngOnInit() {
        if (this.priceSettings) {

        }
    }
}
