import { Globals } from './globals';
import { Injectable } from '@angular/core';
import { formatCurrency } from '../../../../../../../../common/common-helpers/currency';

@Injectable()
export class Currency {
    constructor(private globals: Globals) {

    }
    format(number, currency?) {
        if (this.globals.clientSettings && this.globals.clientSettings.currency) {
            return formatCurrency(currency || this.globals.clientSettings.currency, number);
        }
    }
}
