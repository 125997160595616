import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { orderBy } from '../../../../helpers/utility';
import { Api } from '../../../../services/api';
import { Globals } from '../../../../services/globals';
import { CurrentUser } from '../../../../services/user';
import { Utility } from '../../../../services/utility';


export interface ICashRegisterArticle {
  name: string,
  articleNumber: string,
  defaultVatRate?: number
}


@Component({
  selector: 'article-number-chooser',
  styleUrls: ['./article-number-chooser.component.scss'],
  templateUrl: './article-number-chooser.component.html'
})

export class ArticleNumberChooserComponent implements OnInit {
  @Input()
  container: { articleNumber: string } | any;
  @Input()
  articles: ICashRegisterArticle[];

  @Input()
  alignRight: boolean;

  @Input()
  articleNumberField = 'articleNumber';
  @Input()
  label: string;

  @Output()
  articleSelected: EventEmitter<any> = new EventEmitter();


  formControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<ICashRegisterArticle[]>;



  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<HTMLInputElement>;
  search: string;
  searchMode: boolean;

  value: string;
  plainTextMode: boolean = true;

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

  }

  async ngOnInit() {
    if (!this.articles) {
      if (this.globals.clientSettings
        && (this.globals.clientSettings.enableCashRegisterIntegration
          || (this.globals.clientSettings.cashRegisterSettings
            && this.globals.clientSettings.cashRegisterSettings.onboardingMode))) {
        this.articles = await this.api.client().get<ICashRegisterArticle[]>('/cash-register/articles');
        this.articles.sort(orderBy('name').asc);
        if (this.articles && this.articles.length) {
          this.plainTextMode = false;
        }
        else {
          this.plainTextMode = true;
        }
      }
      else {
        this.plainTextMode = true;
      }
    } else {
      this.plainTextMode = false;
    }

    if (this.plainTextMode)
      this.value = this.container[this.articleNumberField];


    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterArticles(value)),
    );
  }

  enableSearchMode() {
    this.searchMode = true;
    this.formControl.setValue('');
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200);
  }

  disableSearchMode() {
    this.searchMode = false;
  }

  articleNumberMissing() {
    return !this.container[this.articleNumberField]
      || this.container[this.articleNumberField] == ''
      || (this.articles && !this.articles.map(a => a.articleNumber).includes(this.container[this.articleNumberField]));
  }

  optionSelected(e) {
    this.container[this.articleNumberField] = e.option.value;
    this.searchMode = false;

    console.log(e);

    console.log('Field:');
    console.log(this.articleNumberField);

    console.log('Container');
    console.log(this.container);

    this.articleSelected.emit();
  }

  keyUp(value) {
    this.container[this.articleNumberField] = value;
    console.log(this.container[this.articleNumberField]);
    this.articleSelected.emit();
  }


  private filterArticles(value: string): ICashRegisterArticle[] {
    const filterValue = value.toLowerCase();
    if (this.articles)
      return this.articles.filter(option => option.name.toLowerCase().includes(filterValue) || option.articleNumber.toLowerCase().includes(filterValue));
  }

}
