import { Component, OnInit, EventEmitter, ViewChild, Inject, Input } from '@angular/core';
import { Utility } from '../../../services/utility';
import { DomSanitizer } from '@angular/platform-browser';
import { IResourceBooking } from 'src/app/interfaces/resource-booking';


@Component({
  selector: 'booking-search-dialog-resource-booking',
  templateUrl: './booking-search-dialog-resource-booking.component.html',
  styleUrls: ['./booking-search-dialog-resource-booking.component.scss']
})

export class BookingSearchDialogResourceBookingComponent implements OnInit {
  @Input()
  resourceBooking: IResourceBooking;
  constructor(private utility: Utility, private sanitizer: DomSanitizer) {

  }

  getIconHtml() {
    return this.sanitizer.bypassSecurityTrustHtml(this.utility.getBookingIconsHtml({ resourceBooking: this.resourceBooking }));
  }

  async ngAfterViewInit() {

  }

  async ngOnInit() {
    if (!this.resourceBooking.customer)
      this.resourceBooking.customer = {};
  }
}
