import { cloneDeep } from "lodash";
import { IOrderRow } from "../common-interfaces/order-row";
import { IPrice, IPriceSummary } from "../common-interfaces/price";

export type PriceType = 'FIXED' | 'PER_PERSON';

export const multiplyPrice = (price: IPrice, multiplier: number): IPrice => {
    let withVat = price.withVat * multiplier;
    let vat = price.vat * multiplier; // parseFloat((withVat * (price.vatPercentage / 100)).toFixed(2));
    let withoutVat = price.withoutVat * multiplier; //withVat - vat;
    return {
        withVat: withVat,
        vat: vat,
        withoutVat: withoutVat,
        vatPercentage: price.vatPercentage
    }
}

export const addPriceToSummary = (priceSummary: IPriceSummary, price: IPrice): IPriceSummary => {
    priceSummary.withVat += price.withVat;
    priceSummary.withoutVat += price.withoutVat;
    priceSummary.vat += price.vat;

    if (!priceSummary.vatCharges[price.vatPercentage])
        priceSummary.vatCharges[price.vatPercentage] = 0;
    priceSummary.vatCharges[price.vatPercentage] += price.vat;
    return priceSummary;
}

export const summarizePriceSummaries = (priceSummary1: IPriceSummary, priceSummary2: IPriceSummary, currencyMultiplier = 1): IPriceSummary => {
    let result = cloneDeep(priceSummary1);
    if (priceSummary2) {
        if (!priceSummary2.vatCharges)
            priceSummary2.vatCharges = {};
        result.withVat += (priceSummary2.withVat * currencyMultiplier);
        result.withoutVat += (priceSummary2.withoutVat * currencyMultiplier);
        result.vat += (priceSummary2.vat * currencyMultiplier);

        // Some bookings may have withVat in each vatCharge, and that's wrong 
        let vatChargesTotal = Object.values(priceSummary2.vatCharges)
            .reduce((total, current) => total += current, 0);
        let isLegacy = vatChargesTotal > (priceSummary2.vat + 1);


        for (let vatPercentage in priceSummary2.vatCharges) {
            let vat;
            if (isLegacy) {
                let factor = 1 / (1 + (parseFloat(vatPercentage) / 100));
                let withVat = priceSummary2.vatCharges[vatPercentage];
                let withoutVat = parseFloat((withVat * factor).toFixed(6));
                vat = withVat - withoutVat;
            } else {
                vat = priceSummary2.vatCharges[vatPercentage];
            }

            if (result.vatCharges[vatPercentage])
                result.vatCharges[vatPercentage] += (vat * currencyMultiplier);
            else
                result.vatCharges[vatPercentage] = (vat * currencyMultiplier);
        }
    }

    return result;
}


export const getPriceForPriceType = (price: IPrice, priceType: PriceType, persons: number) => {
    switch (priceType) {
        case "FIXED":
            return price;
        case "PER_PERSON":
            return multiplyPrice(price, persons);
    }
}

export const summarizeOrderRows = (orderRows: IOrderRow[], allowNegative: boolean, excludeGiftCards?: boolean, giftCardsOnly?: boolean): IPriceSummary => {
    let newPrice = () => {
        return {
            vat: 0,
            vatCharges: {},
            withVat: 0,
            withoutVat: 0
        }
    };
    let result: IPriceSummary = newPrice();

    for (let orderRow of orderRows) {
        let includeOrderRow = true;

        // THESE SHOULD ONLY BE GIFT_CARD_DISCOUNT IN THE FUTURE
        if (excludeGiftCards && ['GIFT_CARD', 'GIFT_CARD_DISCOUNT'].includes(orderRow.type))
            includeOrderRow = false;
        if (giftCardsOnly && !['GIFT_CARD', 'GIFT_CARD_DISCOUNT'].includes(orderRow.type))
            includeOrderRow = false;

        if (includeOrderRow) {
            if (!result.vatCharges[orderRow.unitPrice.vatPercentage])
                result.vatCharges[orderRow.unitPrice.vatPercentage] = 0;

            result.vatCharges[orderRow.unitPrice.vatPercentage] += (orderRow.unitPrice.withVat * orderRow.quantity);

            result.vat += (orderRow.unitPrice.vat * orderRow.quantity);
            result.withVat += (orderRow.unitPrice.withVat * orderRow.quantity);
            result.withoutVat += (orderRow.unitPrice.withoutVat * orderRow.quantity);
        }
    }

    if (!allowNegative && result.withVat < 0)
        return newPrice();
    return result;
}


export const compareOrderRows = (oldOrderRows: IOrderRow[], newOrderRows: IOrderRow[]): { removedOrderRows: IOrderRow[], addedOrderRows: IOrderRow[] } => {
    let result = { removedOrderRows: [], addedOrderRows: [] };

    // Find out which order rows has been removed
    for (let oldOrderRow of oldOrderRows) {
        let newOrderRow = newOrderRows.find(orderRow => {
            return orderRow.id == oldOrderRow.id
                && orderRow.unitPrice.vatPercentage == oldOrderRow.unitPrice.vatPercentage;
        });
        if (newOrderRow) {
            // Order row quantity has been decreased
            if (newOrderRow.quantity < oldOrderRow.quantity)
                result.removedOrderRows.push(Object.assign({}, oldOrderRow, { quantity: oldOrderRow.quantity - newOrderRow.quantity }));
            // Order row quantity has been increased
            else if (newOrderRow.quantity > oldOrderRow.quantity)
                result.addedOrderRows.push(Object.assign({}, newOrderRow, { quantity: newOrderRow.quantity - oldOrderRow.quantity }));
        }
        else {
            result.removedOrderRows.push(oldOrderRow);
        }
    }

    // Find out which order rows has been added
    for (let newOrderRow of newOrderRows) {
        let oldOrderRow = oldOrderRows.find(orderRow => {
            return orderRow.id == newOrderRow.id
                && orderRow.unitPrice.vatPercentage == newOrderRow.unitPrice.vatPercentage;
        });
        if (!oldOrderRow)
            result.addedOrderRows.push(newOrderRow);
    }
    return result;
}

export const flipSignOfOrderRow = (orderRow: IOrderRow): IOrderRow => {
    let result = cloneDeep(orderRow);
    result.unitPrice.withVat = -result.unitPrice.withVat;
    result.unitPrice.vat = -result.unitPrice.vat;
    result.unitPrice.withoutVat = -result.unitPrice.withoutVat;
    return result;
}

export const getPrice = (withVat: number, vatPercentage: number): IPrice => {
    let factor = 1 / (1 + (vatPercentage / 100));
    let withoutVat = parseFloat((withVat * factor).toFixed(6));
    return {
        vat: withVat - withoutVat,
        vatPercentage: vatPercentage,
        withVat: withVat,
        withoutVat: withoutVat
    }
}

export const getPriceFromWithoutVat = (withoutVat: number, vatPercentage: number): IPrice => {
    let factor = 1 + (vatPercentage / 100);
    let withVat = parseFloat((withoutVat * factor).toFixed(6));
    return {
        vat: withVat - withoutVat,
        vatPercentage: vatPercentage,
        withVat: withVat,
        withoutVat: withoutVat
    }
}


export const newPrice = (amount?: number) => {
    return {
        withVat: amount ? amount : 0,
        withoutVat: amount ? amount : 0,
        vat: 0,
        vatPercentage: 0
    }
}

export const newPriceSummary = (): IPriceSummary => {
    return {
        vat: 0,
        vatCharges: {},
        withVat: 0,
        withoutVat: 0
    }
}