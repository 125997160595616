import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Api } from '../../../services/api';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'hcert-scan-dialog',
    templateUrl: './hcert-scan-dialog.component.html',
    styleUrls: ['./hcert-scan-dialog.component.scss']
})

export class HcertScanDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<HcertScanDialogComponent>, private api: Api, translate: TranslateService) {

    }

    async ngOnInit() {

    }

    close() {
        this.dialogRef.close();
    }

    async ngAfterViewInit() {

    }

}
