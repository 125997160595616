import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';



@Component({
    selector: 'customer-feedback-emails',
    templateUrl: './customer-feedback-emails.component.html'
})

export class CustomerFeedbackEmailsComponent implements OnInit {
    feedbackSections: any[];
    newFeedbackSection: any;
    constructor(private api: Api, private router: Router, public globals: Globals) {

    }

    async createFeedbackSection() {
        let result = await this.api.client().put<any>('/manage/feedback-sections', this.newFeedbackSection);
        if (result.succeeded) {
            this.feedbackSections.push(result.feedbackSection);
            this.sortFeedbackSections();
            this.newFeedbackSection = this.getNewFeedbackSection();
        }
    }

    async saveFeedbackSection(feedbackSection) {
        await this.api.client().patch<any>(`/manage/feedback-sections/${feedbackSection.id}`, feedbackSection);
    }

    async switchFeedbackSectionOrders(feedbackSection1, feedbackSection2) {
        let sortOrder = feedbackSection1.sortOrder;
        feedbackSection1.sortOrder = feedbackSection2.sortOrder;
        feedbackSection2.sortOrder = sortOrder;
        this.sortFeedbackSections();
        await this.saveFeedbackSection(feedbackSection1);
        await this.saveFeedbackSection(feedbackSection2);
    }

    async moveFeedbackSectionUp(feedbackSection) {
        let index = this.feedbackSections.indexOf(feedbackSection);
        if (index >= 1) {
            this.switchFeedbackSectionOrders(feedbackSection, this.feedbackSections[index - 1]);
        }
    }

    async moveFeedbackSectionDown(feedbackSection) {
        let index = this.feedbackSections.indexOf(feedbackSection);
        if (index < this.feedbackSections.length - 1) {
            this.switchFeedbackSectionOrders(feedbackSection, this.feedbackSections[index + 1]);
        }
    }

    sortFeedbackSections() {
        this.feedbackSections.sort(orderBy('sortOrder').asc);
    }

    getNewFeedbackSection() {
        return {
            title: '',
            isActive: true,
            type: 'SMILEYS',
            sortOrder: (this.feedbackSections.length * 10)
        }
    }

    async ngOnInit() {
        this.feedbackSections = await this.api.client().get<any[]>('/manage/feedback-sections');
        this.sortFeedbackSections();
        this.newFeedbackSection = this.getNewFeedbackSection();
    }
}
