// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  display: block;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/custom-text-chooser/custom-text-chooser.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/custom-text-chooser/custom-text-chooser.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;ACCJ","sourcesContent":[".mat-mdc-form-field{\r\n    display: block;\r\n}",".mat-mdc-form-field {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
