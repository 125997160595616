import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { FileUploader } from 'ng2-file-upload';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})

export class ImageUploadComponent implements OnInit, OnDestroy {
    @Input()
    uploadUrl: string;
    @Input()
    container: any;
    @Input()
    imageField: string;
    @Input()
    label: string;
    @Input()
    maxSize: number = 500;

    @Output()
    imageChanged = new EventEmitter<any>();

    @Output()
    imageRemoved = new EventEmitter<any>();

    public uploading: boolean = false;
    public uploader: FileUploader;
    fileOverDropZone;
    loading = false;

    @ViewChild('fileInput')
    fileInput: ElementRef<HTMLInputElement>;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

    }


    public async deleteImage() {
        this.loading = true;
        await this.api.requester().delete(this.uploadUrl);
        this.container[this.imageField] = null;
        this.loading = false;
        this.imageRemoved.emit();
    }

    public fileOver(e: any): void {
        this.fileOverDropZone = e;
    }



    public fileDropped(e: any): void {
        console.log('File dropped');
        this.loading = true;
    }

    openFileSelect(){
        this.fileInput.nativeElement.click();
    }

    getMaxSizeText(){
        
        if(this.maxSize > 999){
            return (this.maxSize/1000) + "MB"
        }

        return this.maxSize + "kb";
    }

    async ngOnInit() {
        // Translate label
        this.translate.get(this.label).subscribe(label => {
            this.label = label;
        });


        this.uploader = new FileUploader({
            url: this.uploadUrl,
            autoUpload: true,
            maxFileSize: this.maxSize * 1024,
            allowedFileType: ['image'],
            method: 'post',
            itemAlias: 'image',
        });

        this.uploader.onSuccessItem = (item, response, status, headers) => {
            let data = JSON.parse(response);
            if (data.succeeded) {
                this.container[this.imageField] = data.image;
                this.loading = false;
                this.imageChanged.emit();
            }
            console.log('Upload complete');
            console.log(response);
        }
    }

    async ngOnDestroy() {

    }
}
