export type ClientCategory =
    'TRAMPOLINE'
    | 'PLAYLAND'
    | 'MULTICENTER'
    | 'BOWLING'
    | 'LASERTAG'
    | 'MISSION_ROOM'
    | 'GOKART'
    | 'PAINTBALL'
    | 'EVENT'
    | 'CLIMBING'
    | 'VR'
    | 'SOCIAL_GAMING'
    | 'E_SPORTS'
    | 'HIGH_ROPES'
    | 'MINI_GOLF'
    | 'ESCAPE_ROOM'
    | 'OTHER';

export const clientCategories: ClientCategory[] = [
    'TRAMPOLINE',
    'PLAYLAND',
    'MULTICENTER',
    'BOWLING',
    'LASERTAG',
    'MISSION_ROOM',
    'GOKART',
    'PAINTBALL',
    'EVENT',
    'CLIMBING',
    'VR',
    'SOCIAL_GAMING',
    'E_SPORTS',
    'HIGH_ROPES',
    'MINI_GOLF',
    'ESCAPE_ROOM',
    'OTHER'
]