import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Api } from '../../../services/api';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'unknown-text-scanned-dialog',
    templateUrl: './unknown-text-scanned-dialog.component.html',
    styleUrls: ['./unknown-text-scanned-dialog.component.scss']
})

export class UnknownTextScannedDialogComponent implements OnInit {
    text: string;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<UnknownTextScannedDialogComponent>, private api: Api, translate: TranslateService) {
        if (data) {
            this.text = data.text;
        }
    }


    close() {
        this.dialogRef.close();
    }






    async ngAfterViewInit() {

    }



    async ngOnInit() {


    }
}
