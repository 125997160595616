import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Api } from '../../../../services/api';
import { Globals } from '../../../../services/globals';
import { Utility } from '../../../../services/utility';
import { IBooking } from 'src/app/interfaces/booking';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../../../../services/currency';
import { IBookingData } from '../booking-list-report.component';

@Component({
    selector: 'booking-list',
    templateUrl: './booking-list.component.html',
    styleUrls: ['./booking-list.component.scss']
})

export class BookingListComponent implements OnInit, OnDestroy {
    @Input()
    bookingData: IBookingData[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private translate: TranslateService) {

    }

    getExtraActivities(_package) {
        return _package.activities.filter(a => a.isExtra).sort((a, b) => {
            if (a.time < b.time) { return -1; }
            if (a.time > b.time) { return 1; }
            return 0;
        });
    }

    getPaymentMethod(booking: IBooking) {
        let paymentMethod = 'CASH';
        if (booking.paymentInfo && booking.paymentInfo.length) {
            let paymentInfo = booking.paymentInfo.find(pi => pi.isPaid && pi.status == 'PAID');
            if (paymentInfo)
                paymentMethod = paymentInfo.paymentMethod;
        }
        if (paymentMethod)
            return this.translate.instant(paymentMethod);
    }


    async dateChanged() {

    }

    getPackageData(booking) {
        let packageData = [];
        for (let bookingPackage of booking.packages) {
            let _package = {
                name: bookingPackage.name,
                persons: bookingPackage.persons,
                customerGroups: bookingPackage.customerGroups,
                addOns: bookingPackage.addOns,
                activities: []
            }
            let addedActivityIds = {};
            for (let bookingActivity of bookingPackage.activities) {
                if (!addedActivityIds[bookingActivity.uniqueActivityId]) {
                    let activity = {
                        name: bookingActivity.name,
                        resources: []
                    }
                    addedActivityIds[bookingActivity.uniqueActivityId] = true;

                    let addedResourceIds = {};
                    for (let resource of bookingActivity.resources) {
                        if (!addedResourceIds[resource.resourceId]) {
                            activity.resources.push({
                                name: resource.name
                            });
                            addedResourceIds[resource.resourceId] = true;
                        }
                    }
                    _package.activities.push(activity);
                }

            }
            packageData.push(_package);
        }

        return packageData;
    }

    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
