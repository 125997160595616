import { Component, OnInit, OnDestroy } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IResource } from '../../interfaces/resource';
import { IUserSettings } from '../../../../../../../../../common/common-interfaces/user-settings';
import { CurrentUser } from '../../services/user';

@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.component.html'
})

export class UserSettingsComponent implements OnInit, OnDestroy {
    settings: IUserSettings = null;
    private paramsSubscription: Subscription;
    availableResources: IResource[];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, public currentUser: CurrentUser) {

    }

    async save() {
        let result = await this.api.client().put<any>(`/current-user/settings`, this.settings);
        if (result.succeeded) {
            this.globals.userSettings = this.settings;
        }
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            this.settings = await this.api.client().get<IUserSettings>(`/current-user/settings`);

            if (!this.settings.hiddenCalendarResources)
                this.settings.hiddenCalendarResources = [];

            this.availableResources = await this.api.client().get<IResource[]>('/resources');
        });

    }

    async ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
    }
}
