import { IMedia } from '../common-interfaces/media';
export type MediaType = 'DOCUMENT' | 'IMAGE' | 'VIDEO' | 'UNKNOWN';

export type DocumentType = 'PDF' | 'DOC' | 'DOCX' | 'ODT' | 'UNKNOWN';


export const SafeMimeTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'application/pdf',
    'video/mp4',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text'
];

export const getMediaType = (media: IMedia): MediaType => {
    switch (media.contentType.toLowerCase()) {
        case 'application/pdf':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
            return 'DOCUMENT';
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'image/gif':
            return 'IMAGE';
        case 'video/mp4':
            return 'VIDEO';
    }
    return 'UNKNOWN';
}

export const getDocumentType = (media: IMedia): DocumentType => {
    switch (media.contentType) {
        case 'application/pdf':
            return 'PDF';
        case 'application/msword':
            return 'DOC';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'DOCX';
        case 'application/vnd.oasis.opendocument.text':
            return 'ODT';
    }
    return 'UNKNOWN';
}