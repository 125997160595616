// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flashes .flash {
  margin-top: 15px;
  background: rgba(0, 0, 0, 0.05);
}

.status-texts input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/hardware-units/type-settings/booking-list-type-settings.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/hardware-units/type-settings/booking-list-type-settings.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,+BAAA;ACAR;;ADMI;EACI,qBAAA;EACA,WAAA;EACA,sBAAA;ACHR","sourcesContent":[".flashes{\r\n    .flash{\r\n        margin-top: 15px;\r\n        background: rgba(0,0,0,.05);\r\n    }\r\n}\r\n\r\n\r\n.status-texts{\r\n    input{\r\n        display: inline-block;\r\n        width: auto;\r\n        vertical-align: middle;\r\n    }\r\n}",".flashes .flash {\n  margin-top: 15px;\n  background: rgba(0, 0, 0, 0.05);\n}\n\n.status-texts input {\n  display: inline-block;\n  width: auto;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
