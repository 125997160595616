import { ITimeslotContainer } from '../interfaces/timeslot-container';
import * as moment from 'moment';

export const GenerateTimeslots = async (role: string, interval: string, container: ITimeslotContainer, emptyTimeslots: boolean, priceSettingIndex: number) => {
    container.timeslotInterval = interval;
    let date = moment().startOf('day');
    if (container) {
        if (!container.timeslots)
            container.timeslots = {};
        container.timeslots[role] = {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: []
        }
        let today = moment().startOf('day');
        while (date.clone().startOf('day').format() == today.format()) {
            let endDate = date.clone().add(parseInt(interval.split(':')[0]), 'hours').add(parseInt(interval.split(':')[1]), 'minutes');
            for (let day in container.timeslots[role]) {
                container.timeslots[role][day].push({
                    viewInTable: date.hour() >= 6,
                    startTime: date.format('HH:mm'),
                    interval: interval,
                    endTime: endDate.format('HH:mm'),
                    enabled: !emptyTimeslots && date.hour() >= 8 && date.hour() <= 20,
                    priceSettingIndex: priceSettingIndex
                });
            }
            date = endDate;
        }
    }
}


export const CloneTimeslots = async (timeslots: any, role) => {
    let result: any = {};
    result[role] = {};
    for (let dayName in timeslots[role]) {
        let resultDay = [];
        let day = timeslots[role][dayName];
        for (let time of day) {
            resultDay.push(Object.assign({}, time));
        }
        result[role][dayName] = resultDay;
    }
    return result;
}

export const CloneTimeslotsFromRole = async (timeslots: any, role) => {
    let result: any = {};
    for (let dayName in timeslots[role]) {
        let resultDay = [];
        let day = timeslots[role][dayName];
        for (let time of day) {
            resultDay.push(Object.assign({}, time));
        }
        result[dayName] = resultDay;
    }
    return result;
}