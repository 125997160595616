import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

@Injectable()
export class Api {
  constructor(private http: HttpClient, private globals: Globals) {

  }

  private requester = (baseUrl: string) => {
    let http = this.http;
    let environmentBaseUrl = environment.baseUrl;
    if (baseUrl.startsWith('http'))
      environmentBaseUrl = '';
    return {
      get: function <T>(url: string) {
        return http.get(`${environmentBaseUrl}${baseUrl}${url}`, {
          withCredentials: true
        }).toPromise() as Promise<T>
      },
      post: function <T>(url: string, data: any) {
        return http.post(`${environmentBaseUrl}${baseUrl}${url}`, data, {
          withCredentials: true
        }).toPromise() as Promise<T>
      },
      put: function <T>(url: string, data: any) {
        return http.put(`${environmentBaseUrl}${baseUrl}${url}`, data, {
          withCredentials: true
        }).toPromise() as Promise<T>
      },
      patch: function <T>(url: string, data: any) {
        return http.patch(`${environmentBaseUrl}${baseUrl}${url}`, data, {
          withCredentials: true
        }).toPromise() as Promise<T>
      },
      delete: function <T>(url: string) {
        return http.delete(`${environmentBaseUrl}${baseUrl}${url}`, {
          withCredentials: true
        }).toPromise() as Promise<T>
      }
    }
  }

  public fromUrl = (url: string) => {
    return this.requester(url);
  }

  public base = () => {
    return this.requester('/api');
  }

  public client = () => {
    return this.requester('/api/public/clients/' + this.globals.clientId);
  }

  public publicClientUrl = (url = '') => {
    return `/api/public/clients/${this.globals.clientId}${url}`;
  }


  public public = () => {
    return this.requester('/api/public');
  }


}
