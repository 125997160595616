// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-chooser {
  margin: 5px;
  display: inline-block;
}
.color-chooser .color-box {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  border: 2px solid black;
  cursor: pointer;
  vertical-align: middle;
}

.content {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/color-chooser/color-chooser.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/color-chooser/color-chooser.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,qBAAA;ACCJ;ADAI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,uBAAA;EACA,eAAA;EACA,sBAAA;ACER;;ADEA;EACI,YAAA;ACCJ","sourcesContent":[".color-chooser{\r\n    margin: 5px;\r\n    display: inline-block;\r\n    .color-box{\r\n        height:30px;\r\n        width:30px;\r\n        margin-right:10px;\r\n        display: inline-block;\r\n        border:2px solid black;\r\n        cursor:pointer;\r\n        vertical-align: middle;\r\n    }\r\n}\r\n\r\n.content{\r\n    opacity: .5;\r\n}",".color-chooser {\n  margin: 5px;\n  display: inline-block;\n}\n.color-chooser .color-box {\n  height: 30px;\n  width: 30px;\n  margin-right: 10px;\n  display: inline-block;\n  border: 2px solid black;\n  cursor: pointer;\n  vertical-align: middle;\n}\n\n.content {\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
