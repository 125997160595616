// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.token {
  position: relative;
}
.token .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(5px);
}
.token pre {
  width: 400px;
  white-space: pre-wrap;
  line-break: anywhere;
}
.token.blurred pre {
  filter: blur(4px);
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/settings/api-keys/api-keys.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/settings/api-keys/api-keys.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;ACCJ;ADAI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;ACER;ADCI;EACI,YAAA;EACA,qBAAA;EACA,oBAAA;ACCR;ADIQ;EACI,iBAAA;ACFZ","sourcesContent":[".token {\r\n    position: relative;\r\n    .overlay {\r\n        position: absolute;\r\n        top: 0px;\r\n        left: 0px;\r\n        right: 0px;\r\n        bottom: 0px;\r\n        filter: blur(5px);\r\n    }\r\n\r\n    pre{\r\n        width: 400px;\r\n        white-space: pre-wrap;\r\n        line-break: anywhere;\r\n    }\r\n\r\n    &.blurred{\r\n\r\n        pre{\r\n            filter: blur(4px);\r\n        }\r\n    }\r\n}\r\n",".token {\n  position: relative;\n}\n.token .overlay {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  filter: blur(5px);\n}\n.token pre {\n  width: 400px;\n  white-space: pre-wrap;\n  line-break: anywhere;\n}\n.token.blurred pre {\n  filter: blur(4px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
