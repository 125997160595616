import { Component, OnInit, EventEmitter, ViewChild, Inject, Input, Output } from '@angular/core';
import { Api } from '../../../../services/api';
import { Currency } from '../../../../services/currency';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../services/globals';
import { IBooking } from '../../../../../../../../src/app/interfaces/booking';


@Component({
    selector: 'check-in-section',
    templateUrl: './check-in-section.component.html',
    styleUrls: ['./check-in-section.component.scss']
})

export class CheckInSectionComponent implements OnInit {
    @Input()
    public booking: IBooking;
    @Output()
    public bookingChanged = new EventEmitter();
    @Output()
    public bookingCanceled = new EventEmitter();
    public checkingIn = false;
    public showPaymentText = false;
    public showCancelSureText = false;
    constructor(private api: Api, public currency: Currency, private translate: TranslateService, public globals: Globals) {

    }

    async checkIn() {
        if (!this.checkingIn) {
            if (!this.booking.isPaid) {
                if (!this.showPaymentText) {
                    this.showPaymentText = true;
                    return;
                }
            }

            this.checkingIn = true;
            let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/check-in`, {});
            if (result.succeeded) {
                let booking = await this.api.client().get<IBooking>(`/bookings/${this.booking.id}`);
                this.booking.hasArrived = true; //booking.hasArrived;
                this.booking.arrivalDate = booking.arrivalDate;
                this.booking.isPaid = booking.isPaid;
                this.booking.log = booking.log;
                this.bookingChanged.emit(this.booking);
                this.checkingIn = false;
            } else {

            }

        }
    }

    async cancel() {
        if (!this.showCancelSureText) {
            this.showCancelSureText = true;
        }
        else {
            this.checkingIn = true;
            let result = await this.api.publicClient().post<any>(`/bookings/${this.booking.id}/cancel`, {});
            if (result.succeeded) {
                let booking = await this.api.client().get<IBooking>(`/bookings/${this.booking.id}`);
                this.booking.isCanceled = booking.isCanceled;
                this.booking.cancelDate = booking.cancelDate;
                this.booking.log = booking.log;
                this.bookingCanceled.emit(this.booking);
            }
            this.checkingIn = true;
        }
    }

    getCheckInButtonText() {
        return this.showPaymentText ? this.translate.instant('HAVE_YOU_BEEN_PAID') : this.translate.instant('CHECK_IN_BUTTON');
    }

    getCancelButtonText() {
        return this.showCancelSureText ? this.translate.instant('ARE_YOU_SURE') : this.translate.instant('CANCEL_BOOKING');
    }


    async ngAfterViewInit() {

    }

    async ngOnInit() {

    }
}
