import { Injectable, EventEmitter, Injector } from "@angular/core";
import { Api } from './api';
import { environment } from '../../environments/environment';
import { IUserSettings } from "../../../../../../../../common/common-interfaces/user-settings";
import { SeasonalTheme, Utility } from "./utility";



@Injectable()
export class Globals {
  clientId?: string;
  clientSettings?: any;
  userSettings?: IUserSettings;
  language: string;
  clientSettingsReceived: EventEmitter<any> = new EventEmitter<any>();
  clientSettingsPromise: Promise<any>;
  userSettingsReceived: EventEmitter<IUserSettings> = new EventEmitter<IUserSettings>();
  baseUrl = environment.baseUrl;
  private api: Api;
  seasonalTheme: SeasonalTheme;

  constructor(private injector: Injector, private utility: Utility) {
    this.language = navigator.languages[0] || navigator.language;
    if (window.location.hash.includes('new-year')) {
      this.seasonalTheme = 'NEW_YEAR';
    }
    else if (window.location.hash.includes('easter')) {
      this.seasonalTheme = 'EASTER';
    }
    else if (window.location.hash.includes('midsummer')) {
      this.seasonalTheme = 'MIDSUMMER';
    }
    else if (window.location.hash.includes('halloween')) {
      this.seasonalTheme = 'HALLOWEEN';
    }
    else if (window.location.hash.includes('christmas')) {
      this.seasonalTheme = 'CHRISTMAS';
    }
    else if (window.location.hash.includes('april-fools')) {
      this.seasonalTheme = 'APRIL_FOOLS';
    }
    else if (window.location.hash.includes('halloween')) {
      this.seasonalTheme = 'HALLOWEEN';
    }
    else {
      this.seasonalTheme = this.utility.getSeasonalTheme();
    }
  }

  getBookingReferenceUrl(bookingId) {
    return `${this.baseUrl}/api/public/clients/${this.clientId}/bookings/${bookingId}/ticket`;
  }

  getResourceBookingReferenceUrl(bookingId) {
    return `${this.baseUrl}/api/public/clients/${this.clientId}/resource-bookings/${bookingId}/ticket`;
  }

  getEventBookingReferenceUrl(bookingId) {
    return `${this.baseUrl}/api/public/clients/${this.clientId}/event-bookings/${bookingId}/ticket`;
  }

  getGiftCardReferenceUrl(giftCardId) {
    return `${this.baseUrl}/api/public/clients/${this.clientId}/gift-cards/${giftCardId}/view`;
  }

  async setClientId(clientId: string) {

    if (!this.api)
      this.api = this.injector.get(Api);
    this.clientId = clientId;


    this.clientSettingsPromise = this.api.client().get<any>('/settings');
    this.clientSettings = await this.clientSettingsPromise;
    this.clientSettingsReceived.emit(this.clientSettings);
    this.userSettings = await this.api.client().get<any>('/current-user/settings');



    if (!this.userSettings.calendarPreferences) {
      this.userSettings.calendarPreferences = {
        expandedResources: {}
      }
    }

    this.userSettingsReceived.emit(this.userSettings);
  }

  async saveUserSettings() {
    await this.api.client().put<any>('/current-user/settings', this.userSettings);
  }
}
