import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { IBooking, IBookingAddOn } from '../../../../../../../../../src/app/interfaces/booking';
import { Globals } from '../../../../../services/globals';
import { CurrentUser } from '../../../../../services/user';
import { IPrisonIslandParticipant, IPrisonIslandTeam } from '../../../../../../../../../../../../common/common-third-party/prison-island/interfaces';
import { Utility } from '../../../../../services/utility';


@Component({
  selector: 'prison-island-registration-dialog',
  templateUrl: './prison-island-registration-dialog.component.html',
  styleUrls: ['./prison-island-registration-dialog.component.scss']
})

export class PrisonIslandRegistrationDialogComponent implements OnInit {
  public booking: IBooking;
  selectedTeam: IPrisonIslandTeam;
  selectedParticipant: IPrisonIslandParticipant;
  loading: boolean;
  editTeamData: {
    name: string,
    tagId: string
  };
  scanSucceeded: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<PrisonIslandRegistrationDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, public utility: Utility) {
    this.booking = data.booking;

    this.dialogRef.backdropClick().subscribe(async () => {
      await this.close();
    });
  }


  async close() {
    this.dialogRef.close();
    this.data.releaseQRScanHijack();
  }

  async ngOnInit() {
    this.data.hijackQRScan((data) => {
      this.tagScanned(data);
    });
  }

  async selectTeam(team) {
    this.selectedTeam = team;
    this.editTeamData = {
      name: this.selectedTeam.name,
      tagId: this.selectedTeam.tagId
    }
  }

  async removeSelectedParticipant() {
    this.loading = true;
    let result = await this.api.client().delete<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant/${this.selectedParticipant.identifier}`);
    if (result.succeeded) {
      this.booking.gameIntegrationData.prisonIslandData = result.data;
    }

    delete this.selectedParticipant;
    this.loading = false;
  }

  async saveSelectedParticipant() {
    this.loading = true;
    let result = await this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant`, this.selectedParticipant);
    if (result.succeeded) {

    }

    delete this.selectedParticipant;
    this.loading = false;
  }

  async selectParticipant(participant) {
    this.selectedParticipant = participant;
  }

  getTeamParticipants(team) {
    return this.booking.gameIntegrationData.prisonIslandData.participants.filter(p => p.teamIdentifier == team.identifier);
  }

  getTeamParticipantsWithoutTeam() {
    return this.booking.gameIntegrationData.prisonIslandData.participants.filter(p => !p.teamIdentifier);
  }



  async newTeam() {
    let newTeam = {
      identifier: this.utility.uuid(),
      name: ''
    }
    this.booking.gameIntegrationData.prisonIslandData.teams.push(newTeam);
    this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/team`, newTeam);
  }

  async newParticipant() {
    let newParticipant: IPrisonIslandParticipant = {
      identifier: this.utility.uuid(),
      role: 'PARTICIPANT',
      name: '',
      teamIdentifier: this.booking.gameIntegrationData.prisonIslandData.teams.length == 1 ? this.booking.gameIntegrationData.prisonIslandData.teams[0].identifier : null
    }
    this.booking.gameIntegrationData.prisonIslandData.participants.push(newParticipant);
    this.selectedParticipant = newParticipant;
    this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/participant`, newParticipant);
  }

  async saveTeam() {
    this.loading = true;
    let result = await this.api.client().put<any>(`/bookings/${this.booking.id}/third-party/prison-island/team`, Object.assign({}, this.selectedTeam, this.editTeamData));
    if (result.succeeded) {
      Object.assign(this.selectedTeam, this.editTeamData);
    }
    delete this.selectedTeam;
    delete this.editTeamData;
    this.scanSucceeded = false;
    this.loading = false;
  }

  async removeTeam() {
    this.loading = true;
    let result = await this.api.client().delete<any>(`/bookings/${this.booking.id}/third-party/prison-island/team/${this.selectedTeam.identifier}`);
    if (result.succeeded) {
      this.booking.gameIntegrationData.prisonIslandData = result.data;
    }

    delete this.selectedTeam;
    delete this.editTeamData;
    this.loading = false;
  }

  async tagScanned(data: string) {

    // Fix if focus is on team name input
    if (this.editTeamData.name) {
      this.editTeamData.name = this.editTeamData.name.replace(data, '');
    }



    if (data.length == 16) {
      data = data.substring(6);
      console.log(`Tag substring: ${data}`);
    }

    console.log(`Key without checksum: ${data}`);

    // Checksum stuff
    if (data.length == 10) {
      data = data.slice(Math.max(data.length - 10, 0))

      // Applying checksum to the key
      var checkSum = parseInt(data[0] + data[1], 16) ^ parseInt(data[2] + data[3], 16) ^ parseInt(data[4] + data[5], 16) ^ parseInt(data[6] + data[7], 16) ^ parseInt(data[8] + data[9], 16);
      var keyTag;
      if (checkSum.toString(16).length > 1)
        keyTag = data + checkSum.toString(16);
      else
        keyTag = data + '0' + checkSum.toString(16);

      //var keyTag = input + checkSum.toString(16)

      keyTag = keyTag.toUpperCase();
      data = keyTag;

      console.log(`Key with checksum: ${data}`);
    }


    if (this.editTeamData) {
      this.editTeamData.tagId = data;
      this.scanSucceeded = true;
    }


  }

  ngOnDestroy() {

  }
}
