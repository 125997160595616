import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Api } from '../../services/api';
import { CurrentUser } from '../../services/user';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IUser } from '../../interfaces/user';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationContentDialogComponent } from '../notification-center/notification-content-dialog/notification-content-dialog.component';
import { SeasonalTheme, Utility } from '../../services/utility';

@Component({
  selector: 'main',
  templateUrl: './main.component.html'
})

export class MainComponent implements OnInit {
  user: IUser | null = null;
  clientId?: string;
  private paramsSubscription?: Subscription;
  menuOpened: boolean;
  pinnedNotification: any;


  constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser, private translate: TranslateService, private dateAdapter: DateAdapter<Date>, private dialog: MatDialog, private utility: Utility) {
    this.api = api;
    this.router = router;
  }

  openPinnedNotification() {
    this.dialog.open(NotificationContentDialogComponent, {
      data: { notification: this.pinnedNotification }
    });
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }
  openMenu() {
    this.menuOpened = true;
  }
  closeMenu() {
    this.menuOpened = false;
  }

  isReportAvailable(reportIdentifier: string) {
    return ['superadmin', 'manager'].includes(this.user.role) || this.user.availableReports[reportIdentifier];
  }

  async logOut() {
    await this.api.base().post<any>(`/client/log-out`, {});
    window.location.href = '/client/login';
  }

  async ngOnInit() {
    if ((window as any).CKEDITOR)
      (window as any).CKEDITOR.config.allowedContent = true;
    this.paramsSubscription = this.route.params.subscribe(async (params) => {
      if (params['clientId']) {
        this.clientId = params['clientId'];
        this.globals.setClientId(this.clientId);
        this.globals.clientSettingsReceived.subscribe(clientSettings => {
          this.translate.use(this.globals.clientSettings.language);
          moment.locale(this.globals.clientSettings.language);

          console.log(`Language: ${this.globals.clientSettings.language}`);
          this.dateAdapter.setLocale(this.globals.clientSettings.language);
          this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
        })
        this.user = await this.currentUser.get();


        this.pinnedNotification = await this.api.client().get<any>(`/notifications/pinned`);
      }
      else {
        console.log('No clientId');
        window.location.href = '/';
      }

      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        let user = await this.currentUser.get();
        if (user) {
          // this.router.navigateByUrl('/' + this.globals.clientId);
        }
        else {
          this.router.navigateByUrl(this.globals.clientId + '/login');
        }
      }
      else {
        this.router.navigateByUrl(this.globals.clientId + '/login');
      }

    });
  }
}
