import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';

@Component({
    selector: 'customer-group-administration',
    templateUrl: './customer-group-administration.component.html'
})

export class CustomerGroupAdministrationComponent implements OnInit, OnDestroy {

    customerGroup: any = null;
    private paramsSubscription?: Subscription;
    @ViewChild('tabGroup', { static: false }) tabGroup;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals) {

    }
    
    async save() {
        // Check if customerGroup should be created or updated
        if (this.customerGroup && this.customerGroup.id) {
            let result = await this.api.client().patch<any>(`/manage/customer-groups/${this.customerGroup.id}`, this.customerGroup);
        }
        else {
            let result = await this.api.client().put<any>(`/manage/customer-groups`, this.customerGroup);
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/customer-groups/${result.customerGroup.id}`);
            }
        }
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.customerGroup = await this.api.client().get<any>(`/manage/customer-groups/${params['id']}`);
            }
            else {
                this.customerGroup = { };
            }
        });

    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }
}
