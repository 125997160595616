import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { Api } from '../../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';
import { IPackage } from '../../../interfaces/package';
import { environment } from 'projects/client-app/src/environments/environment';

@Component({
    selector: 'test-type-settings',
    templateUrl: './test-type-settings.component.html'
})

export class HardwareUnitTestTypeSettingsComponent implements OnInit, OnDestroy {
    @Input()
    hardwareUnit: any;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, private currentUser: CurrentUser) {

    }

    async ngOnInit() {

            if (!this.hardwareUnit.typeSettings.TEST) {
                this.hardwareUnit.typeSettings.TEST = {
                    backgroundColor: '',
                    relayPins: []
                }
            }
            if (!this.hardwareUnit.typeSettings.TEST.relayPins) {
                this.hardwareUnit.typeSettings.TEST.relayPins = [];
            }
    }

    async newRelayPin() {
        this.hardwareUnit.typeSettings.TEST.relayPins.push(0)
    }

    async removeRelayPin(index) {
        this.hardwareUnit.typeSettings.TEST.flashes.splice(index, 1);
    }

    ngOnDestroy() {

    }

}
