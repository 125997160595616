import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { DateAdapter } from '@angular/material/core';
import { IPackage } from '../../../interfaces/package';
import { IResourceBooking } from '../../../../../../../src/app/interfaces/resource-booking';
import { IBookingBase } from '../../../../../../../src/app/interfaces/booking-base';
import { IBookingData } from '../booking-list/booking-list-report.component';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';
import { ChartDataset, LegendOptions, ChartOptions, ChartType } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'event-report',
  templateUrl: './event-report.component.html',
  styleUrls: ['./event-report.component.scss']
})
export class EventReportComponent implements OnInit, OnDestroy {
  availableEvents: IEvent[];
  availableEventDates: string[];
  events: IEvent[];
  eventDate: string;
  selectedInterval = "00:15";

  data: { time: string, persons: number, count: number }[];
  totals: { persons: number, bookings: number };
  arrived: { persons: number, bookings: number };
  attendances: { days: number, persons: number }[];

  public graphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };

  loading = false;

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>, private translate: TranslateService) {

  }

  print() {
    window.print();
  }

  async eventChanged() {
    this.availableEventDates = [];
    for (let event of this.events) {
      for (let eventDate of event.dates) {
        if (!this.availableEventDates.includes(eventDate.day))
          this.availableEventDates.push(eventDate.day);
      }
    }
    delete this.graphData;
    this.fetchAttendanceData();
    if (this.availableEventDates.includes(this.eventDate))
      this.fetchArrivalData();
    else
      delete this.eventDate;
  }

  async eventDateChanged() {
    await this.fetchArrivalData();
  }

  async intervalChanged() {
    await this.fetchArrivalData();
  }

  async refreshData() {

  }


  async fetchEvents() {
    this.availableEvents = await this.api.client().get<IEvent[]>(`/reports/events/available-events`);
  }

  async fetchAttendanceData() {
    this.loading = true;
    delete this.attendances;
    let result: { [count: string]: number } = {};
    if (this.events.length) {
      for (let event of this.events) {
        let data = await this.api.client().get<any>(`/reports/events/event/${event.id}/attendance`);
        for (let count in data) {
          if (!result[count])
            result[count] = data[count];
          else
            result[count] += data[count];
        }
      }
      this.attendances = [];
      console.log(result);
      let maxDays = Object.keys(result).map(r => parseInt(r)).reduce((previous, current) => {
        return Math.max(previous, current);
      }, 0);
      console.log(`Max days: ${maxDays}`);
      for (let i = 0; i <= maxDays; i++) {
        if (result[String(i)])
          this.attendances.push({
            days: i,
            persons: result[String(i)]
          });
        else
          this.attendances.push({
            days: i,
            persons: 0
          });
      }
    }
    this.loading = false;
  }

  async fetchArrivalData() {
    this.loading = true;
    this.data = [];
    this.totals = { persons: 0, bookings: 0 };
    this.arrived = { persons: 0, bookings: 0 };
    delete this.graphData;
    for (let event of this.events) {
      let eventDate = event.dates.find(d => d.day == this.eventDate);
      if (eventDate) {
        let result = await this.api.client().get<any>(`/reports/events/event/${event.id}/date/${eventDate.identifier}/arrival-times/${this.selectedInterval}`);
        for (let arrival of result.arrivals) {
          let time = this.data.find(t => t.time == arrival.time);
          if (time) {
            time.persons += arrival.persons;
            time.count += arrival.count;
          }
          else {
            this.data.push(arrival);
          }

        }
        this.totals.persons += result.totals.persons;
        this.totals.bookings += result.totals.bookings;
        this.arrived.persons += result.arrived.persons;
        this.arrived.bookings += result.arrived.bookings;
      }
    }
    this.data.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    })
    this.graphData = {
      chartType: 'bar',
      datasets: [
        {
          label: this.translate.instant('CHECKED_IN_PERSONS'),
          borderColor: '',
          backgroundColor: `#76c5e053`,
          hoverBackgroundColor: `#76c5e0`,
          data: this.data.map(d => d.persons),
        }
      ],
      labels: this.data.map(d => d.time),
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            mode: 'index',
          }
        },
        hover: {
          mode: 'index'
        },
        scales: {
          x: {
            stacked: true,
            /*       scaleLabel: {
                       display: true,
                       labelString: ''
                   }, */
            ticks: {
              autoSkip: true
            }
          },
          y: {
            stacked: true,
            /*     scaleLabel: {
                     display: true,
                     labelString: ''
                 } */
          }
        }
      }
    }


    this.loading = false;
  }

  async ngOnInit() {
    this.fetchEvents();
  }

  async ngOnDestroy() {

  }
}
