import { Component, OnInit, ElementRef, Inject, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Globals } from '../../../../services/globals';
import { Currency } from '../../../../services/currency';
import { Api } from '../../../../services/api';

import { IBookingInfo, IBookingPackage, IBookingAddOn } from 'src/app/interfaces/booking';

import { ISelectedAddOns } from 'src/app/interfaces/booking-form-state';
import { IResource } from 'projects/client-app/src/app/interfaces/resource';
import { orderBy } from 'projects/client-app/src/app/helpers/utility';
import { addDurationToTime } from '../../../../../../../../../../../common/common-helpers/utility';


export interface IChangedPackageInfo {
  bookingPackage: IBookingPackage,
  selectedAddOns: ISelectedAddOns
}


@Component({
  selector: 'switch-resources-section',
  templateUrl: './switch-resources-section.component.html',
  styleUrls: ['./switch-resources-section.component.scss']
})
export class SwitchResourcesSectionComponent implements OnInit {
  @Input()
  booking: IBookingInfo;

  @Output()
  resourcesSwitched = new EventEmitter()



  loading = true;

  availableResources: IResource[];
  resourcesById: { [resourceId: string]: IResource } = {};

  resourceAllocations: any;
  resourceAllocationsById: { [resourceAllocationId: string]: any } = {};
  showConflictingResources: boolean;
  groupedData: {
    resources: {
      resourceId: string
      name: string,
      startTime: string,
      endTime: string,
      resourceAllocationIds: string[],
      newResourceId: string,
      conflictingResourceIds: { [resourceId: string]: boolean }
    }[];


    /*[packageId: string]: {
      name: string,
      activities: {
        [activityId: string]: {
          name:string,
          resources:[resourceId:string]
        }
      }
    } */
  };
  existingResourceAllocations: {
    [resourceId: string]: {
      [time: string]: boolean;
    }
  } = {};
  constructor(private api: Api, public globals: Globals, public currency: Currency, private translate: TranslateService, private elementRef: ElementRef, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  async switchResources() {
    this.loading = true;
    let switchData: any = {
      resourceAllocations: {}
    };

    for (let resource of this.groupedData.resources) {
      if (resource.newResourceId && resource.newResourceId != 'KEEP') {
        for (let resourceAllocationId of resource.resourceAllocationIds) {
          switchData.resourceAllocations[resourceAllocationId] = {
            newResourceId: resource.newResourceId
          }
        }
      }
    }

    let result = await this.api.publicClient().post<any>(`/bookings/${this.booking.id}/change/switch-resources`, switchData);

    if (result.succeeded) {
      this.booking.packages = result.booking.packages;
      this.resourcesSwitched.emit({ booking: result.booking });
    }

    await this.refreshReourceAllocations();

    this.loading = false;
  }



  async ngOnInit() {
    this.availableResources = await this.api.client().get<IResource[]>('/resources');
    for (let resource of this.availableResources) {
      this.resourcesById[resource.id] = resource;
    }
    this.availableResources = this.availableResources.filter(r => !r.disabled);

    await this.refreshReourceAllocations();

    this.loading = false;
  }

  async refreshReourceAllocations() {
    this.resourceAllocations = await this.api.client().get<any[]>(`/bookings/${this.booking.id}/resource-allocations`);
    for (let resourceAllocation of this.resourceAllocations) {
      this.resourceAllocationsById[resourceAllocation._id] = resourceAllocation;
    }

    let resourceAllocations = await this.api.client().get<any[]>(`/resource-allocations/by-day/${this.booking.localDay}`);

    for (let resourceAllocation of resourceAllocations) {
      if (!this.existingResourceAllocations[resourceAllocation.resourceId])
        this.existingResourceAllocations[resourceAllocation.resourceId] = {};
      if (!this.existingResourceAllocations[resourceAllocation.resourceId][resourceAllocation.startTime])
        this.existingResourceAllocations[resourceAllocation.resourceId][resourceAllocation.startTime] = true;
    }

    await this.populateResourceGroups();
  }

  hasSwitchedResources() {
    return this.groupedData.resources.some(r => r.newResourceId && r.newResourceId != 'KEEP');
  }

  async populateResourceGroups() {
    this.groupedData = { resources: [] };

    let resourceAllocationsByResource: {
      [resourceId: string]: {
        name: string,
        resourceAllocations: {
          id: string,
          startTime: string,
          endTime: string
        }[],
        conflictingResourceIds: { [resourceId: string]: boolean }
      }
    } = {};

    for (let _package of this.booking.packages) {
      for (let activity of _package.activities) {
        for (let resource of activity.resources) {
          if (!resourceAllocationsByResource[resource.resourceId])
            resourceAllocationsByResource[resource.resourceId] = {
              name: resource.name,
              resourceAllocations: [],
              conflictingResourceIds: {}
            };

          for (let existingResourceId in this.existingResourceAllocations) {
            if (this.existingResourceAllocations[existingResourceId][this.resourceAllocationsById[resource.resourceAllocationId].startTime]) {
              resourceAllocationsByResource[resource.resourceId].conflictingResourceIds[existingResourceId] = true;
            }
          }

          resourceAllocationsByResource[resource.resourceId].resourceAllocations.push({
            id: resource.resourceAllocationId,
            startTime: this.resourceAllocationsById[resource.resourceAllocationId].startTime,
            endTime: this.resourceAllocationsById[resource.resourceAllocationId].endTime,
          });
        }
      }
    }

    for (let resourceId in resourceAllocationsByResource) {
      let interval = this.resourcesById[resourceId].timeslotInterval;
      resourceAllocationsByResource[resourceId].resourceAllocations.sort(orderBy('startTime').asc);
      console.log(resourceAllocationsByResource[resourceId].resourceAllocations);
      let resourceAllocationsToGroup = [];
      let expectedStartTime;
      let currentStartTime;
      let currentEndTime;
      for (let resourceAllocation of resourceAllocationsByResource[resourceId].resourceAllocations) {
        if (!currentStartTime)
          currentStartTime = resourceAllocation.startTime;
        currentEndTime = resourceAllocation.endTime;


        if (expectedStartTime && expectedStartTime != resourceAllocation.startTime) {
          console.log(`Resource allocation ${resourceAllocation.startTime} didn't have expected start time ${expectedStartTime} and trigged grouping`)
          this.groupedData.resources.push({
            name: this.resourcesById[resourceId].name,
            resourceId: resourceId,
            startTime: currentStartTime,
            endTime: expectedStartTime,
            resourceAllocationIds: resourceAllocationsToGroup,
            newResourceId: 'KEEP',
            conflictingResourceIds: resourceAllocationsByResource[resourceId].conflictingResourceIds
          });
          resourceAllocationsToGroup = [resourceAllocation.id];
          currentStartTime = resourceAllocation.startTime;
        }
        else {
          console.log(`Resource allocation ${resourceAllocation.startTime} was queued for grouping`);
          resourceAllocationsToGroup.push(resourceAllocation.id);
        }
        expectedStartTime = addDurationToTime(resourceAllocation.startTime, interval);
      }

      this.groupedData.resources.push({
        name: this.resourcesById[resourceId].name,
        resourceId: resourceId,
        startTime: currentStartTime,
        endTime: currentEndTime,
        resourceAllocationIds: resourceAllocationsToGroup,
        newResourceId: 'KEEP',
        conflictingResourceIds: resourceAllocationsByResource[resourceId].conflictingResourceIds
      });

    }

  }
}
