// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prison-island-report {
  padding: 20px;
}
.prison-island-report .summary h1 {
  margin-bottom: 0px;
}
.prison-island-report table.styled > tr > td,
.prison-island-report table.styled > tr > th {
  padding: 2px 10px;
}
.prison-island-report .prisonisland-logo {
  max-height: 100px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/prison-island/prison-island-report.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/prison-island/prison-island-report.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;ACCF;ADEI;EACE,kBAAA;ACAN;ADIE;;EAEE,iBAAA;ACFJ;ADKE;EACE,iBAAA;ACHJ","sourcesContent":[".prison-island-report {\r\n  padding: 20px;\r\n\r\n  .summary {\r\n    h1 {\r\n      margin-bottom: 0px;\r\n    }\r\n  }\r\n\r\n  table.styled>tr>td,\r\n  table.styled>tr>th {\r\n    padding: 2px 10px;\r\n  }\r\n\r\n  .prisonisland-logo {\r\n    max-height: 100px;\r\n  }\r\n}\r\n",".prison-island-report {\n  padding: 20px;\n}\n.prison-island-report .summary h1 {\n  margin-bottom: 0px;\n}\n.prison-island-report table.styled > tr > td,\n.prison-island-report table.styled > tr > th {\n  padding: 2px 10px;\n}\n.prison-island-report .prisonisland-logo {\n  max-height: 100px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
