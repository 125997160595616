import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ICharity } from '../../../../../../../../../../common/common-interfaces/charity';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Utility } from '../../../services/utility';


@Component({
    selector: 'charities-settings',
    templateUrl: './charities-settings.component.html'
})

export class CharitiesSettingsComponent implements OnInit {
    @Input()
    clientSettings: any = null;

    availableCharities: ICharity[];
    selectedCharityIds: string[] = [];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currentUser: CurrentUser, private sanitizer: DomSanitizer) {

    }

    async ngOnInit() {
        this.availableCharities = await this.api.client().get<any>(`/manage/settings/charities/available`);

        if (!this.clientSettings.charitySettings) {
            this.clientSettings.charitySettings = {
                enabledCharities: []
            }
        }

        for (let charity of this.clientSettings.charitySettings.enabledCharities) {
            this.selectedCharityIds.push(charity.charityId);
        }

        this.updateSelectedCharities();
    }

    updateSelectedCharities() {
        console.log('Updating selected charities')
        for (let selectedCharityId of this.selectedCharityIds) {
            if (!this.clientSettings.charitySettings.enabledCharities.some(c => c.charityId == selectedCharityId)) {
                console.log(`Adding charity ${selectedCharityId}`);
                this.clientSettings.charitySettings.enabledCharities.push({
                    charityId: selectedCharityId,
                    enabledByDefault: false,
                    defaultValue: 0
                })
            }
        }

        for (let charity of this.clientSettings.charitySettings.enabledCharities) {
            if (!this.selectedCharityIds.includes(charity.charityId) || !this.availableCharities.some(c => c.id == charity.charityId)) {
                console.log(`Removing charity ${charity.charityId}`);
                this.clientSettings.charitySettings.enabledCharities.splice(this.clientSettings.charitySettings.enabledCharities.indexOf(charity), 1);
            }
        }
    }

    getCharity(charityId) {
        return this.availableCharities.find(c => c.id == charityId);
    }

    getImageUrl(charityId) {
        let charity = this.getCharity(charityId);
        return this.sanitizer.bypassSecurityTrustResourceUrl(charity.imageUrl);
    }
}
