import { Component, OnInit, EventEmitter, ViewChild, Inject, Input } from '@angular/core';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { Utility } from '../../../services/utility';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'booking-search-dialog-booking',
    templateUrl: './booking-search-dialog-booking.component.html',
    styleUrls: ['./booking-search-dialog-booking.component.scss']
})

export class BookingSearchDialogBookingComponent implements OnInit {
    @Input()
    booking: IBooking;
    constructor(private utility: Utility, private sanitizer: DomSanitizer) {

    }

    getIconHtml() {
        return this.sanitizer.bypassSecurityTrustHtml(this.utility.getBookingIconsHtml({ booking: this.booking }));
    }

    async ngAfterViewInit() {

    }

    async ngOnInit() {

    }
}
