// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-search-dialog {
  height: 500px;
  max-height: 80vh;
  max-width: 100%;
  width: 700px;
}
.booking-search-dialog .placeholder-icon {
  margin-top: 15%;
  font-size: 250px;
  opacity: 0.1;
}
.booking-search-dialog .booking {
  transition: 0.3s ease;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-search-dialog/booking-search-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-search-dialog/booking-search-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;ACCF;ADAE;EACE,eAAA;EACA,gBAAA;EACA,YAAA;ACEJ;ADAE;EACE,qBAAA;EACA,aAAA;ACEJ","sourcesContent":[".booking-search-dialog {\r\n  height: 500px;\r\n  max-height: 80vh;\r\n  max-width: 100%;\r\n  width: 700px;\r\n  .placeholder-icon {\r\n    margin-top: 15%;\r\n    font-size: 250px;\r\n    opacity: 0.1;\r\n  }\r\n  .booking {\r\n    transition: 0.3s ease;\r\n    padding: 10px;\r\n  }\r\n}\r\n",".booking-search-dialog {\n  height: 500px;\n  max-height: 80vh;\n  max-width: 100%;\n  width: 700px;\n}\n.booking-search-dialog .placeholder-icon {\n  margin-top: 15%;\n  font-size: 250px;\n  opacity: 0.1;\n}\n.booking-search-dialog .booking {\n  transition: 0.3s ease;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
