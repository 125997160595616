import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'rebook-resource-booking-info-dialog',
    templateUrl: './rebook-resource-booking-info-dialog.component.html',
    styleUrls: ['./rebook-resource-booking-info-dialog.component.scss']
})

export class RebookResourceBookingInfoDialogComponent implements OnInit {
    proceedCallback: any;
    cancelCallback: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<RebookResourceBookingInfoDialogComponent>, translate: TranslateService) {
        if (data) {
            this.proceedCallback = data.proceed;
            this.cancelCallback = data.cancel;
        }
    }


    proceed() {
        if (this.proceedCallback)
            this.proceedCallback();
        this.close();
    }

    cancel() {
        if (this.cancelCallback)
            this.cancelCallback();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }






    async ngAfterViewInit() {

    }



    async ngOnInit() {


    }
}
