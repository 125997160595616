import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';




@Component({
    selector: 'gift-card-templates',
    templateUrl: './gift-card-templates.component.html',
    styleUrls: ['./gift-card-templates.component.scss']
})

export class GiftCardTemplatesComponent implements OnInit {
    giftCardTemplates: any;
    displayedColumns = ['title', 'edit'];

    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.giftCardTemplates = await this.api.client().get<any[]>('/manage/gift-card-templates');
    }


}
