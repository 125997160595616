import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Globals } from './globals';
import { IPackage } from '../../../projects/client-app/src/app/interfaces/package';
import { IPrice, IPriceSummary } from '../interfaces/price';
import { cloneDeep, isNumber } from 'lodash';
import { v4 } from 'uuid';
import { generateNickname, generateTeamName } from '../../../../../../common/common-helpers/generate';
import { IEvent } from '../../../../../../common/common-interfaces/event';
import { Currency } from './currency';
import moment from 'moment';
import { addDurationToTime, subtractDurationFromTime } from '../../../../../../common/common-helpers/utility';



@Injectable()
export class Utility {
  constructor(private globals: Globals, private currency: Currency) {

  }

  iPhoneVersion() {
    var iHeight = window.screen.height;
    var iWidth = window.screen.width;

    if (iWidth === 414 && iHeight === 896) {
      return "Xmax-Xr";
    }
    else if (iWidth === 375 && iHeight === 812) {
      return "X-Xs";
    }
    else if (iWidth === 320 && iHeight === 480) {
      return "4";
    }
    else if (iWidth === 375 && iHeight === 667) {
      return "6";
    }
    else if (iWidth === 414 && iHeight === 736) {
      return "6+";
    }
    else if (iWidth === 320 && iHeight === 568) {
      return "5";
    }
    else if (iHeight <= 480) {
      return "2-3";
    }
    return 'none';
  }

  isIphone() {
    return !!navigator.userAgent.match(/iPhone/i);
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public uuid() {
    return v4();
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone) {
    let phoneTrim: string = phone ? phone.trim().replace('+', '').replace('-', '') : null;
    let phoneInt = parseInt(phoneTrim);
    return phone
      && !isNaN(phoneInt)
      && String(phoneInt).length == (phoneTrim.startsWith('0') ? phoneTrim.length - 1 : phoneTrim.length)
      && phone.length > 6;
  }

  padded(number: number) {
    if (number == 0)
      return '00';
    return (number < 10) ? `0${number}` : `${number}`;
  }

  addDurationToTime(time: string, duration: string) {
    return addDurationToTime(time, duration);
  }

  subtractDurationFromTime(time: string, duration: string) {
    return subtractDurationFromTime(time, duration);
  }

  getFirstTimeslot(timeslot1: string, timeslot2: string): string {
    let timeslot1Hour = parseInt(timeslot1.split(':')[0]);
    let timeslot1Minute = parseInt(timeslot1.split(':')[1]);
    let timeslot2Hour = parseInt(timeslot2.split(':')[0]);
    let timeslot2Minute = parseInt(timeslot2.split(':')[1]);

    if (timeslot1Hour == timeslot2Hour)
      return timeslot1Minute < timeslot2Minute ? timeslot1 : timeslot2;
    return timeslot1Hour < timeslot2Hour ? timeslot1 : timeslot2;
  }


  getLastTimeslot(timeslot1: string, timeslot2: string): string {
    let timeslot1Hour = parseInt(timeslot1.split(':')[0]);
    let timeslot1Minute = parseInt(timeslot1.split(':')[1]);
    let timeslot2Hour = parseInt(timeslot2.split(':')[0]);
    let timeslot2Minute = parseInt(timeslot2.split(':')[1]);

    if (timeslot1Hour == timeslot2Hour)
      return timeslot1Minute > timeslot2Minute ? timeslot1 : timeslot2;
    return timeslot1Hour > timeslot2Hour ? timeslot1 : timeslot2;
  }

  getTotalPackagePrice(_package: IPackage, persons = 1, customerGroupId?) {
    var packageQuantity = 0;
    switch (_package.priceType) {
      case 'PER_PERSON':
        packageQuantity = persons;
        break;
      case 'FIXED':
        packageQuantity = 1;
        break;
    }

    let price = (_package as any).price || _package.priceSettings[0].price;

    if (customerGroupId
      && _package.priceSettings
      && _package.priceSettings[0]
      && _package.priceSettings[0].customerGroupPrices[customerGroupId]
      && _package.priceSettings[0].customerGroupPrices[customerGroupId].enabled) {
      price = _package.priceSettings[0].customerGroupPrices[customerGroupId].price;
    }

    let extraOrderRows = _package.priceSettings ? _package.priceSettings[0].extraOrderRows : null;

    return (price.withVat * packageQuantity) +
      (extraOrderRows ? extraOrderRows.reduce((sum, row) => sum + (row.price.withVat * packageQuantity), 0) : 0);
  }


  getPackageMinimumPrice(_package: IPackage, exVat?: boolean) {
    let minimumPrice = 999999;
    let field = exVat ? 'withoutVat' : 'withVat';
    for (let priceSetting of _package.priceSettings) {
      let extraOrderRowsPrice = priceSetting.extraOrderRows.reduce((value, orderRow) => value + orderRow.price[field], 0);
      minimumPrice = Math.min(priceSetting.price[field] + extraOrderRowsPrice, minimumPrice);
      for (let customerGroupId in priceSetting.customerGroupPrices) {
        if (priceSetting.customerGroupPrices[customerGroupId].enabled)
          minimumPrice = Math.min(priceSetting.customerGroupPrices[customerGroupId].price[field] + extraOrderRowsPrice, minimumPrice);
      }
    }
    return minimumPrice;
  }

  getPackageMaximumPrice(_package: IPackage, exVat?: boolean) {
    let maximumPrice = 0;
    let field = exVat ? 'withoutVat' : 'withVat';
    for (let priceSetting of _package.priceSettings) {
      let extraOrderRowsPrice = priceSetting.extraOrderRows.reduce((value, orderRow) => value + orderRow.price[field], 0);
      maximumPrice = Math.max(priceSetting.price[field] + extraOrderRowsPrice, maximumPrice);
      for (let customerGroupId in priceSetting.customerGroupPrices) {
        if (priceSetting.customerGroupPrices[customerGroupId].enabled)
          maximumPrice = Math.max(priceSetting.customerGroupPrices[customerGroupId].price[field] + extraOrderRowsPrice, maximumPrice);
      }
    }
    return maximumPrice;
  }

  getPackagePriceInterval(_package: IPackage, exVat?: boolean) {
    let minPrice = this.getPackageMinimumPrice(_package, exVat);
    let maxPrice = this.getPackageMaximumPrice(_package, exVat);

    if (minPrice == maxPrice)
      return this.currency.format(minPrice);
    return `${this.currency.format(minPrice)} - ${this.currency.format(maxPrice)}`;
  }

  getTotalEventPrice(event: IEvent, persons = 1, customerGroupId?) {
    var quantity = persons;


    let price = event.priceSettings.price;

    if (customerGroupId
      && event.priceSettings
      && event.priceSettings.customerGroupPrices[customerGroupId]
      && event.priceSettings.customerGroupPrices[customerGroupId].enabled) {
      price = event.priceSettings.customerGroupPrices[customerGroupId].price;
    }

    let extraOrderRows = event.priceSettings ? event.priceSettings.extraOrderRows : null;

    return (price.withVat * quantity) +
      (extraOrderRows ? extraOrderRows.reduce((sum, row) => sum + (row.price.withVat * quantity), 0) : 0);
  }

  getEventMinimumPrice(event: IEvent, exVat?: boolean) {
    let minimumPrice = 999999;
    let field = exVat ? 'withoutVat' : 'withVat';
    let extraOrderRowsPrice = event.priceSettings.extraOrderRows.reduce((value, orderRow) => value + orderRow.price[field], 0);
    minimumPrice = Math.min(event.priceSettings.price[field] + extraOrderRowsPrice, minimumPrice);
    for (let customerGroupId in event.priceSettings.customerGroupPrices) {
      if (event.priceSettings.customerGroupPrices[customerGroupId].enabled)
        minimumPrice = Math.min(event.priceSettings.customerGroupPrices[customerGroupId].price[field] + extraOrderRowsPrice, minimumPrice);
    }
    return minimumPrice;
  }

  getEventMaximumPrice(event: IEvent, exVat?: boolean) {
    let maximumPrice = 0;
    let field = exVat ? 'withoutVat' : 'withVat';
    let extraOrderRowsPrice = event.priceSettings.extraOrderRows.reduce((value, orderRow) => value + orderRow.price[field], 0);
    maximumPrice = Math.max(event.priceSettings.price[field] + extraOrderRowsPrice, maximumPrice);
    for (let customerGroupId in event.priceSettings.customerGroupPrices) {
      if (event.priceSettings.customerGroupPrices[customerGroupId].enabled)
        maximumPrice = Math.max(event.priceSettings.customerGroupPrices[customerGroupId].price[field] + extraOrderRowsPrice, maximumPrice);
    }
    return maximumPrice;
  }

  getEventPriceInterval(event: IEvent, exVat?: boolean) {
    let minPrice = this.getEventMinimumPrice(event, exVat);
    let maxPrice = this.getEventMaximumPrice(event, exVat);

    if (minPrice == maxPrice)
      return this.currency.format(minPrice);
    return `${this.currency.format(minPrice)} - ${this.currency.format(maxPrice)}`;
  }


  getEventDateInterval(event: IEvent) {
    let startDate = event.dates[0].startDate;
    if (event.dates.length > 2) {
      let endDate = event.dates[event.dates.length - 1].startDate;
      return `${moment(startDate).format('D MMM')} - ${moment(endDate).format('D MMM')}`;
    }
    return moment(startDate).format('D MMM');
  }

  getEventStartDate(event: IEvent) {
    return moment(event.startDate).format('ddd D MMM');
  }


  multiplyPrice(price: IPrice, multiplier: number): IPrice {
    let withVat = price.withVat * multiplier;
    let vat = price.vat * multiplier;
    let withoutVat = price.withoutVat * multiplier;
    return {
      withVat: withVat,
      vat: vat,
      withoutVat: withoutVat,
      vatPercentage: price.vatPercentage
    }
  }


  addPriceToSummary(priceSummary: IPriceSummary, price: IPrice): IPriceSummary {
    priceSummary.withVat += price.withVat;
    priceSummary.withoutVat += price.withoutVat;
    priceSummary.vat += price.vat;

    if (!priceSummary.vatCharges[price.vatPercentage])
      priceSummary.vatCharges[price.vatPercentage] = 0;
    priceSummary.vatCharges[price.vatPercentage] += price.vat;
    return priceSummary;
  }

  subtractPriceFromSummary(priceSummary: IPriceSummary, price: IPrice): IPriceSummary {
    priceSummary.withVat -= price.withVat;
    priceSummary.withoutVat -= price.withoutVat;
    priceSummary.vat -= price.vat;

    if (!priceSummary.vatCharges[price.vatPercentage])
      priceSummary.vatCharges[price.vatPercentage] = 0;
    priceSummary.vatCharges[price.vatPercentage] -= price.vat;
    return priceSummary;
  }


  summarizePriceSummaries(priceSummary1: IPriceSummary, priceSummary2: IPriceSummary): IPriceSummary {
    let result = cloneDeep(priceSummary1);
    if (priceSummary2) {
      result.withVat += priceSummary2.withVat;
      result.withoutVat += priceSummary2.withoutVat;
      result.vat += priceSummary2.vat;

      for (let vatPercentage in priceSummary2.vatCharges) {
        if (result.vatCharges[vatPercentage])
          result.vatCharges[vatPercentage] += priceSummary2.vatCharges[vatPercentage];
        else
          result.vatCharges[vatPercentage] = priceSummary2.vatCharges[vatPercentage];
      }
    }

    return result;
  }


  generateNickname(maxLength?: number) {
    return generateNickname(maxLength)
  }

  generateTeamName(maxLength?: number) {
    return generateTeamName(maxLength)
  }

  async getEmbedIframeTopOffset(): Promise<number> {
    if (this.globals.embedMode) {
      return new Promise(resolve => {
        let listener = e => {
          if (e.data) {
            if (e.data.name == 'iframeTopOffset') {
              console.log(`Received iframeTopOffset: ${e.data.value}`);
              window.removeEventListener("message", listener);
              resolve(e.data.value);
            }
          }
        }
        window.addEventListener("message", listener);
        window.parent.postMessage({
          name: "getIframeTopOffset"
        }, '*');
      });
    } else {
      return 0;
    }
  }

  async scrollIntoView(element: Element, options?: {}) {
    if (element) {

      // Use Safari scroll
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && this.globals.embedMode) {
        let iframeOffset = await this.getEmbedIframeTopOffset();
        let elementOffset = (element as HTMLElement).offsetTop;

        window.parent.postMessage({
          name: "scrollTo",
          value: iframeOffset + elementOffset
        }, '*');
        console.log(`Using Safari scroll`);
        console.log(`Iframe offset: ${iframeOffset}`);
        console.log(`Element offset: ${elementOffset}`);
      } else {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    else {
      console.log(`No element to scroll to`);
    }

  }

}

