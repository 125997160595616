import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { Currency } from '../../../services/currency';
import { CurrentUser } from '../../../services/user';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { JournalEntry } from '../../../../../../../../../../common/common-types/reports/journal';
import { MatDialog } from '@angular/material/dialog';
import { JournalEntryDialogComponent } from './journal-entry-dialog/journal-entry-dialog.component';
import { IJournalTransactionData } from '../../../../../../../../../../common/common-interfaces/reports/journal';
import { utils } from 'protractor';


@Component({
    selector: 'journal-report',
    templateUrl: './journal-report.component.html',
    styleUrls: ['./journal-report.component.scss']
})
export class JournalReportComponent implements OnInit, OnDestroy, OnChanges {
    startDate: any = moment().subtract(1, "days").startOf('day').format('YYYY-MM-DD');
    endDate: any = moment().endOf('day').format('YYYY-MM-DD');

    user: any;

    selectedType: 'booking' | 'gift-card-order' = 'booking';

    journalReportData: any[];
    unFilteredJournalReportData: any[];

    bookingNumberSearch: string = '';
    externalIdSearch: string = '';
    sequenceIdSearch: string = '';

    isPaid: boolean;
    isCanceled: boolean = false;

    @ViewChild('JournalReportTable', { static: false })
    JournalTable: any;

    constructor(private api: Api, public currentUser: CurrentUser, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, public currency: Currency, private dateAdapter: DateAdapter<any>, private translate: TranslateService, private dialog: MatDialog) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
        });

    }
    ngOnChanges(changes: SimpleChanges){}

    getDate(date: string) {
        return date != '' ? moment(date).format('YYYY-MM-DD') : '';
    }

    async updateJournalReport() {
        delete this.journalReportData;
        await this.fetchData();
    }

    async openEntry(entry: any) {
        this.dialog.open(JournalEntryDialogComponent, {
            data: entry
        });
    }

    async csvDownload() {

        let download = (content, fileName, mimeType) => {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if ((navigator as any).msSaveBlob) { // IE10
              (navigator as any).msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        }

        let getCsvString = (content: string) => {
            if (content && typeof (content) == 'string') {
                var result = content.replace(/"/g, '""');
                return `"${result}"`;
            }
            else {
                return '';
            }
        }

        let csvContent = "";
        csvContent = `#,CREATED_AT,CUSTOMER_NAME,GIFT_CARD_SUM,PAYMENT_DATE,PAYMENT_STATUS,PAYMENT_METHOD,TRANSACTION_DATE,TRANSACTION_STATUS,EXTERNAL_ID,Seq.Id,HANDLED_BY,ABORTED_BY,IS_VERIFIED,PRICE_WITH_VAT,PRICE_WITHOUT_VAT\n`


        if(this.selectedType == 'booking'){
            for(let entry of this.journalReportData) {
                csvContent += `${[
                    getCsvString(String(entry.bookingNumber)),
                    getCsvString(String(moment(entry.created).format("YYYY-MM-DD"))),
                    getCsvString(String(entry.customer.firstName + " " + entry.customer.lastName)),
                    getCsvString(String(entry.giftCardSum ?? '')),
                    getCsvString(String(entry.paymentDate)),
                    getCsvString(String(entry.paymentStatus)),
                    getCsvString(String(entry.paymentMethod)),
                    getCsvString(String(entry.transactionInfo.transactionDate ?? '')),
                    getCsvString(String(entry.transactionInfo.status ?? '')),
                    getCsvString(String(entry.transactionInfo.externalId ?? '')),
                    getCsvString(String(entry.transactionInfo.sequenceNumber ?? '')),
                    getCsvString(String(entry.transactionInfo.finishedBy ?? '')),
                    getCsvString(String(entry.transactionInfo.abortedBy ?? '')),
                    getCsvString(String(entry.isVerified)),
                    getCsvString(String(entry.price.withVat)),
                    getCsvString(String(entry.price.withoutVat)),
                ].join(',')}\r\n`;
            }
        }

        download(csvContent, `journal_data_${moment(this.startDate).format("YYYY-MM-DD")}_${moment(this.endDate).format("YYYY-MM-DD")}.csv`, "data:text/csv;charset=utf-8,")
    }

    async ngOnInit() {
        this.user = await this.currentUser.get();
        await this.fetchData();
    }

    async fetchData(){
        let query = `startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format('YYYY-MM-DD')}`;

        console.log(this.isCanceled);

        if(this.selectedType != 'gift-card-order'){
            if (typeof (this.isPaid) != 'undefined')
                query += `&isPaid=${this.isPaid}`;
            if (typeof (this.isCanceled) != 'undefined')
                query += `&isCanceled=${this.isCanceled}`;

            this.journalReportData = await this.api.client().get<JournalEntry[]>(`/reports/journal-report/data?${query}`);
        }
        else {
            this.journalReportData = await this.api.client().get<JournalEntry[]>(`/reports/journal-report/data/gift-card-order?${query}`);
        }

        this.unFilteredJournalReportData = this.journalReportData;

        this.filterRows();
    }

    filterRows() {
        this.journalReportData = this.unFilteredJournalReportData;

        if(this.bookingNumberSearch != '' && this.selectedType != 'gift-card-order') {
              this.journalReportData = this.journalReportData.filter((entry) => { return entry.bookingNumber.match(this.bookingNumberSearch) });
        }

        if(this.externalIdSearch != ''){
            this.journalReportData = this.journalReportData.filter((entry) => { return entry.transactionInfo.externalId && entry.transactionInfo.externalId.match(this.externalIdSearch) });
        }

        if(this.sequenceIdSearch) {
            this.journalReportData = this.journalReportData.filter((entry) => { return entry.transactionInfo.sequenceNumber && String(entry.transactionInfo.sequenceNumber).match(this.sequenceIdSearch) });
        }

        if(this.JournalTable){
            this.JournalTable.reload();
        }
    }

    getGiftCardUrl(giftCardId: string) {
        return `${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${giftCardId}/view`;
    }

    changeSelectedType(type: 'booking' | 'gift-card-order') {
        this.selectedType = type;
        this.updateJournalReport();
    }

    cutExternalIdToFit(externalId: string) {
        return externalId.length > 21 ? externalId.substring(0, 21) + '...' : externalId;
    }

    openBookingRef(id: string) {
        window.open(`${this.globals.baseUrl}/client/${this.globals.clientId}/bookings/${id}/ticket`, '_blank');
    }

    async ngOnDestroy() {

    }

    getVerifiedIcon(verified: boolean) {
        return verified ? 'check' : 'warning';
    }

    getFilterOptionClass(value, reverse?) {
        return {
            none: typeof (value) == 'undefined',
            'green-text': reverse ? value === false : value,
            'red-text': reverse ? value === true : value === false
        }
    }

    toggleIsCanceled() {
        if (typeof (this.isCanceled) == 'undefined')
            this.isCanceled = true;
        else if (this.isCanceled)
            this.isCanceled = false;
        else
            delete this.isCanceled
    }

    toggleIsPaid() {
        if (typeof (this.isPaid) == 'undefined')
            this.isPaid = true;
        else if (this.isPaid)
            this.isPaid = false;
        else
            delete this.isPaid;
    }
}
