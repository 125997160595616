import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from '../../../helpers/utility';


@Component({
    selector: 'custom-text-chooser',
    templateUrl: './custom-text-chooser.component.html',
    styleUrls: ['./custom-text-chooser.component.scss']
})

export class CustomTextChooserComponent implements OnInit {
    @Input()
    customTextId: any;

    @Input()
    type: string;

    @Input()
    label: string;

    @Output()
    customTextIdChange = new EventEmitter();
    availableCustomTexts: any[] = [];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }

    async customTextSelected(customTextId) {
        this.customTextIdChange.emit(this.customTextId);
    }

    async ngOnInit() {
        this.availableCustomTexts = await this.api.client().get<any[]>(`/manage/custom-texts/untagged?type=${this.type ? this.type : ''}`);
        this.availableCustomTexts.sort(orderBy('name').asc);
    }

}
