import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IClientNotification } from '../../../interfaces/client-notification';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { NotificationContentDialogComponent } from '../notification-content-dialog/notification-content-dialog.component';

@Component({
    selector: 'notification-group',
    templateUrl: './notification-group.component.html',
    styleUrls: ['./notification-group.component.scss']
})

export class NotificationGroupComponent implements OnInit, OnDestroy, OnChanges {
    @Output()
    notificationsChanged = new EventEmitter();
    @Output()
    groupExpanded = new EventEmitter();
    @Output()
    groupMinimized = new EventEmitter();

    @Input()
    tag: string;
    @Input()
    expanded: boolean;
    @Input()
    notifications: IClientNotification[];
    notificationsByDate: { label: string, date: string, notifications: IClientNotification[] }[];
    unchecked: number;

    constructor(private api: Api, private router: Router, public globals: Globals, private route: ActivatedRoute, private currentUser: CurrentUser, private translate: TranslateService, private dialog: MatDialog) {

    }


    calculateUnchecked() {
        this.unchecked = this.notifications.reduce((sum, notification) => notification.checked ? sum : sum + 1, 0);
    }

    notificationsHasChanged() {
        this.notificationsChanged.emit();
        this.calculateUnchecked();
    }

    toggleExpansion() {
        this.expanded = !this.expanded;
        if (this.expanded)
            this.groupExpanded.emit();
        else
            this.groupMinimized.emit();
    }

    async checkedChanged(notification: IClientNotification) {
        let updatedNotification = await this.api.client().post<any>(`/notifications/${notification.id}/checked`, { checked: notification.checked });
        notification.checkedDate = updatedNotification.checkedDate;
        notification.checkedBy = updatedNotification.checkedBy;
        this.notificationsHasChanged();
    }

    groupNotifications() {
        let notificationsByDate = {};
        this.notificationsByDate = [];

        let addDate = (date, label) => {
            let notifications = [];
            notificationsByDate[date] = notifications;
            this.notificationsByDate.push({
                label: label,
                date: date,
                notifications: notifications
            });
        }

        let labels = {};
        let now = moment();

        addDate(now.format('YYYY-MM-DD'), this.translate.instant('TODAY'));
        addDate(moment(now).subtract(1, 'day').format('YYYY-MM-DD'), this.translate.instant('YESTERDAY'));

        for (var i = 2; i < 8; i++) {
            let date = moment(now).subtract(i, 'days');
            addDate(date.format('YYYY-MM-DD'), date.format('dddd'));
        }

        addDate('OLD', this.translate.instant('OLDER_THAN_7_DAYS'));

        for (let notification of this.notifications) {
            let date = moment(notification.date).format('YYYY-MM-DD');

            if (moment(notification.date).add(7, 'days').isBefore(moment()))
                date = 'OLD';

            if (notificationsByDate[date])
                notificationsByDate[date].push(notification);
            else
                notificationsByDate['OLD'].push(notification);
        }
    }

    openNotificationContent(notification: IClientNotification) {
        this.dialog.open(NotificationContentDialogComponent, {
            data: { notification: notification }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.groupNotifications();
        this.calculateUnchecked();
    }

    async ngOnInit() {

    }

    ngOnDestroy() {

    }

    async manycheckedChanged(notifications: IClientNotification[], tag) {
        let updatedNotification = await this.api.client().post<any>(`/notifications/tag/${tag}/checked`, { checked : true });

        notifications.forEach(notification => {
            notification.checked = true;
        });

        this.calculateUnchecked();
    }
}
