import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Api } from 'projects/client-app/src/app/services/api';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { IBooking } from 'src/app/interfaces/booking';
import { IBookingData } from '../booking-list-report.component';

@Component({
    selector: 'booking-table',
    templateUrl: './booking-table.component.html',
    styleUrls: ['./booking-table.component.scss']
})

export class BookingTableComponent implements OnInit, OnDestroy {
    @Input()
    bookingData: IBookingData[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }


    async dateChanged() {

    }

    getPackageData(booking) {
        let packageData = [];
        for (let bookingPackage of booking.packages) {
            let _package = {
                name: bookingPackage.name,
                persons: bookingPackage.persons,
                customerGroups: bookingPackage.customerGroups,
                addOns: bookingPackage.addOns,
                activities: []
            }
            let addedActivityIds = {};
            for (let bookingActivity of bookingPackage.activities) {
                if (!addedActivityIds[bookingActivity.uniqueActivityId]) {
                    let activity = {
                        name: bookingActivity.name,
                        resources: []
                    }
                    addedActivityIds[bookingActivity.uniqueActivityId] = true;

                    let addedResourceIds = {};
                    for (let resource of bookingActivity.resources) {
                        if (!addedResourceIds[resource.resourceId]) {
                            activity.resources.push({
                                name: resource.name
                            });
                            addedResourceIds[resource.resourceId] = true;
                        }
                    }
                    _package.activities.push(activity);
                }

            }
            packageData.push(_package);
        }

        return packageData;
    }

    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
