import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { Globals } from '../../services/globals';
import { CurrentUser } from '../../services/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    api: Api;
    router: Router;
    username: string = '';
    password: string = '';

    showPassword: boolean = false;

    constructor(api: Api, router: Router, private currentUser: CurrentUser, private globals: Globals, private snackBar: MatSnackBar, private translate: TranslateService) {
        this.api = api;
        this.router = router;
    }


    async ngOnInit() {
        let accessToken = localStorage.getItem('accessToken');
        let type = localStorage.getItem('userType');
        if (accessToken && type == 'GLOBAL') {
            window.location.replace('/admin');
            return;
        }

        if (await this.currentUser.get()) {
            this.router.navigateByUrl('/' + this.globals.clientId);
        }
    }

    logIn = async () => {
        let result = await this.api.base().post<any>('/authenticate', {
            username: this.username,
            password: this.password
        });
        if (result.succeeded) {
            localStorage.setItem('accessToken', result.accessToken);
            localStorage.setItem('userRole', result.role);
            localStorage.setItem('userType', result.type);
            if (result.type == "LOCAL") {
                this.globals.clientId = result.clientId;
                localStorage.setItem('userClientId', this.globals.clientId);
                this.router.navigateByUrl(`/${this.globals.clientId}/calendar`);
            }
            else {
                window.location.replace('/admin');
            }

        }else{
            this.snackBar.open(this.translate.instant('LOGIN_FAILED'), null, {
                duration: 1000,
                horizontalPosition: 'center',
                panelClass: 'align-center'
            });
        }
        console.log(result);
    }
}
