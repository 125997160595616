import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'feedback-smiley',
    templateUrl: './feedback-smiley.component.html',
    styleUrls: ['./feedback-smiley.component.scss']
})

export class FeedbackSmileyComponent implements OnInit, OnDestroy {
    @Input()
    type: string;
    constructor() {

    }


    async ngOnInit() {

    }


    async ngOnDestroy() {

    }
}
