import { Injectable } from '@angular/core';
import { Api } from './api';
import { Router } from '@angular/router';
import { IUser } from '../interfaces/user';
import { Globals } from './globals';



let currentUser: IUser | null = null;

@Injectable()
export class CurrentUser {

    get user() {
        return currentUser;
    }
    constructor(private api: Api, private router: Router, private globals: Globals) {

    }

    public async get(): Promise<IUser | null> {
        if (currentUser)
            return currentUser;
        else {
            try {
                if (!this.globals.clientId) {
                    this.globals.clientId = localStorage.getItem('userClientId');
                }

                currentUser = await this.api.client().get<IUser>('/users/current');
            }
            catch (error) {
                // If unauthorized, redirect to login
                if (error.status == 401) {
                    localStorage.removeItem('clientId');
                    localStorage.removeItem('accessToken');
                    this.router.navigateByUrl('/login');
                }
                else {
                    console.log(error);
                }
            }
            return currentUser;
        }
    }


}