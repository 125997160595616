import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { IStaffDevice } from '../../../../../../../../../../common/common-interfaces/staff-device';
import { orderBy } from '../../../helpers/utility';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { StaffDeviceQRDialogComponent } from './staff-device-qr-dialog/staff-device-qr-dialog.component';


@Component({
    selector: 'staff-devices-settings',
    templateUrl: './staff-devices.component.html'
})

export class StaffDevicesSettingsComponent implements OnInit {
    staffDevices: IStaffDevice[] | null = null;
    displayedColumns = ['delete', 'name', 'quick-check-in', 'unpaid-check-in', 'valid-to', 'qr-code'];

    newStaffDevice: IStaffDevice;

    @ViewChild('staffDevicesTable', { static: false }) staffDevicesTable: MatTable<any>;
    constructor(private api: Api, private router: Router, public globals: Globals, private dialog: MatDialog) {
        this.resetNewStaffDevice();
    }

    resetNewStaffDevice() {
        this.newStaffDevice = {
            name: '',
            quickCheckInEnabled: false,
            allowUnpaidCheckIn: false,
            validTo: moment().add(1, 'year').toDate()
        }
    }


    async saveStaffDevice(staffDevice: IStaffDevice) {
        let result = await this.api.client().patch<any>(`/manage/settings/staff-devices/${staffDevice.id}`, staffDevice);

        if (result.succeeded) {

        }

    }

    async removeStaffDevice(staffDevice: IStaffDevice) {
        let result = await this.api.client().delete<any>(`/manage/settings/staff-devices/${staffDevice.id}`);

        if (result.succeeded) {
            this.staffDevices.splice(this.staffDevices.indexOf(result.staffDevice), 1);
            this.staffDevices.sort(orderBy('name').asc);
            this.staffDevicesTable.renderRows();
        }

    }

    async createStaffDevice() {
        let result = await this.api.client().put<any>('/manage/settings/staff-devices', this.newStaffDevice);

        if (result.succeeded) {
            this.staffDevices.push(result.staffDevice);
            this.staffDevices.sort(orderBy('name').asc);
            this.staffDevicesTable.renderRows();
            this.resetNewStaffDevice();
            this.viewQRCode(result.staffDevice);
        }
    }

    async viewQRCode(staffDevice: IStaffDevice) {
        this.dialog.open(StaffDeviceQRDialogComponent, {
            data: { staffDevice: staffDevice }
        });
    }


    async ngOnInit() {
        this.staffDevices = await this.api.client().get<IStaffDevice[]>('/manage/settings/staff-devices');
        this.staffDevices.sort(orderBy('name').asc);

    }

    ngAfterContentInit() {
        if (this.staffDevicesTable)
            this.staffDevicesTable.renderRows();
    }
}
