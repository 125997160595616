import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { IAddOn } from '../../interfaces/add-on';
import { IAddOnGroup } from '../../interfaces/add-on-group';



@Component({
    selector: 'add-ons',
    templateUrl: './add-ons.component.html',
    styleUrls: ['./add-ons.component.scss']
})

export class AddOnsComponent implements OnInit {
    addOns: IAddOn[] | null = null;
    addOnGroups: IAddOnGroup[] | null = null;
    displayedColumns = ['name', 'edit'];

    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }

    async saveAddOnGroup(addOnGroup) {
        await this.api.client().put<IAddOnGroup>('/manage/add-on-groups', addOnGroup);
    }

    async switchAddOnGroupOrder(addOnGroup, addOnGroup2) {
        let sortOrder = addOnGroup2.sortOrder;
        addOnGroup2.sortOrder = addOnGroup.sortOrder;
        addOnGroup.sortOrder = sortOrder;
        this.addOnGroups.sort(orderBy('sortOrder').asc);
        this.saveAddOnGroup(addOnGroup);
        this.saveAddOnGroup(addOnGroup2);
    }

    moveAddOnGroupUp(addOnGroup) {
        let index = this.addOnGroups.indexOf(addOnGroup);
        if (index > 0) {
            this.switchAddOnGroupOrder(addOnGroup, this.addOnGroups[index - 1])
        }
    }

    moveAddOnGroupDown(addOnGroup) {
        let index = this.addOnGroups.indexOf(addOnGroup);
        if (index < this.addOnGroups.length - 1) {
            this.switchAddOnGroupOrder(addOnGroup, this.addOnGroups[index + 1])
        }
    }

    async toggleGroupThumbnail(addOnGroup, addOnId) {
        if (addOnGroup.thumbnailAddOnId != addOnId)
            addOnGroup.thumbnailAddOnId = addOnId;
        else
            addOnGroup.thumbnailAddOnId = null;
        this.saveAddOnGroup(addOnGroup);
    }

    async moveAddOnUp(addOnGroup, addOn) {
        let index = addOnGroup.addOns.indexOf(addOn);
        if (index > 0) {
            addOnGroup.addOns.splice(index - 1, 0, addOnGroup.addOns.splice(index, 1)[0]);
        }
        this.saveAddOnGroup(addOnGroup);
    }

    async moveAddOnDown(addOnGroup, addOn) {
        let index = addOnGroup.addOns.indexOf(addOn);
        if (index < addOnGroup.addOns.length - 1) {
            addOnGroup.addOns.splice(index + 1, 0, addOnGroup.addOns.splice(index, 1)[0]);
        }
        this.saveAddOnGroup(addOnGroup);
    }

    async removeAddOn(addOnGroup, addOn) {
        addOnGroup.addOns.splice(addOnGroup.addOns.indexOf(addOn), 1);
        this.saveAddOnGroup(addOnGroup);
    }

    async addAddOnGroup() {
        let addOnGroup = (await this.api.client().put<any>('/manage/add-on-groups', {
            sortOrder: this.addOnGroups.reduce((value, addOnGroup) => Math.max(value, addOnGroup.sortOrder), 1) +1,
            addOns: []
        })).addOnGroup;

        if(!addOnGroup.conditions) {
            addOnGroup.conditions = {
                enabled: false
            };
        }
        
        this.addOnGroups.push(addOnGroup);
    }

    async removeAddOnGroup(addOnGroup) {
        await this.api.client().delete<any>(`/manage/add-on-groups/${addOnGroup.id}`);
        this.addOnGroups.splice(this.addOnGroups.indexOf(addOnGroup), 1);
    }

    async addAddOnToGroup(addOnGroup, e) {
        let addOn = e.value;
        if (!addOnGroup.addOns.some(a => a.addOnId == addOn.id)) {
            addOnGroup.addOns.push({
                addOnId: addOn.id,
                name: addOn.name
            });
        }
        this.saveAddOnGroup(addOnGroup);
    }

    async ngOnInit() {
        this.addOns = await this.api.client().get<IAddOn[]>('/manage/add-ons');
        this.addOnGroups = await this.api.client().get<IAddOnGroup[]>('/manage/add-on-groups');
            
        this.addOns.sort(orderBy('name').asc);
        this.addOnGroups.sort(orderBy('sortOrder').asc);

        //add condition and condition.enabled to addOnGroups that don't have them (backwards compatibility)
        this.addOnGroups.forEach(addOnGroup => {
            if(!addOnGroup.conditions) {
                addOnGroup.conditions = {
                    enabled: false
                };
            }
        });
    }

}
