// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://booking.funbutler.dev',
  webSafeFonts: [
    'Arial',
    'Verdana',
    'Trebuchet MS',
    'Times New Roman',
    'Didot',
    'American Typewriter',
    'Andale Mono',
    'Courier',
    'Bradley Hand',
    'Luminari'
  ],
  stripePublicKey:'pk_test_51LY6cTF2Iydm81zG10zZfVi57Kry1Z0RWYTXbVMm7qdW3zH4NOfnMFSENKMJ6A39vA7zmmOW5dx7yhHWvlbK8Bui006uG47UHb',
  adyenClientKey: 'test_S7DHDEM3BBEUNEAFDK4L4RTURM3ISUWS'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
