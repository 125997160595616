import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Api } from 'projects/client-app/src/app/services/api';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { IBooking } from 'src/app/interfaces/booking';
import { IBookingData } from '../booking-list-report.component';

@Component({
    selector: 'booking-customer-table',
    templateUrl: './booking-customer-table.component.html',
    styleUrls: ['./booking-customer-table.component.scss']
})

export class BookingCustomerTableComponent implements OnInit, OnDestroy {
    @Input()
    bookingData: IBookingData[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }


    async dateChanged() {

    }

    async downloadCSV() {
        let download = (content, fileName, mimeType) => {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if ((navigator as any).msSaveBlob) { // IE10
              (navigator as any).msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        }


        let getCsvString = (content: string) => {
            if (content && typeof (content) == 'string') {
                var result = content.replace(/"/g, '""');
                return `"${result}"`;
            }
            else {
                return '';
            }
        }


        let csvContent = "";
        csvContent += `${['#',
            'BOOKING_DATE',
            'VISIT_DATE',
            'FIRST_NAME',
            'LAST_NAME',
            'COMPANY_NAME',
            'EMAIL',
            'PHONE',
            'NEWSLETTER_ACCEPTED'].join(',')}\r\n`;

        for (let data of this.bookingData) {
            csvContent += `${[
                getCsvString(String(data.bookingBase.bookingNumber)),
                getCsvString(moment(data.bookingBase.created).format('YYYY-MM-DD HH:mm')),
                getCsvString(moment(data.bookingBase.startDate).format('YYYY-MM-DD HH:mm')),
                getCsvString(data.bookingBase.customer.firstName),
                getCsvString(data.bookingBase.customer.lastName),
                getCsvString(data.bookingBase.customer.companyName),
                getCsvString(data.bookingBase.customer.email),
                getCsvString(data.bookingBase.customer.phone),
                getCsvString(data.bookingBase.newsletterAccepted ? 'YES' : 'NO'),
            ].join(',')}\r\n`;
        }

        download(csvContent, 'data.csv', "data:text/csv;charset=utf-8,");
        //  var encodedUri = encodeURI(csvContent);
        //  window.open(encodedUri);
    }

    getPackageData(booking) {
        let packageData = [];
        for (let bookingPackage of booking.packages) {
            let _package = {
                name: bookingPackage.name,
                persons: bookingPackage.persons,
                customerGroups: bookingPackage.customerGroups,
                addOns: bookingPackage.addOns,
                activities: []
            }
            let addedActivityIds = {};
            for (let bookingActivity of bookingPackage.activities) {
                if (!addedActivityIds[bookingActivity.uniqueActivityId]) {
                    let activity = {
                        name: bookingActivity.name,
                        resources: []
                    }
                    addedActivityIds[bookingActivity.uniqueActivityId] = true;

                    let addedResourceIds = {};
                    for (let resource of bookingActivity.resources) {
                        if (!addedResourceIds[resource.resourceId]) {
                            activity.resources.push({
                                name: resource.name
                            });
                            addedResourceIds[resource.resourceId] = true;
                        }
                    }
                    _package.activities.push(activity);
                }

            }
            packageData.push(_package);
        }

        return packageData;
    }

    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
