// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-icon {
  font-size: 14em;
}

a:focus-within, a:focus, a:focus-visible {
  border: 0px;
  outline: 0px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/hcert-scan-dialog/hcert-scan-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/hcert-scan-dialog/hcert-scan-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;ACCJ;;ADGI;EAGI,WAAA;EACA,YAAA;ACFR","sourcesContent":[".mat-icon {\r\n    font-size: 14em;\r\n}\r\n\r\na {\r\n    &:focus-within,\r\n    &:focus,\r\n    &:focus-visible {\r\n        border: 0px;\r\n        outline: 0px;\r\n    }\r\n}",".mat-icon {\n  font-size: 14em;\n}\n\na:focus-within, a:focus, a:focus-visible {\n  border: 0px;\n  outline: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
