// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.face-detector {
  display: none;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/common/face-detection/face-detector.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/common/face-detection/face-detector.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;ACCF","sourcesContent":[".face-detector {\r\n  display: none;\r\n}\r\n",".face-detector {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
