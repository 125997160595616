// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
.support-btn-custom-txt {
    position: absolute;
    right: 0;
    top: -7px;
    z-index: 100;
}
*/`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/settings/custom-texts/custom-texts-settings.component.scss"],"names":[],"mappings":"AAAA;;;;;;;CAAA","sourcesContent":["/*\r\n.support-btn-custom-txt {\r\n    position: absolute;\r\n    right: 0;\r\n    top: -7px;\r\n    z-index: 100;\r\n}\r\n*/\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
