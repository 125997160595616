import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { IActivity } from '../../interfaces/activity';



@Component({
    selector: 'activities',
    templateUrl: './activities.component.html'
})

export class ActivitiesComponent implements OnInit {
    activities: IActivity[] | null = null;
    displayedColumns = ['name', 'edit'];
    
    showDisabled: boolean = false;

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }


    async ngOnInit() {
        this.activities = await this.api.client().get<IActivity[]>('/manage/activities');
        this.activities.sort(orderBy('name').asc);
    }
}
