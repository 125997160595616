import { Component, OnInit, EventEmitter, ViewChild, Inject, Input } from '@angular/core';
import { Globals } from '../../../services/globals';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Utility } from '../../../services/utility';

@Component({
  selector: 'booking-icons',
  templateUrl: './booking-icons.component.html',
  styleUrls: ['./booking-icons.component.scss']
})

export class BookingIconsComponent implements OnInit {
  @Input()
  data: any;
  constructor(private globals: Globals, private sanitizer: DomSanitizer, private utility: Utility) {

  }

  getBookingIconsHtml(data) {
    return this.sanitizer.bypassSecurityTrustHtml(this.utility.getBookingIconsHtml(data));
  }

  async ngAfterViewInit() {

  }

  async ngOnInit() {

  }
}
