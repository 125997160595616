import { Component, OnInit, EventEmitter, ViewChild, Inject, Input, Output } from '@angular/core';
import { Api } from '../../../../services/api';
import { Currency } from '../../../../services/currency';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../services/globals';
import { IBooking } from '../../../../../../../../src/app/interfaces/booking';


@Component({
    selector: 'person-counter',
    templateUrl: './person-counter.component.html',
    styleUrls: ['./person-counter.component.scss']
})

export class PersonCounterComponent implements OnInit {
    @Input()
    booking: IBooking;
    @Output()
    hasChanged = new EventEmitter();

    constructor(private api: Api, public currency: Currency, private translate: TranslateService, public globals: Globals) {

    }


    decrease() {
        if (this.booking.metadata.arrivedPersons > 0) {
            this.booking.metadata.arrivedPersons--;
            this.update();
        }
    }

    increase() {
        if (this.booking.metadata.arrivedPersons < this.booking.persons) {
            this.booking.metadata.arrivedPersons++;
            this.update();
        }
    }

    getProgressText() {
        return this.translate.instant('PERSON_COUNTER_TEXT').replace('#ARRIVED', this.booking.metadata.arrivedPersons).replace('#TOTAL', this.booking.persons);
    }

    getProgressWidth() {
        return `${(this.booking.metadata.arrivedPersons / this.booking.persons) * 100}%`;
    }

    async update() {
        this.api.client().patch(`/bookings/${this.booking.id}/metadata`, this.booking.metadata);
        this.hasChanged.emit();
    }

    async ngAfterViewInit() {

    }

    async ngOnInit() {
        if (!this.booking.metadata)
            this.booking.metadata = {};
        if (!this.booking.metadata.arrivedPersons)
            this.booking.metadata.arrivedPersons = 0;
    }
}
