import { Component, OnInit, EventEmitter, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Api } from '../../../services/api';
import { TranslateService } from '@ngx-translate/core';
import { QuickBookingComponent } from './quick-booking/quick-booking.component';
import { QuickResourceBookingComponent } from './quick-resource-booking/quick-resource-booking.component';
import { CurrentUser } from '../../../services/user';
import { Globals } from '../../../services/globals';
import { IResourceBooking } from '../../../../../../../src/app/interfaces/resource-booking';


@Component({
    selector: 'quick-booking-dialog',
    templateUrl: './quick-booking-dialog.component.html',
    styleUrls: ['./quick-booking-dialog.component.scss']
})

export class QuickBookingDialogComponent implements OnInit, OnDestroy {
    @ViewChild('quickBookingElement', { static: false })
    quickBookingElement: QuickBookingComponent;
    @ViewChild('quickResourceBookingElement', { static: false })
    quickResourceBookingElement: QuickResourceBookingComponent;
    @ViewChild('tabGroup', { static: false })
    tabGroup: MatTabGroup;

    selectedTabIndex = 0;
    resourceBookingToRebook: IResourceBooking;

    selectedDate: Date;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<QuickBookingDialogComponent>, private api: Api, translate: TranslateService, public currentUser: CurrentUser, public globals: Globals) {
        this.selectedDate = data.selectedDate;
    }
    

    validate() {
        if (this.tabGroup) {
            switch (this.tabGroup.selectedIndex) {
                case 0:
                    return this.quickBookingElement.validate();
                case 1:
                    return this.quickResourceBookingElement.validate();
            }
        }
    }

    createBooking() {
        switch (this.tabGroup.selectedIndex) {
            case 0:
                return this.quickBookingElement.createBooking();
            case 1:
                return this.quickResourceBookingElement.createResourceBooking();
        }
    }


    async close() {
        if(this.dialogRef)
            this.dialogRef.close();
    }

    async bookingCreated(booking) {
        this.data.bookingCreated(booking);
        this.dialogRef.close();
    }

    async resourceBookingCreated(resourceBooking) {
        this.data.bookingCreated(resourceBooking);
        this.dialogRef.close();
    }

    async ngAfterViewInit() {

    }

    async ngAfterContentInit() {
        
        if (this.resourceBookingToRebook) {
            console.log(this.tabGroup);
            this.selectedTabIndex = 1;
        }
    }


    async ngOnInit() {
        this.resourceBookingToRebook = this.data.resourceBookingToRebook;
    }

    ngOnDestroy() {
        
    }
}
