import { Component, OnInit, OnDestroy, ViewChild, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';
import * as moment from 'moment';


@Component({
    selector: 'closed-periods-editor',
    templateUrl: './closed-periods-editor.component.html'
})

export class ClosedPeriodsEditorComponent implements OnInit {
    closedPeriods: any[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility) {

    }


    async newClosedPeriod() {
        let result = await this.api.client().post<any>(`/manage/closed-periods/new`, {});
        if (result.succeeded) {
            result.closedPeriod.startDate = moment(result.closedPeriod.startDate).toDate();
            result.closedPeriod.endDate = moment(result.closedPeriod.endDate).toDate();
            this.closedPeriods.push(result.closedPeriod);
        }
    }

    async deleteClosedPeriod(closedPeriod) {
        let result = await this.api.client().delete<any>(`/manage/closed-periods/${closedPeriod.id}`);
        this.closedPeriods.splice(this.closedPeriods.indexOf(closedPeriod), 1);
    }

    async saveClosedPeriod(closedPeriod) {
        let result = await this.api.client().patch<any>(`/manage/closed-periods/${closedPeriod.id}`, closedPeriod);
    }

    async ngOnInit() {
        this.closedPeriods = await this.api.client().get<any[]>(`/manage/closed-periods`);
        for (let closedPeriod of this.closedPeriods) {
            closedPeriod.startDate = moment(closedPeriod.startDate).toDate();
            closedPeriod.endDate = moment(closedPeriod.endDate).toDate();
        }
    }

}
