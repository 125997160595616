import { Component, OnInit, EventEmitter, ViewChild, Inject, Output, Input } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { IPackage } from 'projects/client-app/src/app/interfaces/package';
import { IAddOn } from 'projects/client-app/src/app/interfaces/add-on';
import { ISelectedExtraActivity, IQuickBookingFormState } from 'projects/client-app/src/app/interfaces/quick-booking-form-state';
import { Api } from 'projects/client-app/src/app/services/api';
import { IBooking } from 'src/app/interfaces/booking';
import { Utility } from 'projects/client-app/src/app/services/utility';


@Component({
    selector: 'quick-booking',
    templateUrl: './quick-booking.component.html',
    styleUrls: ['./quick-booking.component.scss']
})

export class QuickBookingComponent implements OnInit {
    @Input()
    selectedDate: Date = moment().toDate();

    availablePackages: IPackage[];
    availableTimeslots: any;
    availableAddOns: IAddOn[];
    selectedPackage: IPackage;
    selectedDay: string = moment().format('YYYY-MM-DD');
    selectedTime: string;
    selectedPersons: number = 0;
    selectedCustomerGroups: { [customerGroupId: string]: { name: string, quantity: number, customerGroupId: string } };
    selectedCustomerGroupId: string;
    availableCustomerGroups: any[];
    customerInfo: any = {
        lastName: ''
    };
    selectedExtraActivities: ISelectedExtraActivity[] = [];
    extraActivitiesTimeslots: any = {}
    isPaid: boolean = false;
    hasArrived: boolean = false;
    notChangeable: boolean = false;
    newsletterAccepted: boolean = false;
    creatingBooking = false;
    addOns: {
        addOnId,
        name,
        quantity
    }[] = [];

    sortedCustomerGroups: any[];

    @ViewChild('packageSelect', { static: false }) packageSelect: MatSelect;

    @Output()
    bookingCreated = new EventEmitter<IBooking>();

    constructor(private api: Api, translate: TranslateService, public utility: Utility) {
        this.customerInfo.firstName = translate.instant('DROP_IN');
    }

    validate() {
        return !this.creatingBooking
            && this.availableTimeslots
            && this.availableTimeslots[this.selectedTime]
            && this.selectedPackage
            && this.selectedDay
            && this.selectedTime
            && this.selectedPersons >= this.selectedPackage.minPeoplePerBooking
            && this.selectedPersons <= this.selectedPackage.maxPeoplePerBooking
            && this.selectedPersons <= this.availableTimeslots[this.selectedTime].availableSlots
            && this.customerInfo.firstName != '';
    }


    getQuickBookingFormState(): IQuickBookingFormState {
        return {
            selectedAddOns: this.addOns.filter(a => a.quantity > 0),
            selectedCustomerGroups: Object.values(this.selectedCustomerGroups),
            selectedDay: moment(this.selectedDate).format('YYYY-MM-DD'),
            selectedTime: this.selectedTime,
            selectedExtraActivities: this.selectedExtraActivities,
            selectedPackage: this.selectedPackage,
            selectedPersons: this.selectedPersons,
            hasArrived: this.hasArrived,
            isPaid: this.isPaid,
            notChangeable: this.notChangeable,
            newsletterAccepted: this.newsletterAccepted,
            customerInfo: this.customerInfo
        }
    }

    addOnQuantityChanged(addOn, data) {
        addOn.quantity = data.value;
    }

    async fetchTimeslots() {
        this.availableTimeslots = null;
        this.selectedTime = null;
        let endDate = this.selectedPackage.allowMidnightOverflow ? moment(this.selectedDay).add(2, 'days').format('YYYY-MM-DD') : moment(this.selectedDay).add(1, 'day').format('YYYY-MM-DD');
        let result = await this.api.publicClient().get<any>(`/packages/${this.selectedPackage.id}/available-timeslots?startDate=${encodeURIComponent(this.selectedDay)}&endDate=${encodeURIComponent(endDate)}`);
        if (result.timeslots && result.timeslots.dates && result.timeslots.dates[this.selectedDay]) {
            this.availableTimeslots = result.timeslots.dates[this.selectedDay];

            let times = Object.keys(this.availableTimeslots);
            for (let time of times) {
                if (!this.availableTimeslots[time].availableSlots)
                    delete this.availableTimeslots[time];
            }

            // Filter old timeslots
            if (this.selectedDay == moment().format('YYYY-MM-DD')) {
                for (let time in this.availableTimeslots) {
                    if (moment(`${this.selectedDay} ${time}`).add(1, 'hour').isBefore(moment()))
                        delete this.availableTimeslots[time];
                }
            }

            // Select the next upcoming time
            for (let time in this.availableTimeslots) {
                if (moment(`${this.selectedDay} ${time}`).isAfter(moment())) {
                    this.selectedTime = time;
                    this.timeSelected();
                    break;
                }
            }


            if (this.selectedPackage.extraActivities && this.selectedPackage.extraActivities.length)
                this.extraActivitiesTimeslots = (await this.api.publicClient().get<any>(`/packages/${this.selectedPackage.id}/extra-activities/timeslots?day=${encodeURIComponent(this.selectedDay)}`)).activities;
        }
    }

    async timeSelected() {
        console.log('Time selected');
        console.log(this.availableTimeslots[this.selectedTime]);
        if (this.availableTimeslots[this.selectedTime]
            && this.availableTimeslots[this.selectedTime].hasBookings
            && this.availableTimeslots[this.selectedTime].customerGroupIds) {
            this.selectedCustomerGroupId = Object.keys(this.availableTimeslots[this.selectedTime].customerGroupIds)[0];
            console.log(this.selectedCustomerGroupId);
        }
    }

    isCustomerGroupHidden(customerGroupId) {
        if (this.availableTimeslots[this.selectedTime]
            && this.availableTimeslots[this.selectedTime].hasBookings
            && this.availableTimeslots[this.selectedTime].customerGroupIds) {
            if (this.availableTimeslots[this.selectedTime].customerGroupIds[customerGroupId]) {
                return false;
            }
            else
                return true;
        }

        if (this.selectedPackage.lockToCustomerGroup) {
            if (this.selectedCustomerGroupId && this.selectedCustomerGroupId != customerGroupId && this.selectedPersons > 0)
                return true;
        }
        return false;
    }

    async packageSelected() {
        this.selectedCustomerGroups = {};
        this.selectedCustomerGroupId = null;


        //sort out customerGroups that have no price on the package so the quick-booking is not
        //clutterd with a bunch of customer group
        this.sortedCustomerGroups = this.availableCustomerGroups;
        let groups = [];
        for(let priceSettings of this.selectedPackage.priceSettings){
          for(let key of Object.keys(priceSettings.customerGroupPrices) ){
            if(priceSettings.customerGroupPrices[key].enabled) {
                groups.push(key);
            }
          }
        }

        this.sortedCustomerGroups = this.availableCustomerGroups.filter((group) => {
            return groups.includes(group.id);
        });

        console.log(this.availableCustomerGroups);
        console.log("Filtered groups :");
        console.log(this.sortedCustomerGroups);

        for (let customerGroup of this.availableCustomerGroups) {
            this.selectedCustomerGroups[customerGroup.id] = {
                name: customerGroup.name,
                quantity: 0,
                customerGroupId: customerGroup.id
            }
        }
        this.fetchTimeslots();
        this.addOns = [];


        for (let addOnId in this.selectedPackage.enabledAddOnIds) {
            let addOn = this.availableAddOns.find(a => a.id == addOnId);
            if(!addOn.disabled){
                this.addOns.push({
                    addOnId: addOn.id,
                    name: addOn.name,
                    quantity: 0
                });
            }
        }
    }

    async dayChanged() {
        console.log('Day changed');
        if (this.selectedDate) {
            this.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
            this.fetchTimeslots();
        }

    }

    async createBooking() {
        if (!this.creatingBooking) {
            this.creatingBooking = true;
            let result = await this.api.client().post<any>(`/quick-booking`, this.getQuickBookingFormState());
            if (result.succeeded) {
                this.creatingBooking = false;
                this.bookingCreated.emit(result.booking);
            }
        }
    }

    async ngAfterViewInit() {

    }

    customerGroupPersonsChanged(customerGroupId, data) {
        let oldQuantity = this.selectedCustomerGroups[customerGroupId].quantity;
        let newValue = 0;
        this.selectedCustomerGroups[customerGroupId].quantity = data.value;
        console.log(this.selectedCustomerGroups);
        for (let customerGroupId in this.selectedCustomerGroups) {
            newValue += this.selectedCustomerGroups[customerGroupId].quantity;
        }
        if (newValue > this.selectedPackage.maxPeoplePerBooking) {
            this.selectedCustomerGroups[customerGroupId].quantity = oldQuantity;
            data.revert();
        }
        else {
            this.selectedPersons = newValue;
        }

        if (this.selectedPackage.lockToCustomerGroup) {
            this.selectedCustomerGroupId = customerGroupId;
        }
    }

    personsChanged(data) {
        if (data.value > this.selectedPackage.maxPeoplePerBooking)
            data.revert();
        else
            this.selectedPersons = data.value;
    }

    async ngOnInit() {
        this.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
        this.availablePackages = await this.api.client().get<IPackage[]>(`/quick-booking/packages`);
        this.availableAddOns = await this.api.publicClient().get<IAddOn[]>(`/add-ons`);
        this.availableCustomerGroups = (await this.api.publicClient().get<any[]>(`/customer-groups`)).filter(cg => !cg.disabled);
        this.packageSelect.focus();

    }
}
