import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IStaffDevice } from '../../../../../../../../../../../common/common-interfaces/staff-device';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { Api } from 'projects/client-app/src/app/services/api';

@Component({
    selector: 'staff-device-qr-dialog',
    templateUrl: './staff-device-qr-dialog.component.html',
    styleUrls: ['./staff-device-qr-dialog.component.scss']
})

export class StaffDeviceQRDialogComponent implements OnInit {
    staffDevice: IStaffDevice;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globals: Globals, private dialogRef: MatDialogRef<StaffDeviceQRDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer) { 
        this.staffDevice = data.staffDevice;
    }

    close() {
        this.dialogRef.close();
    }

    async ngOnInit() {
       
    }
}
