// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smiley {
  font-size: 5em;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/feedback-smiley/feedback-smiley.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/feedback-smiley/feedback-smiley.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;ACCJ","sourcesContent":[".smiley{\r\n    font-size: 5em;\r\n}",".smiley {\n  font-size: 5em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
