// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-center {
  padding: 15px;
  width: 40vw;
  max-width: 80vw;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/notification-center/notification-center.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/notification-center/notification-center.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,eAAA;ACCJ","sourcesContent":[".notification-center{\r\n    padding:15px;\r\n    width:40vw;\r\n    max-width:80vw;\r\n}",".notification-center {\n  padding: 15px;\n  width: 40vw;\n  max-width: 80vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
