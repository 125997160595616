import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';
import { Currency } from '../../services/currency';




@Component({
    selector: 'invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {
    invoices: any[];
    displayedColumns = ['date', 'amount', 'status', 'show'];
    constructor(private api: Api, private router: Router, public globals: Globals, public currency: Currency) {
        
    }


    async ngOnInit() {
        this.invoices = await this.api.client().get<any[]>('/manage/invoices');
    }

}
