// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:'https://booking.funbutler.dev',
  gtag:'G-69950YT8LP',
  firebaseConfig: {
    apiKey: "AIzaSyAk_5R6mwlKiPPredEsKS-3b08O9lKZ7yc",
    authDomain: "procommerca-booking-dev.firebaseapp.com",
    databaseURL: "https://procommerca-booking-dev.firebaseio.com",
    projectId: "procommerca-booking-dev",
    storageBucket: "procommerca-booking-dev.appspot.com",
    messagingSenderId: "738030867080",
    appId: "1:738030867080:web:7200a3ac17768b0e2dc53d",
    measurementId: "G-BT2HZSMDZQ"
  },
  firebaseWebPushKeyPair: 'BMSCOoKhaChYvGELCeawNZ_mNKhykNAD2EoheenV0Vp5mm7Vww5XZ6jQ5gjqSSQZp6PoShzHWK_sJLoj3WyXRaU',
  funbutlerStripePublicKey: 'pk_test_51LY6cTF2Iydm81zG10zZfVi57Kry1Z0RWYTXbVMm7qdW3zH4NOfnMFSENKMJ6A39vA7zmmOW5dx7yhHWvlbK8Bui006uG47UHb'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
