import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { IClientSettings } from "../interfaces/client-settings";
import { Currency } from "../../../../../../common/common-types/currency";

@Injectable()
export class Globals {
    clientId?: string;
    currency?: Currency;
    clientSettings?: IClientSettings;
    baseUrl?: string = environment.baseUrl;
    customTextCache: { [id: string]: any } = {};
    locale: string;
    embedMode: boolean;
    standalone: boolean;
    useDarkMode?: boolean;

    constructor() {
        this.embedMode = (window as any).funbutlerEmbedMode;
        this.standalone = (window as any).funbutlerStandalone;
    }

    

    determineCartId(clientId: string) {
        // Format: #cart(CART_ID)
        if (window.location.hash.includes('cartId(')) {
            let cartId = window.location.hash.split('cartId(')[1].split(')')[0];
            localStorage.setItem(`${clientId}_cartId`, cartId);
            window.location.href = window.location.href.split('#')[0];
        }
    }
}
