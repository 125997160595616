// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-content-dialog {
  width: 600px;
}
.notification-content-dialog .content {
  max-width: 100%;
}
.notification-content-dialog .content.no-html {
  white-space: pre-wrap;
}
.notification-content-dialog .content.html ::ng-deep img {
  max-width: 100% !important;
}
.notification-content-dialog a:focus-within, .notification-content-dialog a:focus, .notification-content-dialog a:focus-visible {
  border: 0px;
  outline: 0px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/notification-center/notification-content-dialog/notification-content-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/notification-center/notification-content-dialog/notification-content-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;ACCJ;ADCI;EACI,eAAA;ACCR;ADAQ;EACI,qBAAA;ACEZ;ADGgB;EACI,0BAAA;ACDpB;ADSQ;EAGI,WAAA;EACA,YAAA;ACTZ","sourcesContent":[".notification-content-dialog {\r\n    width: 600px;\r\n\r\n    .content {\r\n        max-width: 100%;\r\n        &.no-html {\r\n            white-space: pre-wrap;\r\n        }\r\n\r\n        &.html {\r\n            ::ng-deep {\r\n                img {\r\n                    max-width: 100% !important;\r\n                }\r\n            }\r\n        }\r\n    }\r\n\r\n\r\n    a {\r\n        &:focus-within,\r\n        &:focus,\r\n        &:focus-visible {\r\n            border: 0px;\r\n            outline: 0px;\r\n        }\r\n    }\r\n}\r\n",".notification-content-dialog {\n  width: 600px;\n}\n.notification-content-dialog .content {\n  max-width: 100%;\n}\n.notification-content-dialog .content.no-html {\n  white-space: pre-wrap;\n}\n.notification-content-dialog .content.html ::ng-deep img {\n  max-width: 100% !important;\n}\n.notification-content-dialog a:focus-within, .notification-content-dialog a:focus, .notification-content-dialog a:focus-visible {\n  border: 0px;\n  outline: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
