import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { MatTableModule, MatTable } from '@angular/material/table';
import { IBookingForm } from '../../interfaces/booking-form';
import { IPackage } from '../../interfaces/package';
import { IAddOn } from '../../interfaces/add-on';
import { IEvent } from '../../../../../../../../../common/common-interfaces/event';
import { orderBy } from '../../helpers/utility';
import { CurrentUser } from '../../services/user';
import { IQRCodePoster } from '../../../../../../../../../common/common-interfaces/qr-code';
import { PaymentProviderName } from '../../../../../../../../../common/common-interfaces/payment-info';
import { Utility } from '../../services/utility';
import { IDepartment } from '../../../../../../../../../common/common-interfaces/department';

@Component({
  selector: 'department-administration',
  templateUrl: './department-administration.component.html'
})

export class DepartmentAdministrationComponent implements OnInit, OnDestroy {
  department: IDepartment | null = null;
  private paramsSubscription?: Subscription;

  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private currentUser: CurrentUser, public utility: Utility) {

  }

  async save() {
    // Check if booking form should be created or updated
    if (this.department && this.department.id) {
      let result = await this.api.client().patch<any>(`/manage/departments/${this.department.id}`, this.department);
    }
    else {
      let result = await this.api.client().put<any>(`/manage/departments`, this.department);
      if (result.succeeded) {
        this.router.navigateByUrl(`/${this.globals.clientId}/departments/${result.department.id}`);
      }
    }
  }

  async ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(async (params) => {
      if (params['id']) {
        this.department = await this.api.client().get<IDepartment>(`/manage/departments/${params['id']}`);
      }
      else {
        this.department = {

        }
      };
    });
  }

  async ngOnDestroy() {
    if (this.paramsSubscription)
      this.paramsSubscription.unsubscribe();
  }
}
