// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-settings-editor .price-setting {
  padding: 15px;
}
.price-settings-editor .price-setting .extra-order-rows {
  table-layout: fixed;
  font-size: 10px;
}
.price-settings-editor .price-setting .extra-order-rows .text,
.price-settings-editor .price-setting .extra-order-rows .article-number {
  width: 20%;
  overflow: hidden;
}
.price-settings-editor .price-setting .extra-order-rows .price {
  width: 50%;
}
.price-settings-editor .price-setting .extra-order-rows .remove {
  width: 10%;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/packages/price-settings-editor/price-settings-editor.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/packages/price-settings-editor/price-settings-editor.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;ACAJ;ADEI;EACE,mBAAA;EACA,eAAA;ACAN;ADEM;;EAEE,UAAA;EACA,gBAAA;ACAR;ADGM;EACE,UAAA;ACDR;ADIM;EACE,UAAA;ACFR","sourcesContent":[".price-settings-editor {\r\n  .price-setting {\r\n    padding: 15px;\r\n\r\n    .extra-order-rows {\r\n      table-layout: fixed;\r\n      font-size:10px;\r\n\r\n      .text,\r\n      .article-number {\r\n        width: 20%;\r\n        overflow: hidden;\r\n      }\r\n\r\n      .price {\r\n        width: 50%;\r\n      }\r\n\r\n      .remove {\r\n        width: 10%;\r\n      }\r\n    }\r\n  }\r\n}\r\n",".price-settings-editor .price-setting {\n  padding: 15px;\n}\n.price-settings-editor .price-setting .extra-order-rows {\n  table-layout: fixed;\n  font-size: 10px;\n}\n.price-settings-editor .price-setting .extra-order-rows .text,\n.price-settings-editor .price-setting .extra-order-rows .article-number {\n  width: 20%;\n  overflow: hidden;\n}\n.price-settings-editor .price-setting .extra-order-rows .price {\n  width: 50%;\n}\n.price-settings-editor .price-setting .extra-order-rows .remove {\n  width: 10%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
