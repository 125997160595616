import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Api } from '../../../services/api';
import { CustomTextEditorComponent } from '../custom-text-editor/custom-text-editor.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'custom-sms-text-editor',
    templateUrl: './custom-sms-text-editor.component.html',
    styleUrls: ['./custom-sms-text-editor.component.scss']
})

export class CustomSmsTextEditorComponent implements OnInit {
    @Input()
    tag: string;
    @ViewChild('customTextEditor', { static: false })
    customTextEditor: CustomTextEditorComponent;
    SEGMENT_LENGTH = 160;
    UCS2_SEGMENT_LENGTH = 70;

    UCS2_CHARACTERS = [' '];
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

    }

    async contentLoaded() {

    }

    async contentChanged() {

    }

    isUCS2Required() {
        if (this.customTextEditor) {
            let content = this.customTextEditor.customText.content;

            if (content) {
                for (let character of this.UCS2_CHARACTERS) {
                    if (content.includes(character))
                        return true;
                }
            }
        }
    }

    getSmsDescriptionText() {
        if (this.customTextEditor && this.customTextEditor.customText) {
            let content = this.customTextEditor.customText.content;
            let placeholdersEstimatedLength = 0;
            if (this.customTextEditor.customText.availablePlaceholders) {
                for (let placeholder of this.customTextEditor.customText.availablePlaceholders) {
                    while (content.includes(placeholder.tag)) {
                        content = content.replace(placeholder.tag, '');
                        placeholdersEstimatedLength += placeholder.estimatedLength ? placeholder.estimatedLength : 0;
                    }
                }
            }
            let characters = content.length + placeholdersEstimatedLength;

            let segments = Math.ceil(characters / this.SEGMENT_LENGTH);

            if (this.isUCS2Required()) {
                segments = Math.ceil(characters / this.UCS2_SEGMENT_LENGTH);
            }

            return this.translate.instant('SMS_TEXT_EDITOR_DESCRIPTION')
                .replace('#CHARACTERS', characters)
                .replace('#SEGMENTS', segments);
        }
    }


    async ngOnInit() {

    }

}
