import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IActivitySequences, IPackage, IPriceSettings, IExtraOrderRow } from '../../../interfaces/package';
import { IActivity } from '../../../interfaces/activity';
import { addDuration, minutesFromDuration, getStandardColor } from '../../../helpers/utility';
import { TranslateService } from '@ngx-translate/core';
import { newPrice } from '../../../interfaces/price';
import { Currency } from '../../../services/currency';
import { Utility } from '../../../services/utility';





@Component({
    selector: 'price-settings-editor',
    templateUrl: './price-settings-editor.component.html',
    styleUrls: ['./price-settings-editor.component.scss']
})

export class PriceSettingsEditorComponent implements OnInit {
    @Input()
    priceSettings: IPriceSettings[];


    constructor(private api: Api, public globals: Globals, public translate: TranslateService, public currency: Currency, public utility: Utility) {

    }

    addPriceSetting() {
        this.priceSettings.push({
            name: '',
            color: getStandardColor(this.priceSettings.length),
            customerGroupPrices: {},
            extraOrderRows: [],
            price: newPrice()
        });
    }

    removeExtraOrderRow(priceSetting: IPriceSettings, extraOrderRow: IExtraOrderRow) {
        priceSetting.extraOrderRows.splice(priceSetting.extraOrderRows.indexOf(extraOrderRow), 1);
    }

    addExtraOrderRow(priceSetting: IPriceSettings) {
        priceSetting.extraOrderRows.push({
            name: '',
            price: newPrice()
        });
    }


    async ngOnInit() {

    }
}
