import { Component, OnInit, OnDestroy } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IUser } from '../../interfaces/user';
import { Globals } from '../../services/globals';

@Component({
    selector: 'user-administration',
    templateUrl: './user-administration.component.html'
})

export class UserAdministrationComponent implements OnInit, OnDestroy {

    user: IUser | null = null;
    newPassword: string = null;
    private paramsSubscription: Subscription;
    availableReports: string[];

    rightPassword: boolean = true;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals) {

    }

    async save() {
        // Check if user should be created or updated

        if (this.user.id) {
            if(this.rightPassword) {
                let result = await this.api.client().patch<any>(`/manage/users/${this.user.id}`, Object.assign(this.user, { newPassword: this.newPassword }));
                if (result.succeeded) {
                    this.newPassword = null;
                    this.rightPassword = false;
                }
            }
            
        }
        else {
            if (this.user.email) {
                if(this.rightPassword) {
                    let result = await this.api.client().put<any>(`/manage/users`, Object.assign(this.user, { password: this.newPassword }));
                    if (result.succeeded) {
                        this.router.navigateByUrl(`/${this.globals.clientId}/users/${result.user.id}`);
                    }
                }

            }
        }
    }

    async ngOnInit() {
        this.user = {
            availableReports: {}
        };
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.user = await this.api.client().get<IUser>(`/manage/users/${params['id']}`);
                if (!this.user.availableReports)
                    this.user.availableReports = {};
            }
        });
        this.availableReports = await this.api.client().get<string[]>(`/manage/reports/available-reports`);
    }

    async ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
    }

    validatePassword(password: string) {        
        const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()@$!%*?&])[A-Za-z\d@$!%*?&()]{8,}$/;
        this.rightPassword = passwordRules.test(password);

        if(password == null || password == ""){
            this.rightPassword = true;
        }

    }   

}
