import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { Globals } from '../../../services/globals';
import { IResource } from '../../../interfaces/resource';
import { Api } from '../../../services/api';
import { GenerateTimeslots, CloneTimeslots, CloneTimeslotsFromRole } from '../../../helpers/timeslot-generator';
import { IPriceSettings } from '../../../interfaces/package';
import { R } from '@angular/cdk/keycodes';

@Component({
    selector: 'custom-timeslot-tables',
    templateUrl: './custom-timeslot-tables.component.html',
    styleUrls: ['./custom-timeslot-tables.component.scss']
})

export class CustomTimeslotTablesComponent implements OnInit, OnDestroy {
    @Input()
    customTimeslotPeriods: any[];
    @Input()
    currentTimeslots: any;
    @Input()
    timeslotInterval: any;
    @Input()
    priceSettings: IPriceSettings[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals) {

    }


    async generateTimeslots(timeslotPeriod, role, emptyTimeslots) {
        await GenerateTimeslots(role,
            this.timeslotInterval,
            timeslotPeriod,
            emptyTimeslots, this.priceSettings ? 0 : null);
    }

    async enabledTimeslotSchemeChanged(timeslotPeriod, role) {
        if (timeslotPeriod.enabledTimeslotSchemes[role] && !timeslotPeriod.timeslots[role]) {
            timeslotPeriod.timeslots[role] = await CloneTimeslotsFromRole(timeslotPeriod.timeslots, 'public');
            // this.generateTimeslots(timeslotPeriod, role, false);
        }
    }


    async addCustomTimeslotPeriod(useCurrent) {
        if (this.customTimeslotPeriods) {
            let customTimeslotPeriod: any = {
                name: '',
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
                enabledTimeslotSchemes: { public: true }
            }

            if (useCurrent) {
                customTimeslotPeriod.timeslots = await CloneTimeslots(this.currentTimeslots, 'public');
            }
            else {
                this.generateTimeslots(customTimeslotPeriod, 'public', true);
            }

            this.customTimeslotPeriods.push(customTimeslotPeriod);
        }
    }

    async removeCustomTimeslotPeriod(timeslotPeriod: any) {
        if (this.customTimeslotPeriods)
            this.customTimeslotPeriods.splice(this.customTimeslotPeriods.indexOf(timeslotPeriod), 1);
    }


    startDateChanged(timeslotPeriod, e) {
        console.log(`Start date: ${e.value}`);
        timeslotPeriod.startDate = moment(e.value).startOf('day').toDate();
    }

    endDateChanged(timeslotPeriod, e) {
        console.log(`End date: ${e.value}`);
        timeslotPeriod.endDate = moment(e.value).endOf('day').toDate();
    }

    async ngOnInit() {


    }

    async ngOnDestroy() {

    }

    hasDuplicateCustomTimeSlots() {
        let activeStartDates = [];
        let activeEndDates = [];


        if(!this.customTimeslotPeriods) return false;

        // Get all start and end dates that are active
        this.customTimeslotPeriods.forEach(timeslot => {

            // Check if the timeslot has a start and end date 
            // apparently it can be null which causes the app to crash
            // so we need to check for that first before we do anything else
            if(!timeslot.startDate || !timeslot.endDate) return false;

            if (moment().isAfter(moment(timeslot.startDate)) || moment().isSame(moment(timeslot.startDate))) {
                activeStartDates.push(timeslot.startDate.toISOString());
            }

            if (moment().isBefore(moment(timeslot.endDate)) || moment().isSame(moment(timeslot.endDate))) {
                activeEndDates.push(timeslot.endDate.toISOString());
            }
        });
        
        // If there are no active start or end dates, return false 
        //since there are no timeslots to check which means no duplicates
        if(activeStartDates.length === 0 || activeEndDates.length === 0) return false;


        //Loop through all timeslots twice to check for overlaps
        for (let i = 0; i < this.customTimeslotPeriods.length; i++) {
            for (let j = 0; j < this.customTimeslotPeriods.length; j++) {

                // Check so timeslot A is not the same as timeslot B
                if (i !== j) {

                    // Check so the timeslots are in the active lists
                    if(activeStartDates.includes(this.customTimeslotPeriods[i].startDate.toISOString()) && activeEndDates.includes(this.customTimeslotPeriods[i].endDate.toISOString())) {

                        // Check so the timeslots are in the active timeslots lists
                        if(activeStartDates.includes(this.customTimeslotPeriods[j].startDate.toISOString()) && activeEndDates.includes(this.customTimeslotPeriods[j].endDate.toISOString())) {

                            let startA = moment(this.customTimeslotPeriods[i].startDate);
                            let endA = moment(this.customTimeslotPeriods[i].endDate);
                            let startB = moment(this.customTimeslotPeriods[j].startDate);
                            let endB = moment(this.customTimeslotPeriods[j].endDate);

                            // Check if timeslot A overlaps with timeslot B
                            if (startA.isBefore(endB) && endA.isAfter(startB)) {
                                return true; // Overlap found
                            }

                        }
                    }

                }

            }
        }

        return false; // No overlaps
    }

}
