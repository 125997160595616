import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';
import { Currency } from '../../../services/currency';

@Component({
    selector: 'first-visit-dialog',
    templateUrl: './first-visit-dialog.component.html',
    styleUrls: ['./first-visit-dialog.component.scss']
})

export class FirstVisitDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<FirstVisitDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, private currency: Currency) {
        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
        });

    }

    async ngAfterViewInit() {

    }



    ngOnDestroy() {

    }

    close() {
        this.dialogRef.close();
    }

    async ngOnInit() {

    }
}
