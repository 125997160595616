// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gift-card-report {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/gift-cards/gift-card-report.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/gift-cards/gift-card-report.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,eAAA;ACCJ","sourcesContent":[".gift-card-report{\r\n    padding-right: 20px;\r\n    padding-left: 20px;\r\n    font-size: 10px;\r\n}",".gift-card-report {\n  padding-right: 20px;\n  padding-left: 20px;\n  font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
