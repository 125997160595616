import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { FileUploader } from 'ng2-file-upload';
import { TranslateService } from '@ngx-translate/core';
import { IMedia } from '../../../../../../../../../../common/common-interfaces/media';
import { getMediaType, SafeMimeTypes } from '../../../../../../../../../../common/common-helpers/media-helper';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.scss']
})

export class MediaListComponent implements OnInit, OnDestroy {
    @Input()
    uploadUrl: string;
    @Input()
    mediaList: IMedia[];

    public uploading: boolean = false;
    public uploader: FileUploader;
    fileOverDropZone;
    loading = false;
    @ViewChild('mediaTable', { static: false }) mediaTable: MatTable<any>;
    displayedColumns = ['icon', 'name', 'sort', 'remove'];

    loadingMedia = {};

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

    }


    public async deleteImage() {
        this.loading = true;
        await this.api.requester().delete(this.uploadUrl);
        //  this.container[this.imageField] = null;
        this.loading = false;
    }

    public fileOver(e: any): void {
        this.fileOverDropZone = e;
    }


    public fileDropped(e: any): void {
        console.log('File dropped');
        console.log(e);
      //  this.loading = true;
    }

    async ngOnInit() {
        this.uploader = new FileUploader({
            url: this.uploadUrl,
            autoUpload: true,
            method: 'post',
            itemAlias: 'media',
        });

        this.uploader.onSuccessItem = (item, response, status, headers) => {
            let data = JSON.parse(response);
            if (data.succeeded) {
                this.mediaList.push(data.media);
                this.loading = false;
            }
            console.log('Upload complete');
            console.log(response);

            this.mediaTable.renderRows();
        }

        this.uploader.onBeforeUploadItem = () => {
            this.loading = true;
        }

        this.uploader.onErrorItem = (item, response, status, headers) => {
            this.loading = false;
        }
    }

    moveMediaUp(media: IMedia) {
        let index = this.mediaList.indexOf(media);
        if (index > 0) {
            this.mediaList.splice(index - 1, 0, this.mediaList.splice(index, 1)[0]);
            this.mediaTable.renderRows();
        }
        this.saveMediaList();
    }

    moveMediaDown(media: IMedia) {
        let index = this.mediaList.indexOf(media);
        if (index < this.mediaList.length - 1) {
            this.mediaList.splice(index + 1, 0, this.mediaList.splice(index, 1)[0]);
            this.mediaTable.renderRows();
        }
        this.saveMediaList();
    }

    async saveMediaList() {
        await this.api.requester().patch(this.uploadUrl, this.mediaList);
    }


    async removeMedia(media: IMedia) {
        this.loadingMedia[media.fileId] = true;
        let index = this.mediaList.indexOf(media);
        await this.api.requester().delete(`${this.uploadUrl}/${encodeURIComponent(media.fileId)}`);
        this.mediaList.splice(index, 1);
        this.mediaTable.renderRows();
        this.loadingMedia[media.fileId] = false;
    }

    getMediaType(media: IMedia) {
        return getMediaType(media);
    }

    async ngOnDestroy() {

    }
}
