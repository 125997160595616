// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview {
  font-size: 1em;
}
.preview .poster-heading {
  font-size: 3em;
}
.preview .poster-heading,
.preview .poster-footer {
  text-align: center;
  padding: 20px;
}

.download button {
  margin: 0px 10px;
}

color-chooser {
  margin-top: 33px;
  display: inline-block;
}

::ng-deep canvas {
  max-width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
::ng-deep [ng2filedrop] {
  text-align: center;
}
::ng-deep [ng2filedrop] .frame {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/shared/qr-code-poster-configurator/qr-code-poster-configurator.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/shared/qr-code-poster-configurator/qr-code-poster-configurator.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;ACCJ;ADAI;EACI,cAAA;ACER;ADCI;;EAEI,kBAAA;EACA,aAAA;ACCR;;ADII;EACI,gBAAA;ACDR;;ADKA;EACI,gBAAA;EACA,qBAAA;ACFJ;;ADMI;EACI,cAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;ACHR;ADMI;EACI,kBAAA;ACJR;ADMQ;EACI,qBAAA;ACJZ","sourcesContent":[".preview {\r\n    font-size: 1em;\r\n    .poster-heading {\r\n        font-size: 3em;\r\n    }\r\n\r\n    .poster-heading,\r\n    .poster-footer {\r\n        text-align: center;\r\n        padding: 20px;\r\n    }\r\n}\r\n\r\n.download{\r\n    button{\r\n        margin: 0px 10px;\r\n    }\r\n}\r\n\r\ncolor-chooser{\r\n    margin-top: 33px;\r\n    display: inline-block;\r\n}\r\n\r\n::ng-deep {\r\n    canvas {\r\n        max-width: 70%;\r\n        display: block;\r\n        margin-left: auto;\r\n        margin-right: auto;\r\n    }\r\n\r\n    [ng2filedrop]{\r\n        text-align: center;\r\n\r\n        .frame{\r\n            display: inline-block;\r\n        }\r\n    }\r\n}\r\n",".preview {\n  font-size: 1em;\n}\n.preview .poster-heading {\n  font-size: 3em;\n}\n.preview .poster-heading,\n.preview .poster-footer {\n  text-align: center;\n  padding: 20px;\n}\n\n.download button {\n  margin: 0px 10px;\n}\n\ncolor-chooser {\n  margin-top: 33px;\n  display: inline-block;\n}\n\n::ng-deep canvas {\n  max-width: 70%;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n::ng-deep [ng2filedrop] {\n  text-align: center;\n}\n::ng-deep [ng2filedrop] .frame {\n  display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
