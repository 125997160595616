import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';

@Component({
    selector: 'log-viewer',
    templateUrl: './log-viewer.component.html',

})

export class LogViewerComponent implements OnInit {
    @Input()
    log: any;
    displayedColumns = ['created', 'message'];
    constructor(private router: Router, private route: ActivatedRoute) {

    }

    getLog() {
        if (this.log) {
            this.log.sort((a: any, b: any) => {
                return moment(b.created).valueOf() - moment(a.created).valueOf();
            });
        }
        return this.log;
    }

    ngOnInit(): void {

    }
}
