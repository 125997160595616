import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Utility } from '../../../services/utility';
import { Globals } from '../../../services/globals';
import { Api } from '../../../services/api';
import { IBooking } from '../../../../../../../src/app/interfaces/booking';
import { DateAdapter } from '@angular/material/core';
import { IPackage } from '../../../interfaces/package';
import { IResourceBooking } from '../../../../../../../src/app/interfaces/resource-booking';
import { IBookingBase } from '../../../../../../../src/app/interfaces/booking-base';
import { IBookingData } from '../booking-list/booking-list-report.component';


@Component({
    selector: 'day-planner-report',
    templateUrl: './day-planner-report.component.html',
    styleUrls: ['./day-planner-report.component.scss']
})
export class DayPlannerReportComponent implements OnInit, OnDestroy {
    startDate: Date = moment().startOf('day').toDate();
    endDate: Date = moment().endOf('day').toDate();

    loading = false;

    availablePackages: IPackage[];
    selectedPackageIds: string[] = [];

    bookingData: IBookingData[];

    timeInterval = '00:30';

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private dateAdapter: DateAdapter<any>) {
        this.globals.clientSettingsReceived.subscribe(() => {
            this.dateAdapter.setLocale(`${this.globals.clientSettings.language}-${this.globals.clientSettings.country.toUpperCase()}`);
            this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
        });
    }

    print() {
        window.print();
    }

    async timeIntervalChanged(){

    }

    async refreshData() {

    }


    async fetchBookings() {
        this.loading = true;
        this.bookingData = [];
        let query = `startDate=${moment(this.startDate).startOf('day').toISOString()}&endDate=${moment(this.endDate).endOf('day').toISOString()}&searchMode=startDate`;


        if (this.selectedPackageIds.length)
            query += `&packageIds=${this.selectedPackageIds.join(',')}`;

        let bookings = await this.api.client().get<IBooking[]>(`/bookings/get?${query}`);
        bookings.forEach(booking => {
            this.bookingData.push({
                created: moment(booking.created).toDate(),
                startDate: moment(booking.startDate).toDate(),
                bookingBase: booking,
                booking: booking
            });
        });

        let resourceBookings = await this.api.client().get<IResourceBooking[]>(`/resource-bookings/get?${query}`);
        if (resourceBookings) {
            resourceBookings.forEach(resourceBooking => {
                this.bookingData.push({
                    created: moment(resourceBooking.created).toDate(),
                    startDate: moment(resourceBooking.startDate).toDate(),
                    bookingBase: resourceBooking,
                    resourceBooking: resourceBooking
                });
            });
        }

        this.bookingData.sort((result1, result2) => {
            if (moment(result1.startDate).isAfter(result2.startDate))
                return 1;
            else
                return -1;
        });


        this.loading = false;
    }

    async dateChanged() {
        this.endDate = moment(this.startDate).endOf('day').toDate();
        this.fetchBookings();
    }

    async ngOnInit() {
        this.fetchBookings();
        this.availablePackages = await this.api.client().get(`/packages`);
    }

    async ngOnDestroy() {

    }
}
