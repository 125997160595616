import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Utility } from '../../../../services/utility';
import { Globals } from '../../../../services/globals';
import { Api } from '../../../../services/api';
import { ChartDataset, ChartOptions, LegendOptions, ChartType } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'smiley-report-chart',
  templateUrl: './smiley-report-chart.component.html',
  styleUrls: ['./smiley-report-chart.component.scss']
})

export class SmileyReportChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  data: { date: Date, points: number }[];
  public pointsGraphData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };
  public pieChartData: {
    datasets: ChartDataset[],
    labels: string[],
    options: ChartOptions,
    legend?: LegendOptions<any>,
    chartType: ChartType
  };
  colors = {
    good: '#66af48',
    neutral: '#fac805',
    bad: '#d33372'
  }
  numberOfLabels = 15;
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, private globals: Globals, private utility: Utility, private translate: TranslateService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  updateData() {
    if (!this.data)
      return;


    let firstDate: moment.Moment;
    let lastDate: moment.Moment;
    let barChartData: {
      [date: string]: {
        good: number,
        neutral: number,
        bad: number
      }
    } = {};

    let totals = {
      good: 0,
      neutral: 0,
      bad: 0
    }

    for (let data of this.data) {
      let dateMoment = moment(data.date);

      if (!firstDate || dateMoment.isBefore(firstDate))
        firstDate = dateMoment;
      if (!lastDate || dateMoment.isAfter(lastDate))
        lastDate = dateMoment;

      let date = dateMoment.format('YYYY-MM-DD');
      if (!barChartData[date])
        barChartData[date] = {
          good: 0,
          neutral: 0,
          bad: 0
        };
      switch (data.points) {
        case 0:
          barChartData[date].bad++;
          totals.bad++;
          break;
        case .5:
          barChartData[date].neutral++;
          totals.neutral++;
          break;
        case 1:
          barChartData[date].good++;
          totals.good++;
          break;
      }
    }

    // Fill in empty dates
    let date = moment(firstDate);
    while (date.isBefore(lastDate)) {
      let dateString = date.format('YYYY-MM-DD');
      if (!barChartData[dateString]) {
        barChartData[dateString] = {
          good: 0,
          neutral: 0,
          bad: 0
        }
      }
      date = date.add(1, 'day');
    }

    // Sort
    let sortedChartData = {};
    Object.keys(barChartData).sort().forEach((key) => {
      sortedChartData[key] = barChartData[key];
    });
    barChartData = sortedChartData;


    let datasets = {
      good: {
        label: this.translate.instant('FEEDBACK_GOOD'),
        borderColor: '',
        backgroundColor: `${this.colors.good}53`,
        hoverBackgroundColor: this.colors.good,
        data: [],
      },
      neutral: {
        label: this.translate.instant('FEEDBACK_NEUTRAL'),
        borderColor: '',
        backgroundColor: `${this.colors.neutral}53`,
        hoverBackgroundColor: this.colors.neutral,
        data: [],
      },
      bad: {
        label: this.translate.instant('FEEDBACK_BAD'),
        borderColor: '',
        backgroundColor: `${this.colors.bad}53`,
        hoverBackgroundColor: this.colors.bad,
        data: [],
      }
    }

    let labels = [];
    for (let date in barChartData) {
      datasets.good.data.push(barChartData[date].good);
      datasets.neutral.data.push(barChartData[date].neutral);
      datasets.bad.data.push(barChartData[date].bad);
      labels.push(date);
    }

    // Bar chart
    this.pointsGraphData = {
      chartType: 'bar',
      datasets: [datasets.good, datasets.neutral, datasets.bad],
      labels: labels,
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            mode: 'index',
          }
        },
        hover: {
          mode: 'index'
        },

        scales: {
          x:{
            stacked: false,
            ticks: {
              autoSkip: true,
              maxTicksLimit: this.numberOfLabels,

            }
          },
          y:{
            stacked: true,
          }
        }

      }
    }

    // Pie chart
    this.pieChartData = {
      chartType: 'doughnut',
      datasets: [{
        data: [totals.good, totals.neutral, totals.bad],
        backgroundColor: [`${this.colors.good}53`, `${this.colors.neutral}53`, `${this.colors.bad}53`]
      }],
      labels: [
        this.translate.instant('FEEDBACK_GOOD'),
        this.translate.instant('FEEDBACK_NEUTRAL'),
        this.translate.instant('FEEDBACK_BAD')
      ],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'index'
          },
        },

        animation: {
          //    animateScale: true,
          //    animateRotate: true
        }
      }
    }
  }

  async ngOnInit() {

  }

  async ngOnDestroy() {

  }
}
