import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IPrice } from '../../../interfaces/price';

@Component({
    selector: 'color-chooser',
    templateUrl: './color-chooser.component.html',
    styleUrls: ['./color-chooser.component.scss']
})

export class ColorChooserComponent implements OnInit, OnDestroy {
    colorValue : string;

    @Output()
    colorChange = new EventEmitter<string>();
  
    @Input()
    get color(){
      return this.colorValue;
    }
  
    set color(val) {
      this.colorValue = val;
      this.colorChange.emit(this.colorValue);
    }

    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

    }

    async ngOnInit() {

    }

    async ngOnDestroy() {

    }
}
