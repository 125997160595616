import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../../../../../services/api';
import { DomSanitizer } from '@angular/platform-browser';
import { IBooking, IBookingAddOn } from '../../../../../../../../../src/app/interfaces/booking';
import { Globals } from '../../../../../services/globals';
import { CurrentUser } from '../../../../../services/user';
import { IPrisonIslandParticipant, IPrisonIslandTeam } from '../../../../../../../../../../../../common/common-third-party/prison-island/interfaces';
import { Utility } from '../../../../../services/utility';
import { IResource } from 'projects/client-app/src/app/interfaces/resource';
import moment from 'moment';
import { addDurationToTime, shiftTimeByMinutes } from '../../../../../../../../../../../../common/common-helpers/utility';


@Component({
  selector: 'social-gaming-administration-dialog',
  templateUrl: './social-gaming-administration-dialog.component.html',
  styleUrls: ['./social-gaming-administration-dialog.component.scss']
})

export class SocialGamingAdministrationDialogComponent implements OnInit {
  public booking: IBooking;
  loading: boolean = true;

  resourcesById: { [id: string]: IResource } = {};
  loadingEvent: { [id: string]: boolean } = {};
  resourceTimeslots: { [resourceId: string]: any } = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private dialogRef: MatDialogRef<SocialGamingAdministrationDialogComponent>, private translate: TranslateService, private api: Api, private sanitizer: DomSanitizer, public globals: Globals, public currentUser: CurrentUser, public utility: Utility) {
    this.booking = data.booking;

    this.dialogRef.backdropClick().subscribe(async () => {
      await this.close();
    });
  }


  async close() {
    this.dialogRef.close();

  }

  getEventName(event) {
    return event.tables.map(t => t.tableName).join(', ');
  }

   getExtendTimes(event) {
    let minutes = {
      15: true,
      30: true,
      45: true,
      60: true
    };
    let startTime = moment(event.startsAt).add(event.duration, 'minutes').format('HH:mm');
    console.log(`Start time: ${startTime}`);
    // let timeslotInterval = this.resourcesById[table.resourceId].timeslotInterval
    for (let table of event.tables) {
      let timeslots = this.resourceTimeslots[table.resourceId];
      let unavailableTimeFound = false;
      for (let minute in minutes) {
        if (minutes[minute]) {
          let time = shiftTimeByMinutes(startTime, parseInt(minute));
          console.log(`Checking time: ${time} (+${minute} minutes)`);
          if (!timeslots[time] || timeslots[time].slots < table.numGuests || unavailableTimeFound) {
            minutes[minute] = false;
            unavailableTimeFound = true;
            console.log(`${minute} minutes is unavailable`);
          }
        }
      }
    }

    let result = Object.keys(minutes).filter(minute => minutes[minute]).map(minute => parseInt(minute));
    console.log(result);
    return result;
  }

  async updateEvent(event) {
    this.loadingEvent[event.id] = true;

    let result = await this.api.client().post<any>(`/bookings/${this.booking.id}/third-party/social-gaming/change-reservation/${event.id}`, event);
    event.extendedMinutes = 0;

    if(result.succeeded){
      event.isExtended = result.event.isExtended;
    }

    this.loadingEvent[event.id] = false;
  }

  async ngOnInit() {
    for (let event of this.booking.gameIntegrationData.socialGamingData.eventsData) {
      event.extendedMinutes = 0;
      for (let table of event.tables) {
        if (!this.resourcesById[table.resourceId]) {
          this.resourcesById[table.resourceId] = await this.api.client().get<IResource>(`/resources/${table.resourceId}`);
          this.resourceTimeslots[table.resourceId] = (await this.api.client().get<any>(`/resources/${table.resourceId}/available-slots/${this.booking.localDay}`)).dates[this.booking.localDay].timeslots;
        }
      }
    }

    this.loading = false;
  }


  ngOnDestroy() {

  }
}
