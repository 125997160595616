// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prison-island-registration,
.bottom {
  width: 700px;
  max-width: 100%;
}

.prison-island-registration {
  min-height: 200px;
  max-height: 90vh;
}

.logo {
  max-height: 200px;
}

.rfid-icon {
  max-width: 90%;
  max-height: 100px;
}

.teams .icon {
  margin: 5px;
}
.teams .team .edit-icon,
.teams .participant .edit-icon {
  opacity: 0;
  margin-left: 5px;
  transition: 0.3s linear;
}
.teams .team:hover .edit-icon,
.teams .participant:hover .edit-icon {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/booking-info-dialog/third-party/prison-island-registration/prison-island-registration-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/booking-info-dialog/third-party/prison-island-registration/prison-island-registration-dialog.component.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;EACA,eAAA;ACCF;;ADEA;EACE,iBAAA;EACA,gBAAA;ACCF;;ADGA;EACE,iBAAA;ACAF;;ADGA;EACE,cAAA;EACA,iBAAA;ACAF;;ADKE;EACE,WAAA;ACFJ;ADQI;;EACE,UAAA;EACA,gBAAA;EACA,uBAAA;ACLN;ADSM;;EACE,UAAA;ACNR","sourcesContent":[".prison-island-registration,\r\n.bottom {\r\n  width: 700px;\r\n  max-width: 100%;\r\n}\r\n\r\n.prison-island-registration {\r\n  min-height: 200px;\r\n  max-height: 90vh;\r\n}\r\n\r\n\r\n.logo {\r\n  max-height: 200px;\r\n}\r\n\r\n.rfid-icon {\r\n  max-width: 90%;\r\n  max-height: 100px;\r\n}\r\n\r\n.teams {\r\n\r\n  .icon {\r\n    margin: 5px;\r\n  }\r\n\r\n  .team,\r\n  .participant {\r\n\r\n    .edit-icon {\r\n      opacity:0;\r\n      margin-left:5px;\r\n      transition: .3s linear;\r\n    }\r\n\r\n    &:hover {\r\n      .edit-icon {\r\n        opacity: 1;\r\n      }\r\n    }\r\n  }\r\n}\r\n",".prison-island-registration,\n.bottom {\n  width: 700px;\n  max-width: 100%;\n}\n\n.prison-island-registration {\n  min-height: 200px;\n  max-height: 90vh;\n}\n\n.logo {\n  max-height: 200px;\n}\n\n.rfid-icon {\n  max-width: 90%;\n  max-height: 100px;\n}\n\n.teams .icon {\n  margin: 5px;\n}\n.teams .team .edit-icon,\n.teams .participant .edit-icon {\n  opacity: 0;\n  margin-left: 5px;\n  transition: 0.3s linear;\n}\n.teams .team:hover .edit-icon,\n.teams .participant:hover .edit-icon {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
