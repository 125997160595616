// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-feedback-report {
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.customer-feedback-report .comments .smiley {
  width: 60px;
}
.customer-feedback-report .feedback-sections .feedback-section {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/reports/customer-feedback/customer-feedback-report.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/reports/customer-feedback/customer-feedback-report.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;ACCF;ADEI;EACE,WAAA;ACAN;ADKI;EACE,gBAAA;ACHN","sourcesContent":[".customer-feedback-report {\r\n  margin-top: 30px;\r\n  padding-left: 30px;\r\n  padding-right: 30px;\r\n\r\n  .comments {\r\n    .smiley {\r\n      width: 60px;\r\n    }\r\n  }\r\n\r\n  .feedback-sections {\r\n    .feedback-section {\r\n      margin-top: 30px;\r\n    }\r\n  }\r\n}\r\n",".customer-feedback-report {\n  margin-top: 30px;\n  padding-left: 30px;\n  padding-right: 30px;\n}\n.customer-feedback-report .comments .smiley {\n  width: 60px;\n}\n.customer-feedback-report .feedback-sections .feedback-section {\n  margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
