import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { Api } from '../../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';
import { IPackage } from '../../../interfaces/package';
import { environment } from 'projects/client-app/src/environments/environment';

@Component({
    selector: 'booking-list-type-settings',
    templateUrl: './booking-list-type-settings.component.html',
    styleUrls: ['./booking-list-type-settings.component.scss']
})

export class HardwareUnitBookingListTypeSettingsComponent implements OnInit, OnDestroy {
    @Input()
    hardwareUnit: any;

    availableFonts = environment.webSafeFonts;
    availablePackages: IPackage[];

    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, private currentUser: CurrentUser) {

    }

    async ngOnInit() {
        this.availablePackages = await this.api.client().get<IPackage[]>('/manage/packages');
        let notPaidColor = '#ff3087';
        let paidColor = '#76c5e0';
        let checkedInColor = '#5eaf39';

        if (!this.hardwareUnit.typeSettings.BOOKING_LIST) {

            let statusText = () => {
                return {
                    notPaid: { text: '', backgroundColor: notPaidColor },
                    paid: { text: '', backgroundColor: paidColor },
                    checkedIn: { text: '', backgroundColor: checkedInColor }
                }
            };

            this.hardwareUnit.typeSettings.BOOKING_LIST = {
                logoImage: null,
                statusTexts: {
                    upcoming: statusText(),
                    twentyMinutesBefore: statusText(),
                    tenMinutesBefore: statusText(),
                    during: statusText(),
                    after: statusText()
                },
                packageFilter: [],
                bookingStartViewPeriod: {
                    value: 1,
                    unit: 'hours'
                },
                bookingEndViewPeriod: {
                    value: 10,
                    unit: 'minutes'
                },
                socialMedia: {
                    website: '',
                    facebook: '',
                    instagram: ''
                },
                primaryColors: {
                    foreground: '#000000',
                    background: '#c1c1c1'
                },
                secondaryColors: {
                    foreground: '#ffffff',
                    background: '#000000'
                },
                fontFamily: 'Arial',
                fontSize: 18,
                bookingsPerPage: 10,
                flashes: []
            };
        }
        if (!this.hardwareUnit.typeSettings.BOOKING_LIST.socialMedia) {
            this.hardwareUnit.typeSettings.BOOKING_LIST.socialMedia = {
                website: '',
                facebook: '',
                instagram: ''
            }
        }

        if (!this.hardwareUnit.typeSettings.BOOKING_LIST.bookingEndViewPeriod) {
            this.hardwareUnit.typeSettings.BOOKING_LIST.bookingEndViewPeriod = {
                value: 10,
                unit: 'minutes'
            }
        }

        if (!this.hardwareUnit.typeSettings.BOOKING_LIST.flashes) {
            this.hardwareUnit.typeSettings.BOOKING_LIST.flashes = []
        }

        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.notPaid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.notPaid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.notPaid,
                backgroundColor: notPaidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.paid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.paid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.paid,
                backgroundColor: paidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.checkedIn) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.checkedIn = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.after.checkedIn,
                backgroundColor: checkedInColor
            }
        }

        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.notPaid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.notPaid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.notPaid,
                backgroundColor: notPaidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.paid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.paid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.paid,
                backgroundColor: paidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.checkedIn) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.checkedIn = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.during.checkedIn,
                backgroundColor: checkedInColor
            }
        }


        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.notPaid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.notPaid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.notPaid,
                backgroundColor: notPaidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.paid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.paid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.paid,
                backgroundColor: paidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.checkedIn) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.checkedIn = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.tenMinutesBefore.checkedIn,
                backgroundColor: checkedInColor
            }
        }


        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.notPaid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.notPaid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.notPaid,
                backgroundColor: notPaidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.paid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.paid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.paid,
                backgroundColor: paidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.checkedIn) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.checkedIn = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.twentyMinutesBefore.checkedIn,
                backgroundColor: checkedInColor
            }
        }


        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.notPaid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.notPaid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.notPaid,
                backgroundColor: notPaidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.paid) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.paid = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.paid,
                backgroundColor: paidColor
            }
        }
        if (typeof (this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.checkedIn) == 'string') {
            this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.checkedIn = {
                text: this.hardwareUnit.typeSettings.BOOKING_LIST.statusTexts.upcoming.checkedIn,
                backgroundColor: checkedInColor
            }
        }


    }

    async newFlash() {
        let newFlash = {
            identifier: this.utility.uuid(),
            type: 'IMAGE',
            duration: 10,
            delay: 60
        };

        this.hardwareUnit.typeSettings.BOOKING_LIST.flashes.push(newFlash);
        await this.api.client().put<any>(`/manage/hardware-units/${this.hardwareUnit.id}/types/booking-list/flash`, newFlash);
    }

    async removeFlash(flash) {
        let flashIndex = this.hardwareUnit.typeSettings.BOOKING_LIST.flashes.indexOf(flash);
        this.hardwareUnit.typeSettings.BOOKING_LIST.flashes.splice(flashIndex, 1);
        await this.api.client().delete<any>(`/manage/hardware-units/${this.hardwareUnit.id}/types/booking-list/flashes/${flash.identifier}`);
    }

    ngOnDestroy() {

    }

}
