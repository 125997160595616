import { Injectable } from '@angular/core';
import { Globals } from './globals';
import { formatCurrency } from "../../../../../../common/common-helpers/currency";

@Injectable()
export class Currency {
    constructor(private globals: Globals) {

    }
    format(number) {
        return formatCurrency(this.globals.currency, number);
    }
}