import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from '../../../services/user';

@Component({
    selector: 'support-button',
    templateUrl: './support-button.component.html',
    styleUrls: ['./support-button.component.scss']
})

export class SupportButtonComponent implements OnInit, OnDestroy {
    @Input() styling: string = "small";
    @Input() search: string = "";
    @Input() category: string = "";
    @Input() description: string = "";


    HOST_NAME = `support.funbutler.com`;
    fullLink: string = "";

    constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService, public currentUser: CurrentUser) {

    }

    openSupportPage(){
        window.open("https://" + this.fullLink, "_blank");
    }


    async ngOnInit() {
        if(this.category == ""){
            this.fullLink = `${this.HOST_NAME}/?s=${this.search}&et_pb_searchform_submit=et_search_proccess&et_pb_include_posts=yes&et_pb_include_pages=yes`;
        }

        else {
            this.fullLink = `${this.HOST_NAME}/${this.category}`;
        }
    }

    async ngOnDestroy() {

    }

}
