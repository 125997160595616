import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api';
import { Router } from '@angular/router';
import { orderBy } from '../../helpers/utility';
import { Globals } from '../../services/globals';



@Component({
    selector: 'custom-texts',
    templateUrl: './custom-texts.component.html'
})

export class CustomTextsComponent implements OnInit {
    customTexts: any[] = [];

    constructor(private api: Api, private router: Router, public globals: Globals) {

    }

    async createCustomText() {
        let result = await this.api.client().put<any>(`/manage/custom-texts`, { name: '', content: '', type: 'WYSIWYG' });
        if (result.succeeded) {
            this.customTexts.unshift(result.customText);
        }
    }

    async saveCustomText(customText) {
        let result = await this.api.client().patch<any>(`/manage/custom-texts`, customText);
    }

    async ngOnInit() {
        this.customTexts = await this.api.client().get<any[]>('/manage/custom-texts/untagged');
        this.customTexts.sort(orderBy('name').asc);
    }
}
