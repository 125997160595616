import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { CurrentUser } from '../../../services/user';
import { ICustomerGroupRule } from '../../../../../../../../../../common/common-interfaces/customer-groups-rules';
import { CustomerGroupRuleType, availableCustomerGroupRuleTypes } from '../../../../../../../../../../common/common-types/customer-group-rules';


@Component({
	selector: 'customer-group-rules',
	templateUrl: './customer-group-rules.component.html',
	styleUrls: ['./customer-group-rules.component.scss']
})

export class CustomerGroupRulesComponent implements OnInit {

  @Input()
  customerGroupRules: ICustomerGroupRule[] = [];

  @Input()
  availableCustomerGroups: any[] = [];

  customerGroupTypes: CustomerGroupRuleType[] = availableCustomerGroupRuleTypes;

  constructor(private api: Api, private router: Router, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {}

  addNewRule() {
    this.customerGroupRules.push({
      rule: this.customerGroupTypes[0],
      data : {
        value: 0,
        customerGroupIds: [],
        combineValueBetweenCustomerGroups: false
      }
    });
  }

  removeRule(ruleIndex) {
    this.customerGroupRules.splice(ruleIndex, 1);
  }

  //reset the data when selected a new type
  customerRuleDataUpdated(customerRule: ICustomerGroupRule) {
    //customerRule.data = {};

    switch(customerRule.rule) {
      case "MAXIMUM_PERSONS":
      case "MINIMUM_PERSONS":
        customerRule.data = {
          value:0,
          customerGroupIds: [],
          combineValueBetweenCustomerGroups: false
        }
        break;

      case 'MINIMUM_PERSONS_PER_PERSONS':
      case 'MAXIMUM_PERSONS_PER_PERSONS':
        customerRule.data = {
          firstGroup : {
            value: 0,
            customerGroupId: ""
          },
          secondGroup: {
            value: 0,
            customerGroupId: ""
          }
        }
        break;
    }
  }

  async ngOnInit() {}

  ruleType(type: string): string {
    if(["MINIMUM_PERSONS", "MAXIMUM_PERSONS"].includes(type)) {
      return "VALUE";
    }

    else if(["MINIMUM_PERSONS_PER_PERSONS", "MAXIMUM_PERSONS_PER_PERSONS"].includes(type)){
      return "PER_PERSON";
    }

    return "UNKNOWN";
  }

}
